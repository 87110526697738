import { Injectable } from '@angular/core';

import { catchError, first, tap } from 'rxjs/operators';
import { EMPTY } from 'rxjs';
import { NGXLogger } from 'ngx-logger';

import { ErrorHandleService } from '@bp2s/core/errors/error-handle.service';

import { DaisyChainApprovalRequest } from '@bp2s/model/daisyChainApprovalRequest';
import { DaisyChainValidationRequest } from '@bp2s/model/daisyChainValidationRequest';
import { AccountControllerService } from '@bp2s/api/accountController.service';
import { DaisyChainControllerService } from '@bp2s/api/daisyChainController.service';
import { PortfolioModifierControllerService } from '@bp2s/api/portfolioModifierController.service';
import { PortfolioDaisyChainValidationRequest } from '@bp2s/model/portfolioDaisyChainValidationRequest';
@Injectable({
  providedIn: 'root',
})
export class BrListService {
  constructor(
    public logger: NGXLogger,
    public errorService: ErrorHandleService,
    public accountService: AccountControllerService,
    public dcService: DaisyChainControllerService,
    public pmoCreateService:PortfolioModifierControllerService
  ) {}

  getDaisyChainList(id) {
    return this.dcService.daisyChainGetListOfDCForAccountUsingGET(id).pipe(
      first(),
      tap({ next: (x) => this.logger.info('getDaisyChain', x) }),
      catchError(err => {
        this.errorService.handleOldErrorParser(err?.error?.errors);
        return EMPTY;
      })
    );
  }

  getAccountById(id) {
    return this.accountService.accountGetAccountUsingGET(id).pipe(
      first(),
      catchError((err) => {
        this.errorService.handleWithNotification(
          err,
          'Sorry there was a problem retrieving this account'
        );
        return EMPTY;
      })
    );
  }

  createDaisyChainWithApproval(data: DaisyChainApprovalRequest) {
    return this.dcService.daisyChainSaveDaisyChainUsingPOST(data).pipe(
      first(),
      tap({ next: (x) => this.logger.info('getDaisyChain', x) })
    );
  }

  updateDaisyChainWithApproval(data: DaisyChainApprovalRequest) {
    return this.dcService.daisyChainUpdateDaisyChainUsingPUT(data).pipe(
      first(),
      tap({ next: (x) => this.logger.info('getDaisyChain', x) })
    );
  }

  updateWorkflowStatus(data: DaisyChainApprovalRequest){
    return this.dcService.daisyChainUpdateWorkflowStatusUsingPUT(data).pipe(
      first(),
      tap({ next: (x) => this.logger.info('getDaisyChain', x) })
    );
  }

  checkDaisyChainDataExists(
    daisyChainValidationRequest: DaisyChainValidationRequest
  ) {
    return this.dcService
      .daisyChainDaisyChainExistsUsingPOST(daisyChainValidationRequest)
      .pipe(
        first(),
        tap({ next: (x) => this.logger.info('daisyChainValidation', x) })
      );
  }

  checkDaisyChainOverlapswithEA(
    portfolioDaisyChainValidationRequest: PortfolioDaisyChainValidationRequest
  ) {
    return this.pmoCreateService
      .portfolioModifierCheckDCOverlappingUsingPOST(portfolioDaisyChainValidationRequest)
      .pipe(
        first(),
        tap({ next: (x) => this.logger.info('portfolioDaisyChainValidationRequest', x) })
      );
  }
}
