<header>
  <h1 mat-dialog-title>{{ data?.title }}</h1>
  <button [mat-dialog-close]="false" mat-icon-button>
    <mat-icon>close</mat-icon>
  </button>
</header>
<div mat-dialog-content>
  <div
    *ngIf="data.message"
    class="msg-content"
    [innerHTML]="data.message"
  ></div>
  <div *ngIf="data.template">
    <ng-container *ngTemplateOutlet="data.template"></ng-container>
  </div>
</div>
<div mat-dialog-actions>
  <button
    *ngIf="data.cancelText"
    class="mat-stroked-button btn"
    id="common-dialog-cancel"
    mat-stroked-button color="primary"
    (click)="cancel()"
  >
    {{ data.cancelText }}
  </button>
  <button
    *ngIf="data.confirmText"
    mat-flat-button
    class="btn"
    color="primary"
    cdkFocusInitial
    id="common-dialog-confirm"
    (click)="confirm()"
  >
    {{ data.confirmText }}
  </button>
</div>
