<mat-form-field>
  <mat-label>Select the SSO User</mat-label>
  <mat-select [compareWith]="compareWith" [formControl]="userSelector" id="users">
    <mat-option *ngFor="let item of PROFILES" [value]="item">{{
      item?.profile?.sub
    }}</mat-option>
  </mat-select>
</mat-form-field>

<mat-form-field>
  <mat-label>Select the Roles</mat-label>
  <mat-select multiple [formControl]="roleSelector" id="roles">
    <mat-option *ngFor="let item of ROLES | keyvalue" [value]="item.key">{{
      item.key
    }}</mat-option>
  </mat-select>
  <button (click)="resetRoles($event)" mat-icon-button matSuffix id="reset">
    <mat-icon>close</mat-icon>
  </button>
</mat-form-field>
<button id="reload" mat-flat-button color="secondary" (click)="reload()">Apply Changes</button>
