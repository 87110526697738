import { Component, Input, HostBinding } from '@angular/core';
import { leftNavFlowExpansionAnimation } from './left-nav-flow.animation';
import { LeftNavFlowSections } from '../../core/models/left-nav-flow.model';

@Component({
  selector: 'bp2s-left-nav-flow',
  templateUrl: './left-nav-flow.component.html',
  styleUrls: ['./left-nav-flow.component.scss'],
  animations: [
    leftNavFlowExpansionAnimation.flowExpanded,
    leftNavFlowExpansionAnimation.flowSelected
  ]
})
export class LeftNavFlowComponent {
  @Input() icon: string;
  @Input() module: string;
  @Input() page: string;

  @HostBinding('@flowSelected')
  @HostBinding('class.flow-selected')
  @Input()
  selected = true;

  @Input()
  expanded = false;

  @Input() sections: LeftNavFlowSections[] = [];
  @Input() sectionIndex = 1;

  constructor() {}

  select(item: LeftNavFlowSections) {
    const index = this.sections.findIndex(i => item.name === i.name);
    this.sectionIndex = index;
  }
}
