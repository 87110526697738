<ng-template #matPrefix>
  <span
    class="prefix-icon"
    *ngIf="props.prefixLeft"
    [ngStyle]="{ cursor: props.prefixLeft.onClick ? 'pointer' : 'inherit' }"
    (click)="prefixLeftClick($event)"
    [class.disabled]="props.disabled"
  >
    <mat-icon *ngIf="props.prefixLeft.icon">{{ props.prefixLeft.icon }}</mat-icon>
  </span>
</ng-template>

<ng-container #fieldComponent></ng-container>

<ng-template #matSuffix>
  <span class="suffix-secondary-icon" *ngIf="props.prefixRightSecondaryIcon" [class.disabled]="props.disabled">
    <mat-icon *ngIf="props.prefixRightSecondaryIcon.icon">{{ props.prefixRightSecondaryIcon.icon }}</mat-icon>
  </span>
  <span
    class="suffix-icon"
    *ngIf="props.prefixRight"
    [ngStyle]="{ cursor: props.prefixRight.onClick ? 'pointer' : 'inherit' }"
    (click)="prefixRightClick($event)"
    [class.disabled]="props.disabled"
  >
  <ng-container *ngIf="props.prefixRight">
    <mat-icon *ngIf="props.prefixRight.icon">{{ props.prefixRight.icon }}</mat-icon>
  </ng-container>
  </span>
</ng-template>
