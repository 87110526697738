import {
  Component,
  OnInit,
  Input,
  ViewChild,
  TemplateRef,
  Output,
  EventEmitter,
  OnDestroy,
  ElementRef,
} from '@angular/core';
import { MenuPositionY, MenuPositionX } from '@angular/material/menu';

let topNavOverlayId = 0;

@Component({
  selector: 'bp2s-top-nav-overlay',
  templateUrl: './top-nav-overlay.component.html',
  styleUrls: ['./top-nav-overlay.component.scss']
})
export class TopNavOverlayComponent implements OnInit, OnDestroy {
  private _yPosition: MenuPositionY = 'below';
  private _xPosition: MenuPositionX = 'before';

  @Input('aria-label') ariaLabel: string;
  @Input('aria-labelledby') ariaLabelledby: string;
  @Input('aria-describedby') ariaDescribedby: string;

  @Input()
  get xPosition(): MenuPositionX {
    return this._xPosition;
  }
  set xPosition(value: MenuPositionX) {
    if (value !== 'before' && value !== 'after') {
      throw new Error('This is not a valid position');
    }
    this._xPosition = value;
    this.setPositionClasses();
  }

  get yPosition(): MenuPositionY {
    return this._yPosition;
  }

  _classList: { [key: string]: boolean } = {};

  @Input('class')
  set panelClass(classes: string) {
    const previousPanelClass = this._previousPanelClass;

    if (previousPanelClass && previousPanelClass.length) {
      previousPanelClass.split(' ').forEach((className: string) => {
        this._classList[className] = false;
      });
    }

    this._previousPanelClass = classes;

    if (classes && classes.length) {
      classes.split(' ').forEach((className: string) => {
        this._classList[className] = true;
      });

      this._elementRef.nativeElement.className = '';
    }
  }
  private _previousPanelClass: string;

  @ViewChild(TemplateRef) templateRef: TemplateRef<any>;

  @Output() readonly closed: EventEmitter<
    void | 'click' | 'keydown' | 'tab'
  > = new EventEmitter<void | 'click' | 'keydown' | 'tab'>();

  readonly overlayId = `top-nav-overlay-panel-${topNavOverlayId++}`;

  constructor(
    private _elementRef: ElementRef<HTMLElement>,
  ) {}

  ngOnInit(): void {
    this.setPositionClasses();
  }

  ngOnDestroy() {
    this.closed.complete();
  }

  setPositionClasses(
    posX: MenuPositionX = this.xPosition,
    posY: MenuPositionY = this.yPosition
  ) {
    const classes = this._classList;
    classes['mat-menu-before'] = posX === 'before';
    classes['mat-menu-after'] = posX === 'after';
    classes['mat-menu-above'] = posY === 'above';
    classes['mat-menu-below'] = posY === 'below';
  }
}
