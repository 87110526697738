/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { UserDetails } from './userDetails';


export interface PolicyTreeSeriesMetadata { 
    approvalStatus?: PolicyTreeSeriesMetadata.ApprovalStatusEnum;
    clientId?: string;
    creator?: UserDetails;
    description?: string;
    displayView?: string;
    fieldsChanged?: { [key: string]: string; };
    isLatestVersion?: boolean;
    lastUpdateTime?: string;
    lastUpdateUser?: string;
    name?: string;
    orgId?: string;
    owner?: string;
    policyTreeId?: string;
    purpose?: string;
    seriesId?: string;
    type?: PolicyTreeSeriesMetadata.TypeEnum;
    versionEndDate?: string;
    versionStartDate?: string;
    workflowStatus?: PolicyTreeSeriesMetadata.WorkflowStatusEnum;
}
export namespace PolicyTreeSeriesMetadata {
    export type ApprovalStatusEnum = 'PENDING' | 'DRAFT' | 'APPROVED' | 'REJECTED' | 'CANCELLED' | 'REFERRED_BACK';
    export const ApprovalStatusEnum = {
        PENDING: 'PENDING' as ApprovalStatusEnum,
        DRAFT: 'DRAFT' as ApprovalStatusEnum,
        APPROVED: 'APPROVED' as ApprovalStatusEnum,
        REJECTED: 'REJECTED' as ApprovalStatusEnum,
        CANCELLED: 'CANCELLED' as ApprovalStatusEnum,
        REFERREDBACK: 'REFERRED_BACK' as ApprovalStatusEnum
    };
    export type TypeEnum = 'OFFICIAL' | 'UNOFFICIAL';
    export const TypeEnum = {
        OFFICIAL: 'OFFICIAL' as TypeEnum,
        UNOFFICIAL: 'UNOFFICIAL' as TypeEnum
    };
    export type WorkflowStatusEnum = 'INITIAL_CREATION' | 'ACTIVE' | 'INACTIVE' | 'CLOSED';
    export const WorkflowStatusEnum = {
        INITIALCREATION: 'INITIAL_CREATION' as WorkflowStatusEnum,
        ACTIVE: 'ACTIVE' as WorkflowStatusEnum,
        INACTIVE: 'INACTIVE' as WorkflowStatusEnum,
        CLOSED: 'CLOSED' as WorkflowStatusEnum
    };
}


