import {
  Component,
  OnInit,
  ViewEncapsulation,
  Input,
  Output,
  EventEmitter,
  OnDestroy,
} from '@angular/core';
import { CheckboxTreeOption } from './checkbox-tree.model';
import { SelectionModel } from '@angular/cdk/collections';
import { groupBy, reduce } from 'lodash';
import { Subject } from 'rxjs';
import { takeUntil, debounceTime } from 'rxjs/operators';
import { isArray } from 'lodash';

@Component({
  selector: 'bp2s-checkbox-tree',
  templateUrl: './checkbox-tree.component.html',
  styleUrls: ['./checkbox-tree.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CheckboxTreeComponent<T> implements OnInit, OnDestroy {
  groupedOptions: { [groupName: string]: CheckboxTreeOption<T>[] };
  public value: T[];

  private _options: CheckboxTreeOption<T>[];
  private _optionsCount: number;
  private kill$: Subject<void> = new Subject();

  @Input() color = 'primary';
  @Input() set options(options: CheckboxTreeOption<T>[]) {
    if (isArray(options)) {
      this.selection.clear();
      this._options = options;
      this._optionsCount = options.length;

      let groupedOptions = groupBy(options, (option) => option.groupName);
      groupedOptions = reduce(groupedOptions, (prev, val, key) => {
        prev[key] = val.slice().sort((a, b) => a.label.localeCompare(b.label, undefined, { numeric: true }))
        return prev;
      }, {})

      this.groupedOptions = groupedOptions;
      this.setDefaultOptions();
    }
  }

  @Output() selected: EventEmitter<T[]> = new EventEmitter();
  selection: SelectionModel<CheckboxTreeOption<T>> = new SelectionModel(
    true,
    null
  );
  constructor() { }

  ngOnInit(): void {
    this.selection.changed
      .pipe(debounceTime(300), takeUntil(this.kill$))
      .subscribe({
        next: (acc) => {
          this.value = this.selection.selected.map((x) => x.value);
          this.selected.next(this.value);
        }
      }); /* No error handling required for material Observables. */

    this.setDefaultOptions();
  }

  selectItem(item) {
    const hierarchyArray = this._options.filter(
      (ele) => ele.hierarchyType && ele.hierarchyType === item.hierarchyType
    );
    if (hierarchyArray.length > 1) {
      if (this.selection.selected.indexOf(item) === -1) {
        this.selectHierarchyFields(item.hierarchyType);
      } else {
        this.deselectHierarchyFields(item.hierarchyType);
      }
    } else {
      this.selection.toggle(item)
    }
  }

  deselectHierarchyFields(hierarchyType) {
    this._options.forEach((opt) =>
      opt.hierarchyType === hierarchyType ? this.selection.deselect(opt) : null
    );
  }

  selectHierarchyFields(hierarchyType) {
    this._options.forEach((opt) =>
      opt.hierarchyType === hierarchyType ? this.selection.select(opt) : null
    );
  }

  ngOnDestroy() {
    this.kill$.next();
    this.kill$.complete();
  }

  /**
   * On options change set locked options to selected
   */
  setDefaultOptions() {
    if (!isArray(this._options)) {
      return;
    }
    this._options.forEach((opt) =>
      opt.locked ? this.selection.select(opt) : null
    );
  }

  /**
   * @param {string} groupName The group to toggle
   */
  toggleGroupSelect(groupName: string) {
    this.isGroupSelected(groupName)
      ? this.groupedOptions[groupName].forEach((opt) =>
        opt.locked ? null : this.selection.deselect(opt)
      )
      : this.groupedOptions[groupName].forEach((opt) =>
        opt.locked ? null : this.selection.select(opt)
      );
  }

  /**
   * @param groupName The group to check is Selected
   */
  isGroupSelected(groupName: string) {
    return (
      this.groupedOptions[groupName].length ===
      this.selection.selected.filter((x) => x.groupName === groupName).length
    );
  }

  isAllOptionsSelected() {
    return this._optionsCount === this.selection.selected.length;
  }

  toggleAllSelected() {
    this.isAllOptionsSelected()
      ? this._options.forEach((opt) =>
        opt.locked ? null : this.selection.deselect(opt)
      )
      : this._options.forEach((opt) =>
        opt.locked ? null : this.selection.select(opt)
      );
  }
}
