import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { cloneDeep, find, get, set } from 'lodash';
import { map, first } from 'rxjs/operators';
import { MetamodelMockControllerService as AssetControllerService } from '@bp2s/shared/metamodel/metamodel-mock-controller.service';
import { MetaModelMapping } from '@bp2s/model/metaModelMapping.js';
import { MetaModelControllerService } from '@bp2s/api/metaModelController.service';
import { ConfigurationService } from '@bp2s/core/services/configuration.service';
import { NGXLogger } from 'ngx-logger';

const envsUsingAssets = new Set(['DEV', '', 'cib', 'dev', 'local']);


/************************************************************/
/*                     VERY IMPORTANT                       */
/************************************************************/
/* Please use node ./tools/scripts/metamodel.js --create    */
/* to create/add any metamodel definition.                  */
/************************************************************/


@Injectable({ providedIn: 'root' })
export class MetamodelService {
  store = {};

  constructor(
    private readonly metaModelControllerService: MetaModelControllerService,
    private readonly assetControllerService: AssetControllerService,
    private readonly configurationService: ConfigurationService,
    private readonly logger: NGXLogger,
  ) {
    this.logger.info('ENV_NAME', this.configurationService.configuration?.envName);
  }

  getMetamodelByKey(
    key: string,
    feature: string,
    subkey: string
  ): Observable<any> {
    const mocked = envsUsingAssets.has(this.configurationService.configuration?.envName);
    if (get(this.store, [key, feature, '' + mocked])) {
      const stored = cloneDeep(get(this.store, [key, feature, '' + mocked]));
      if (subkey === 'all') {
        return of(stored);
      }
      return of(this.filterBySubkey(stored, feature, subkey));
    } else {
      return this.updateStore(key, feature, subkey, mocked);
    }
  }

  getMetaModelUsingGET(
    key: string,
    feature: string,
    mocked: boolean
  ): Observable<Array<MetaModelMapping>> {
    if (mocked) {
      return this.assetControllerService.getMetaModelUsingGET(key, feature);
    } else {
      return this.metaModelControllerService.metaModelGetMetaModelUsingGET(key, feature);
    }
  }

  updateStore(
    key: string,
    feature: string,
    subkey: string,
    mocked: boolean
  ): Observable<any> {
    const obs$: Observable<Array<MetaModelMapping>> = this.getMetaModelUsingGET(key, feature, mocked);
    return obs$.pipe(
      first(),
      map((metaData:Array<MetaModelMapping>) => {
        set(this.store, [key, feature, '' + mocked], metaData);
        if (subkey === 'all') {
          return cloneDeep(metaData);
        }
        return this.filterBySubkey(cloneDeep(metaData), feature, subkey);
      })
    );
  }

  getKeyName(feature): string {
    switch(feature) {
      case 'businessRules': {
        return 'daisyChainID';
      }
      case 'portfolioModifiers': {
        return 'pmID';
      }
      default: {
        return `${feature}ID`;
      }
    }
  }
  filterBySubkey(data: any[], feature, subkey) {
    const checkKey = this.getKeyName(feature);
    const pattern = {};
    pattern[checkKey] = subkey;
    const found = find(data[0].metaData, pattern);
    if (found) {
      return found[`${feature}Config`];
    }
  }
}
