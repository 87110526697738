import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { LocalStorageService, SessionStorageService } from 'ngx-webstorage';
import { User, UserManager, Profile } from 'oidc-client';
import { STORAGE_TOKENS } from '@bp2s/core/consts/ls-tokens.const';
import { MOCK_TOKEN } from '@bp2s/core/auth/auth.consts';
import { Subject, lastValueFrom } from 'rxjs';
import { catchError, first, switchMap } from 'rxjs/operators';
import { GLOBAL_ERROR } from '@bp2s/core/consts/global-error.consts';

import { SentryService } from '@bp2s/core/services/sentry-error-handler.service';
import { ConfigurationService } from '@bp2s/core/services/configuration.service';
import { MatDialog } from '@angular/material/dialog';
import { DisclaimerComponent } from '@bp2s/core/disclaimer/disclaimer.component';
import { MetamodelService } from '@bp2s/shared/metamodel/metamodel.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  manager: UserManager;
  user: User;
  user$: Subject<User> = new Subject();
  alreadyShown = false;

  constructor(
    public router: Router,
    private sentryService: SentryService,
    private localStorageService: LocalStorageService,
    private sessionStorageService: SessionStorageService,
    private configService: ConfigurationService,
    private dialog: MatDialog,
    private metamodelService: MetamodelService,
  ) {}

  async init() {
    this.alreadyShown = this.sessionStorageService.retrieve('alreadyShown');
    this.manager = new UserManager(this.getClientSettings());

    const user = await this.manager.getUser();
    this.setUser(user);

    if (this.configService.configuration.auth.mocked) {
      const token: any = this.localStorageService.retrieve(
        STORAGE_TOKENS.MOCK_USER_TOKEN
      );
      return this.useMockUser(token ? token : MOCK_TOKEN);
    }

    this.subscribeevents();
  }

  useMockUser(token = MOCK_TOKEN) {
    const mockUser = token;
    this.setUser(mockUser as any);
    this.localStorageService.store(STORAGE_TOKENS.MOCK_USER_TOKEN, this.user);
    this.user$.next(this.user);
  }

  useMockResponsibilities(authorities: any) {
    this.user.profile.authorities = authorities;
    this.localStorageService.store(STORAGE_TOKENS.MOCK_USER_TOKEN, this.user);
    this.user$.next(this.user);
  }

  subscribeevents() {
    this.manager.events.addUserLoaded((user) => {
      this.setUser(user);
    });
    this.manager.events.addSilentRenewError(() => {
      // eslint-disable-next-line no-console
      console.log('error SilentRenew');
    });
    this.manager.events.addAccessTokenExpiring(() => {
      // eslint-disable-next-line no-console
      console.log('access token expiring');
    });
    this.manager.events.addAccessTokenExpired(() => {
      // eslint-disable-next-line no-console
      console.log('access token expired');
    });
  }

  isLoggedInPromise(): Promise<User> {
    return this.manager.getUser();
  }

  isLoggedIn(): boolean {
    return this.user != null && !this.user.expired;
  }

  getClaims(): Profile {
    return this.user.profile;
  }

  getAuthorizationHeaderValue(): string {
    if (this.configService?.configuration?.auth?.mocked) {
      return `${
        this.user.token_type
      } eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.${window.btoa(
        JSON.stringify(this.user.profile)
      )}.886QVUtDtRianeskJyFu28maqcTb5bdp9HQOaHNNkOM`;
    }
    return `${this.user?.token_type} ${this.user?.access_token}`;
  }

  startAuthentication(): Promise<void> {
    return this.manager.signinRedirect();
  }

  startLogout(): Promise<void> {
    return this.manager.removeUser().then(() => {
      /* 
      Need to validate this change
      window.location = <any>this.configService.configuration.auth.sso;
      */
      window.location.assign(<any>this.configService.configuration.auth.sso);
    });
  }

  async completeAuthentication() {
    let pageShouldBeLoaded = true;
    const user = await this.manager.signinRedirectCallback();

    this.setUser(user);

    if (!user) {
      return this.router.navigate(['/', 'error'], {
        queryParams: { error: GLOBAL_ERROR.AUTH_FAILURE, keepUrl: true },
        replaceUrl: false,
        skipLocationChange: true,
      });
    }

    if (!this.alreadyShown) {
      await lastValueFrom(
        this.metamodelService.getMetamodelByKey('edit', 'disclaimer', 'text').pipe(
          switchMap(meta => this.dialog.open(DisclaimerComponent, {
            hasBackdrop: true,
            height: '80%',
            width: '80%',
            disableClose: true,
            data: meta,
          }).afterClosed()),
          first(),
          catchError(error => {
            pageShouldBeLoaded = false;
            return this.router.navigate(['/', 'error'],
              { queryParams: { error, keepUrl: true },
              replaceUrl: false,
              skipLocationChange: true,
            });
          })
      ));
      this.alreadyShown = true;
    }
    const url: string = this.localStorageService.retrieve(
      STORAGE_TOKENS.ST_PREVIOUS_URL
    );

    this.localStorageService.clear(STORAGE_TOKENS.ST_PREVIOUS_URL);
    if (pageShouldBeLoaded) {
      setTimeout(() => {
        if (url) {
          return this.router.navigate(url.split('/'));
        }
        this.router.navigate(['/', 'accounts']);
      });
    }
  }

  getIssuerString() {
    const index = this.manager.settings.authority.indexOf('/oauth');
    return this.manager.settings.authority.slice(0, index);
  }

  completeLogout(url?: string) {
    return this.manager.signoutRedirectCallback(url).then((user) => {
      return this.manager.clearStaleState();
    });
  }

  setUser(user: User) {
    this.user = user;

    if (user) {
      this.sentryService.setUser(user);

      this.user.profile.authorities =
        this.user.profile.authorities.map((auth: string) =>
          auth.toUpperCase()
        ) ?? [];
      this.user$.next(this.user);
    }
  }

  removeUser() {
    this.manager.removeUser();
  }

  getClientSettings() {
    const { auth } = this.configService.configuration;
    return {
      authority: auth.authority,
      client_id: auth.client_id,
      redirect_uri: auth.login,
      post_logout_redirect_uri: auth.logout,
      response_type: 'code',
      scope: 'openid profile email phone address',
      filterProtocolClaims: true,
      loadUserInfo: true,
      // userStore: new WebStorageStateStore({ store: window.localStorage }),
      accessTokenExpiringNotificationTime: 60,
      //default value
      silentRequestTimeout: 10000,
      includeIdTokenInSilentRenew: true,
      automaticSilentRenew: true,
      silent_redirect_uri: auth.silent_redirect,
    };
  }
}
