import { UntypedFormControl, ValidationErrors } from '@angular/forms';
import { isNil } from 'lodash';

export const MAX_DIGITS = 10;
export const DEFAULT_PRECISION = 6;

export function generateInlineNumberValidator(precision: number): (control: UntypedFormControl) => boolean {
  return function (control: UntypedFormControl): boolean {
    if (isNil(control.value)) {
      return true;
    }
    let value = String(control.value);
    value = value.trim();
    if (value === '-' || value === '.' || value === '-.') {
      return false;
    }
    if (value === '') {
      return true;
    }
    let unsigned = value;
    if (value.startsWith('-')) {
      unsigned = value.substring(1);
    }
    const dotIndex = unsigned.indexOf('.');
    if (dotIndex === -1) {
      return /^\d+$/.test(unsigned) && (unsigned.length<MAX_DIGITS);
    }
    const left = unsigned.substring(0, dotIndex);
    const right = unsigned.substring(dotIndex+1);

    return /^\d+$/.test(left) &&
      /^\d+$/.test(right) &&
      ((left.length + right.length) <= MAX_DIGITS) &&
      (right.length <= precision);
  }
}

function generateGlobalNumberValidatorFn(precision: number): (control: UntypedFormControl) => ValidationErrors {
  const fn = generateInlineNumberValidator(precision);
  return function(control: UntypedFormControl): ValidationErrors {
    let ret = { number: true };
    if (fn(control)) {
      ret = null;
    }
    return ret;
  }
}

export const numberValidator = generateGlobalNumberValidatorFn(DEFAULT_PRECISION);
