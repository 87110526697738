<ng-container *ngIf="props.inline; else elseBlock">
  <section
    [class.empty]="field.fieldGroup.length === 0"
    class="form-array-inline"
  >
    <div *ngFor="let field of field.fieldGroup; let i = index" class="form-row">
      <formly-field class="col" [field]="field"></formly-field>
      <button
        mat-flat-button
        color="warn"
        class="btn btn-danger btn-remove-form-group"
        type="button"
        *ngIf="!props.hidden"
        (click)="remove(i)"
        [disabled]="props.removeDisabled || ((props.removeDisabledRow && props.removeDisabledRow[i])?props.removeDisabledRow[i]:false)"
      >
        {{ props.removeText || 'Remove' }}
      </button>
    </div>
  </section>
  <div *ngIf="!props.hidden">
    <button mat-flat-button class="btn-add-form-group" color="primary" type="button" (click)="add(); remove(field.fieldGroup.length-1); add()" [disabled]="props.addDisabled">
      <mat-icon>add</mat-icon>
      {{ props.addText }}
    </button>
  </div>
</ng-container>

<ng-template #elseBlock>
  <section class="repeat-card">
    <section
      [class.empty]="field.fieldGroup.length === 0"
      class="repeat-card__array"
    >
      <div
        *ngFor="let field of field.fieldGroup; let i = index"
        class="repeat-card__row"
      >
        <formly-field class="col" [field]="field"></formly-field>

        <article class="repeat-card__remove">
          <button
            mat-flat-button
            color="warn"
            class="btn btn-danger"
            type="button"
            (click)="remove(i)"
          >
            {{ props.removeText || 'Remove' }}
          </button>
        </article>
      </div>
    </section>
  </section>

  <section class="repeat-card__add">
    <button
      mat-flat-button
      class="btn btn-primary"
      type="button"
      (click)="add()"
    >
      {{ props.addText || 'Add' }}
    </button>
  </section>

  <button
    *ngIf="props.duplicate && model?.length > 0"
    mat-flat-button
    class="btn btn-primary"
    type="button"
    (click)="duplicate()"
  >
    {{ props.duplicateText || 'Duplicate' }}
  </button>
</ng-template>
