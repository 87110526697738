import { each } from 'lodash';
import { Errors } from '@bp2s/model/errors';

/**
 *  This is all completely insane and should be removed as soon as possible.
 */

export class ErrorViewModel {
  public field: string;
  public message: string;
}

enum ErrorCategories {
  MISSING_FIELD,
  PRESENCE_NOT_ALLOWED,
  INVALID_ENUM_VALUE,
  INVALID_SIZE,
  YES_NO,
  INVALID_DATE,
  UNICITY_VIOLATION,
  INVALID_UPDATE,
  UPDATE_READ_ONLY_FIELD,
  INVALID_FIELD_UPDATE,
  EXCEPTION,
  PRE_CONDITION_VIOLATION,
  ACCOUNT_NOT_FOUND,
  INVALID_NUMBER,
  UNSUPPORTED_ASSET_TYPE,
  INVALID_VALUE,
  INVALID_STATE,
  SECURITY_NOT_FOUND,
  INVALID_ACCT_SUFFIX,
  INTERNAL_SERVER_ERROR,
  BAD_REQUEST,
  INVALID_INDEX_PERCENT,
  INVALID_INDEX_VALUE,
}

export class ErrorParser {
  private listOfErrors: Array<ErrorViewModel>;
  private groupedListOfErrors: Map<string, Array<ErrorViewModel>> = new Map<
    string,
    Array<ErrorViewModel>
  >();

  public parse(errObj: any): Map<string, Array<ErrorViewModel>> {
    this.groupedListOfErrors.clear();
    if (typeof errObj === 'string') {
      return this.handleStringError(errObj);
    }
    if (Object.keys(errObj).length === 0) {
      return;
    }
    Object.keys(errObj.errors).forEach(x => {
      if (x === 'UNICITY_VIOLATION') {
        const newKey = 'Account not unique';
        errObj.errors[newKey] = errObj.errors[x];
        delete errObj.errors[x];
      }
    });
    return this.handleValidationErrors(errObj);
  }

  private handleValidationErrors(errObj: Errors) {
    each(Object.keys(errObj.errors), errorCategory => {
      this.groupedListOfErrors.set(errorCategory, []);
      this.listOfErrors = new Array<ErrorViewModel>();

      each(errObj.errors[errorCategory], field => {
        const errorVM = new ErrorViewModel();
        if (
          errorCategory === ErrorCategories[ErrorCategories.INVALID_UPDATE] ||
          errorCategory ===
            ErrorCategories[ErrorCategories.UNICITY_VIOLATION] ||
          errorCategory === ErrorCategories[ErrorCategories.INVALID_STATE]
        ) {
          errorVM.message = this.getCategoryMessage(
            errorCategory,
            null,
            field.errors
          );
        } else if (errorCategory === 'Account not unique') {
          errorVM.message = field.errors[0];
        } else {
          errorVM.field = field.paths[0]?.path;
          if (field.paths.length === 3) {
            errorVM.message = this.getComplexFieldMessage(field);
          } else {
            errorVM.message = this.getCategoryMessage(
              errorCategory,
              errorVM.field,
              field.errors
            );
          }
        }
        this.listOfErrors.push(errorVM);
      });
      this.groupedListOfErrors.set(errorCategory, this.listOfErrors);
    });

    return this.groupedListOfErrors;
  }

  private handleStringError(errorString: string) {
    this.listOfErrors = new Array<ErrorViewModel>();
    const accessDenied = new ErrorViewModel();
    accessDenied.message = errorString;
    this.listOfErrors.push(accessDenied);
    this.groupedListOfErrors.set(
      ErrorCategories[ErrorCategories.EXCEPTION],
      this.listOfErrors
    );
    return this.groupedListOfErrors;
  }

  private getComplexFieldMessage(field) {
    if (field.errors.length === 0) {
      return `${field.paths[2].path} of ${
        field.paths[0].path
      } at position ${field.paths[1].index + 1}`;
    } else {
      return `${field.paths[2].path} of ${
        field.paths[0].path
      } at position ${field.paths[1].index + 1} ${field.errors.join(' and ')}`;
    }
  }

  private getCategoryMessage(
    errorCategory: string,
    fieldName?: string,
    errors?: Array<string>
  ) {
    switch (errorCategory) {
      case ErrorCategories[ErrorCategories.INVALID_VALUE]:
      case ErrorCategories[ErrorCategories.MISSING_FIELD]:{
        if (errors) {
          return `${fieldName}: ${errors.join(',')}`;
        } else {
          return `${fieldName}`;
        }
      }
      case ErrorCategories[ErrorCategories.PRESENCE_NOT_ALLOWED]: {
        if (fieldName) {
          return `${fieldName} is not allowed`;
        } else {
          return `${errors.join(',')}`;
        }
      }
      case ErrorCategories[ErrorCategories.INVALID_ENUM_VALUE]: {
        return `${fieldName} is not a valid value`;
      }
      case ErrorCategories[ErrorCategories.INVALID_SIZE]: {
        return `Please note the ${fieldName} has exceeded the maximum length, ${errors.join(',')}`;
      }
      case ErrorCategories[ErrorCategories.YES_NO]: {
        return `${fieldName} needs user input`;
      }
      case ErrorCategories[ErrorCategories.INVALID_DATE]:
      case ErrorCategories[ErrorCategories.ACCOUNT_NOT_FOUND]: {
        if (errors) {
          return `${fieldName} ${errors.join(',')}`;
        } else {
          return `${fieldName}`;
        }
      }
      case ErrorCategories[ErrorCategories.UNICITY_VIOLATION]: {
        return errors.join(',');
      }
      case ErrorCategories[ErrorCategories.INVALID_UPDATE]: {
        return `${errors.join(',')}`;
      }
      case ErrorCategories[ErrorCategories.UPDATE_READ_ONLY_FIELD]: {
        return `${fieldName} is readonly and cannot be changed.`;
      }
      case ErrorCategories[ErrorCategories.INVALID_FIELD_UPDATE]: {
        return `${fieldName} ${errors.join(',')} cannot be changed`;
      }
      case ErrorCategories[ErrorCategories.PRE_CONDITION_VIOLATION]:
      case ErrorCategories[ErrorCategories.INVALID_NUMBER]: {
        return `${fieldName}: ${errors.join(',')}`;
      }
      case ErrorCategories[ErrorCategories.UNSUPPORTED_ASSET_TYPE]: {
        return 'A bulk upload / update and edit cannot be supported by the application for this asset type';
      }
      case ErrorCategories[ErrorCategories.SECURITY_NOT_FOUND]:
      case ErrorCategories[ErrorCategories.INVALID_ACCT_SUFFIX]:
      case ErrorCategories[ErrorCategories.INVALID_STATE]:
      case ErrorCategories[ErrorCategories.EXCEPTION]:
      case ErrorCategories[ErrorCategories.BAD_REQUEST]:
      case ErrorCategories[ErrorCategories.INVALID_INDEX_PERCENT]:        
      case ErrorCategories[ErrorCategories.INVALID_INDEX_VALUE]:
      case ErrorCategories[ErrorCategories.INTERNAL_SERVER_ERROR]: {
        return `${errors.join(',')}`;
      }
    }
  }
}
