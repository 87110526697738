import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DynamicTabContainerComponent } from './dynamic-tab-container/dynamic-tab-container.component';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { RouterModule } from '@angular/router';
import { DirectivesModule } from '../../core/directives/directives.module';
import { MatProgressBarModule } from '@angular/material/progress-bar';


@NgModule({
  declarations: [DynamicTabContainerComponent],
  imports: [
    CommonModule,
    MatIconModule,
    MatMenuModule,
    DragDropModule,
    MatMenuModule,
    RouterModule,
    DirectivesModule,
    MatButtonModule,
    MatProgressBarModule
  ],
  exports: [DynamicTabContainerComponent]
})
export class DynamicTabModule { }
