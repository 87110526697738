import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  BP2SButtonToggle,
  BP2SButtonToggleGroup
} from './button-toggle-field.component';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [BP2SButtonToggleGroup, BP2SButtonToggle],
  imports: [CommonModule, ReactiveFormsModule],
  exports: [BP2SButtonToggleGroup, BP2SButtonToggle]
})
export class ButtonToggleFieldModule {}
