export const PROFILE_RONAN = {
  jti: 'NoQIi3a1SMBf1OXN_jn2HQ',
  sub: 'm09808',
  auth_time: 1582728642,
  acr: 'urn:bp2s:oidc:auth-level:low',
  amr: ['pwd'],
  sid: 'UlFhckZXTk5EMmhhc1IxWTJVMUJYbGw1blpORTBhd0d4a0p3Y3k5cVVNRDZaRGFTVHo',
  family_name: 'BRETT',
  given_name: 'Ronan',
  name: 'Mock BRETT',
  gender: 'male',
  locale: 'en',
  updated_at: 1582234994,
  email: 'mock.brett@bnpparibas.com',
  phone_number: '+353 89 613 2456',
  address: {
    street_address: 'Trinity point 10-11 Leinster Street South - Dublin 2',
    country: 'Ireland',
    locality: 'DUBLIN',
  },
  authorities: [
    'Performance_Manager',
    'BNP_Paribas_Operational_Member',
    'Client_Ops_Manager',
    'IDM_Team_Member',
    'Performance_Member',
    'BNP_Paribas_Manager',
    'IDM_Team_Manager',
    'Client_Ops_Member',
  ],
};

export const PROFILE_MICHELLE = {
  jti: 'HY3SavAzkRb2dw6eFZPEoA',
  sub: 'm07016',
  family_name: 'HART',
  given_name: 'Michelle',
  name: 'Mock HART',
  gender: 'female',
  locale: 'en',
  updated_at: 1565087793,
  email: 'mock.hart@bnpparibas.com',
  address: {
    street_address: 'Grand Canal Plaza',
    country: 'Ireland',
    locality: 'DUBLIN',
  },
  authorities: [
    'TEST_ROLE',
    'Performance_Manager',
    'BNP_Paribas_Operational_Member',
    'Client_Ops_Manager',
    'IDM_Team_Member',
    'Performance_Member',
    'BNP_Paribas_Manager',
    'View_Only',
    'IDM_Team_Manager',
    'Client_Ops_Member',
  ],
};

export const PROFILE_HASSAN = {
  jti: 'HY3SavAzkRb2dw6eFZPEoA',
  sub: 'm63800',
  family_name: 'WEBHI',
  given_name: 'Hassan',
  name: 'Mock WEBHI',
  gender: 'male',
  locale: 'en',
  updated_at: 1565087793,
  email: 'mock.webhi@bnpparibas.com',
  authorities: [
    'TEST_ROLE',
    'Performance_Manager',
    'BNP_Paribas_Operational_Member',
    'Client_Ops_Manager',
    'IDM_Team_Member',
    'Performance_Member',
    'BNP_Paribas_Manager',
    'View_Only',
    'IDM_Team_Manager',
    'Client_Ops_Member',
  ],
};

export const PROFILE_XAVIER = {
  jti: 'HY3SavAzkRb2dw6eFZPEoA',
  sub: 'm58409',
  family_name: 'AUBERON',
  given_name: 'Xabvier',
  name: 'Mock AUBERON',
  gender: 'male',
  locale: 'en',
  updated_at: 1565087793,
  email: 'mock.auberon@bnpparibas.com',
  authorities: [
    'TEST_ROLE',
    'Performance_Manager',
    'BNP_Paribas_Operational_Member',
    'Client_Ops_Manager',
    'IDM_Team_Member',
    'Performance_Member',
    'BNP_Paribas_Manager',
    'View_Only',
    'IDM_Team_Manager',
    'Client_Ops_Member',
  ],
};

export const PROFILE_ELALEM = {
  jti: 'HY3SavAzkRb2dw6eFZPEoA',
  sub: 'm58409',
  family_name: 'ELAHEM',
  given_name: 'Lahcen',
  name: 'Mock ELAHEM',
  gender: 'male',
  locale: 'en',
  updated_at: 1565087793,
  email: 'mock.elalem@externe.bnpparibas.com',
  authorities: [
    'TEST_ROLE',
    'Performance_Manager',
    'BNP_Paribas_Operational_Member',
    'Client_Ops_Manager',
    'IDM_Team_Member',
    'Performance_Member',
    'BNP_Paribas_Manager',
    'View_Only',
    'IDM_Team_Manager',
    'Client_Ops_Member',
  ],
};

export const PROFILES = [
  PROFILE_RONAN,
  PROFILE_MICHELLE,
  PROFILE_HASSAN,
  PROFILE_XAVIER,
  PROFILE_ELALEM,
];

const MOCK_ID_TOKEN = 'ID_TOKEN';
 const MOCK_ACCESS_TOKEN = 'ACCESS_TOKEN';
const MOCK_REFRESH_TOKEN = 'REFRESH_TOKEN';


export const MOCK_TOKEN = {
  id_token: MOCK_ID_TOKEN,
  access_token: MOCK_ACCESS_TOKEN,
  refresh_token: MOCK_REFRESH_TOKEN,
  token_type: 'Bearer',
  scope: 'openid profile email phone address',
  profile: PROFILE_RONAN,
  expires_at: 1582736017,
};

export const MOCK_TOKEN2 = {
  id_token: MOCK_ID_TOKEN,
  access_token: MOCK_ACCESS_TOKEN,
  refresh_token: MOCK_REFRESH_TOKEN,
  token_type: 'Bearer',
  scope: 'openid profile email phone address',
  profile: PROFILE_MICHELLE,
  expires_at: 1582736017,
};

export const MOCK_TOKEN3 = {
  id_token: MOCK_ID_TOKEN,
  access_token: MOCK_ACCESS_TOKEN,
  refresh_token: MOCK_REFRESH_TOKEN,
  token_type: 'Bearer',
  scope: 'openid profile email phone address',
  profile: PROFILE_XAVIER,
  expires_at: 1582736017,
};

export const TOKENS = [MOCK_TOKEN, MOCK_TOKEN2, MOCK_TOKEN3];
