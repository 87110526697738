import { Injectable } from '@angular/core';
import { CommonDialogComponent } from '@bp2s/core/common-dialog/common-dialog.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { CommonDialogData } from '@bp2s/core/common-dialog/common-dialog-data';

@Injectable({
  providedIn: 'root',
})
export class CommonDialogService {
  constructor(private dialog: MatDialog) {}
  dialogRef: MatDialogRef<CommonDialogComponent>;
  open(options: CommonDialogData) {
    return this.dialog.open(CommonDialogComponent, {
      width: options.width ? options.width : '450px',
      hasBackdrop: true,
      data: {
        title: options.title,
        message: options.message,
        cancelText: options.cancelText,
        confirmText: options.confirmText,
        template: options.template,
      },
    });
  }
}

