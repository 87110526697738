import { Injectable } from '@angular/core';
import { isEmpty } from 'lodash';
import { NGXLogger } from 'ngx-logger';
import { GlobalJobsService } from '@bp2s/core/services/global-jobs.service';


@Injectable({
  providedIn: 'root'
})
export class UnloadService {


  constructor(
    private globalJobService: GlobalJobsService,
    private logger: NGXLogger
  ) {
  }

  unloadListener(event: Event) {
    const activeJobs = this.globalJobService.activeJobs();
    if(!isEmpty(activeJobs)) {
      this.logger.info('Closing the page while the following tasks are running:', activeJobs.join(', '));

      // Cancel the event
      event.preventDefault(); // If you prevent default behavior in Mozilla Firefox prompt will always be shown

      return false;
    }
  }

}
