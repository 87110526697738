import { Injectable } from '@angular/core';
import { AllowedCharsetTable } from '@bp2s/model/allowedCharsetTable';
import { isEmpty } from 'lodash';

export const TEST_CODES = [9,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114,115,116,117,118,119,120,121,122,123,124,125,126,8217,8221];
export const TEST_CHARACTERS = TEST_CODES.map(code => String.fromCharCode(code));

@Injectable({
  providedIn: 'root'
})
export class CharsetService {
  public static charset: AllowedCharsetTable;

  static getAllowedCharacters(): string[] {
    let ret = [];
    if (!isEmpty(CharsetService.charset?.codes)) {
      ret = CharsetService.charset.codes.map(code => String.fromCharCode(code));
    }
    /* istanbul ignore next */
    else if (process.env.JEST_WORKER_ID !== undefined) {
      ret = TEST_CHARACTERS;
    }

    return ret;
  }

  /* Used for testing only (fixing this is not worth the headache). */
  static _set(allowedCharsetTable: AllowedCharsetTable) {
    this.charset = allowedCharsetTable;
  }
}
