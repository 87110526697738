import {
  Component,
  Input,
  HostBinding,
} from '@angular/core';
import {
  GlobalNavItem,
  GlobalNavType
} from '../../core/models/global-nav-item.model';

@Component({
  selector: 'bp2s-left-nav-item',
  templateUrl: './left-nav-item.component.html',
  styleUrls: ['./left-nav-item.component.scss']
})
export class LeftNavItemComponent {
  @Input() item: GlobalNavItem;
  types = GlobalNavType;

  @Input() selected = false;

  @HostBinding('class.selected') get isSelected() {
    return this.selected;
  }

  constructor() {}
}
