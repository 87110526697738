import { FormlyFieldConfig } from '@ngx-formly/core';

export function prefixesExtension(field: FormlyFieldConfig) {
  if (
    !field.props ||
    (field.wrappers && field.wrappers.indexOf('prefixes') !== -1)
  ) {
    return;
  }

  if (field.props.prefixLeft || field.props.prefixRight || field.props.prefixRightSecondaryIcon) {
    field.wrappers = [...(field.wrappers || []), 'prefixes'];
  }
}
