import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
} from '@angular/common/http';
import { AuthService } from '@bp2s/core/auth/auth.service';
import { ConfigurationService } from '@bp2s/core/services/configuration.service';

@Injectable({ providedIn: 'root' })
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private configService: ConfigurationService,

    private auth: AuthService
  ) {}
  intercept(req: HttpRequest<any>, next: HttpHandler) {
    if (this.configService.configuration?.auth?.mocked) {
      req = req.clone({
        headers: req.headers.set(
          'Authorization',
          this.auth.isLoggedIn()
            ? this.auth.getAuthorizationHeaderValue()
            : 'eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImtpZCI6IjkxODM2ZjEyMmJmYWRmZjllM2FlYjNlYzRhMmEzODUyYWM3OGY0YzgifQ.eyJqdGkiOiJKbF9YTzJZaWlybEI0dHpKT2pXVS1BIiwiaXNzIjoiaHR0cHM6Ly9zc29sb2dpbi1icDJzLmZyLm5ldC5pbnRyYSIsImF1ZCI6IjA2ZDJhOTY5LWI4MGEtNTM5Mi05MTY4LTIzNGVmZGM3MDE0ZiIsIm5iZiI6MTU1OTA0MzY4MSwiaWF0IjoxNTU5MDQzNjgxLCJleHAiOjE1NTkwNDM4MDEsInN1YiI6ImQwNzAxNiIsImF1dGhvcml0aWVzIjpbIlRFU1RfUk9MRSIsIkFuYWx5c3RfQU1fQU5EX1BUIiwiQW5hbHlzdF9QVCIsIkJOUF9QYXJpYmFzX09wZXJhdGlvbmFsX01lbWJlciIsIkJOUF9QYXJpYmFzX01hbmFnZXIiLCJTdXBlcnZpc29yX1BUIiwiUGVyZm9ybWFuY2VfTWFuYWdlciIsIlN1cGVydmlzb3JfQU1fQU5EX1BUIiwiQk5QX1BhcmliYXNfQWRtaW5pc3RyYXRvciIsIkNsaWVudF9PcHNfTWFuYWdlciIsIklETV9UZWFtX01lbWJlciIsIlBlcmZvcm1hbmNlX01lbWJlciIsIlZpZXdfT25seSIsIklETV9UZWFtX01hbmFnZXIiLCJDbGllbnRfT3BzX01lbWJlciIsIlJFQURPTkxZX1BUIl19.AvEAcmIGFsjBnKYqhY1Dg_w38RoKtVH7XuRjfNX72CRQQTon8qR3ePPxFts3UnzgJ4aSdZsFNMT1lBpdXs0QJMmz5cgZx9pf6Dd88N0ifAr3W8enqSUNQihqAMf9XCAcFQjvREOdGJhcaAM0I1Q3UZB5_GdOUgUdi6d6U70mVWotgJTeYkGQqE3fd7rBU086bmUW3uf29FEc46W9jIUK3opYloGmBINZ-GqG0YsWKqzrg5atQ057sZOrsBMuaEJ2VBkB3kyC6bP0j1VtUxbMhtLL5fMuBja1o8tHxd9V9JA5gv4FjwSLfKyDQnhQkFbqzdzTc8b8_7MQITHJTdOYGg'
        ),
      });
      return next.handle(req);
    }

    req = req.clone({
      headers: req.headers.set(
        'Authorization',
        this.auth.getAuthorizationHeaderValue()
      ),
    });

    return next.handle(req);
  }
}
