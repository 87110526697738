import { Injectable, Optional, Inject } from '@angular/core';
import { SessionStorageService } from 'ngx-webstorage';
import { BehaviorSubject } from 'rxjs';
import { IBreadcrumb } from '../../core/breadcrumb/breadcrumb.model';
import { isNumber, isArray, isEqual } from 'lodash';
import { moveItemInArray, CdkDragDrop } from '@angular/cdk/drag-drop';
import { DYNAMIC_TAB_CONFIG } from '../../tokens.consts';
import { Tab } from '../models/tab.model';
import { NGXLogger } from 'ngx-logger';
import { DynamicTabMenuItem } from '../models/models';
import { Params, Router } from '@angular/router';

export interface DynamicTabConfigParam {
  storageKey?: string;
}

export class DynamicTabConfig {
  storageKey = 'BP2S_DEFAULT_TAB';

  constructor(config: DynamicTabConfigParam = {}) {
    this.storageKey = config.storageKey ? config.storageKey : this.storageKey;
  }
}

@Injectable({
  providedIn: 'root'
})
export class DynamicTabService {
  menuItems: BehaviorSubject<DynamicTabMenuItem[]> = new BehaviorSubject([]);
  tabs: BehaviorSubject<Tab[]> = new BehaviorSubject([]);
  currentTab: BehaviorSubject<number> = new BehaviorSubject(0);
  _currentTab = 0;
  progress: BehaviorSubject<boolean> = new BehaviorSubject(false);
  skipGuards = false;
  outletGuards = [];
  outletComponent: any;
  NO_PAGE_RELOAD = 1000;
  protected CURRENT_TAB_TOKEN = '_CURRENT_TAB';
  protected TAB_LIST_TOKEN = '_TABS';
  constructor(
    private logger: NGXLogger,
    @Optional() @Inject(DYNAMIC_TAB_CONFIG) config: DynamicTabConfig,
    @Optional() private sessionStorage: SessionStorageService,
    @Optional() private router: Router,
  ) {
    if (!sessionStorage) {
      throw new Error(
        'You must provide ngx-webstorage (SessionStorageService) to use DynamicTabServices'
      );
    }
    if (!config) {
      throw new Error(
        'You must provide a DYNAMIC_TAB_CONFIG to use DynamicTabService'
      );
    }
    if (!config.storageKey) {
      throw new Error(
        'You must provide a storageKey token to use DynamicTabService'
      );
    }
    if (!router) {
      throw new Error(
        'You must provide an instance of Router to use DynamicTabServices'
      );
    }

    this.CURRENT_TAB_TOKEN = config.storageKey + this.CURRENT_TAB_TOKEN;
    this.TAB_LIST_TOKEN = config.storageKey + this.TAB_LIST_TOKEN;

    this.logger.debug('CURRENT_TAB_TOKEN', this.CURRENT_TAB_TOKEN);
    this.logger.debug('TAB_LIST_TOKEN', this.TAB_LIST_TOKEN);

    this.tryRestorePreviousTabs();
  }

  tryRestorePreviousTabs() {
    const currentTab = this.sessionStorage.retrieve(this.CURRENT_TAB_TOKEN);
    const tabs = this.sessionStorage.retrieve(this.TAB_LIST_TOKEN);
    if (
      isNumber(currentTab) &&
      isArray(tabs) &&
      tabs.length > 0 &&
      tabs.length - 1 >= currentTab
    ) {
      this.tabs.next(tabs);
      this._currentTab = currentTab;
      this.currentTab.next(currentTab);
    }

  }

  setAvailableTabs(items: DynamicTabMenuItem[]){
    this.menuItems.next(items);
  }

  createNewTabNoFocus(tabDefinition: any) {
    let tabs = this.tabs.getValue();
    tabs = [...tabs, tabDefinition];
    this.tabs.next(tabs);
  }

  createNewTab(path: string) {
    let tabs = this.tabs.getValue();
    tabs = [...tabs, { path }];
    this.tabs.next(tabs);
    this.setActiveTab(tabs.length - 1);
  }

  setActiveTab(index: number) {
    this._currentTab = index%this.NO_PAGE_RELOAD;
    this.currentTab.next(index);
    this.sessionStorage.store(this.CURRENT_TAB_TOKEN, this._currentTab);
  }

  setProgressActive(isActive: boolean){
    this.progress.next(isActive);
  }

  moveTab(event: CdkDragDrop<string[]>) {
    const tabs = this.tabs.getValue();
    moveItemInArray(tabs, event.previousIndex, event.currentIndex);
    this.tabs.next(tabs);
    if (event.previousIndex === this._currentTab) {
      this._currentTab = event.currentIndex;
    } else if (((event.previousIndex>this._currentTab) && (event.currentIndex>this._currentTab)) ||
        ((event.previousIndex<this._currentTab) && (event.currentIndex<this._currentTab))) {
      return;
    } else if ((event.previousIndex>this._currentTab) && (event.currentIndex<=this._currentTab)) {
      this._currentTab += 1;
    } else if ((event.previousIndex<this._currentTab) && (event.currentIndex>=this._currentTab)) {
      this._currentTab -= 1;
    }
    this.setActiveTab(this._currentTab+this.NO_PAGE_RELOAD);
  }

  updateTabInfo(
    path: string,
    breadCrumbs: IBreadcrumb[],
    tabIndex = this._currentTab
  ) {
    const tabs = this.tabs.getValue();
    tabs[tabIndex] = {
      path,
      breadCrumb: breadCrumbs[0],
      subBreadCrumb: breadCrumbs[1]
    };

    this.tabs.next(tabs);

    this.sessionStorage.store(this.TAB_LIST_TOKEN, tabs);
  }

  updatePathByTabIndex(tabIndex: number, path: string) {
    const tabs = this.tabs.getValue();
    tabs[tabIndex] = { ...tabs[tabIndex], path };
    this.tabs.next(tabs);
    this.sessionStorage.store(this.TAB_LIST_TOKEN, tabs);
  }


  updateCurrentTabParams(queryParams: Params): string {

    const tabs = this.tabs.getValue();
    const tab = tabs[this._currentTab];
    const tree = this.router.parseUrl(tab.path);
    const oldParams = tree.queryParams;

    if (!isEqual(queryParams, oldParams)) {
      tree.queryParams = queryParams;
      tab.path = this.router.serializeUrl(tree);
      this.sessionStorage.store(this.TAB_LIST_TOKEN, tabs);
      return tab.path;
    }
    return null;
  }


  removeTab(index: number) {
    let dontNavigate = this.NO_PAGE_RELOAD;
    const tabs = [...this.tabs.getValue()];
    if (tabs.length === 1) {
      return;
    }

    let newActiveTab = 0;

    if (index !== this._currentTab) {
      if (this._currentTab > index) {
        newActiveTab = this._currentTab - 1;
      } else if (this._currentTab < index) {
        newActiveTab = this._currentTab;
      }
    } else {
      dontNavigate = 0;
      if (index !== 0) {
        newActiveTab = index - 1;
      } else {
        newActiveTab = this._currentTab;
      }
    }

    tabs.splice(index, 1);

    this.tabs.next(tabs);
    this.setActiveTab(newActiveTab + dontNavigate);

    this.sessionStorage.store(this.TAB_LIST_TOKEN, tabs);
  }
}
