import { UntypedFormControl } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { isNil, memoize } from 'lodash';
import { CharsetService } from '@bp2s/core/services/charset.service';

/* IE11 does not support unicode regexes which are more reliable */
export const browserIsIE11 = !isNil(window['msCrypto']);

export function regexpGenerator(chars: string[]): RegExp {

  const codes = new Set<number>();
  for (const char of chars) {
    const code  = char.charCodeAt(0);
    if (!isNaN(code)) {
      codes.add(code);
    }
  }
  const sortedCodes = Array.from(codes);
  sortedCodes.sort((a,b) => a-b);
  let prev = 0;
  let start = 0;
  const ranges = [];
  for (const code of sortedCodes) {
    if (!start) {
      start = code;
    } else if ((prev + 1) !== code) {
      ranges.push([start, prev]);
      start = code;
    }
    prev = code;
  }
  if (start) {
    ranges.push([start, prev]);
  }
  const pattern = getRegExpPatternByRanges(ranges);

  if (browserIsIE11) {
    return new RegExp('[^' + pattern +']');
  } else {
    return new RegExp('[^' + pattern +']', 'u');
  }
}

export function getRegExpPatternByRanges(ranges: any): string {
  let pattern = '';
  for (const range of ranges) {
    if (range[0] === range[1]) {
      if (browserIsIE11) {
        pattern += String.fromCharCode(range[0]);
      } else {
        pattern += '\\' + 'u{' + range[0].toString(16) + '}';
      }
    } else {
      if (browserIsIE11) {
        pattern += String.fromCharCode(range[0]) + '-' + String.fromCharCode(range[1]);
      } else {
        pattern += '\\' + 'u{' + range[0].toString(16) + '}-' + '\\' + 'u{' + range[1].toString(16) + '}';
      }
    }
  }
  return pattern;
}

export const cachedRegexpGen = memoize(regexpGenerator);
export function charsetValidator(control: UntypedFormControl, field: FormlyFieldConfig = {}, options: any = {}): any {
    if (control.value) {
      let charset = null;
      if (options.allowedChars) {
        charset = options.allowedChars;
      } else if (field.type  === 'textarea') {
        charset = [...CharsetService.getAllowedCharacters(), '\n', '\r'];
      } else {
        charset = CharsetService.getAllowedCharacters();
      }
      const regexp = cachedRegexpGen(charset);
      const str = control.value.toString()
      const results = regexp.exec(str);
      if (results) {
        return {
          invalidCharacter: {
            index: results.index+1,
            char: String.fromCodePoint(results.input.codePointAt(results.index))
          }
        };
      }
    }
    return null;
}
