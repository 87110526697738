import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { isEmpty } from 'lodash';

// tslint:disable

@Injectable({ providedIn: 'root' })
export class BrowserDetectService {
  window: Window;
  constructor(@Inject(DOCUMENT) private document: Document) {
    this.window = this.document.defaultView;
  }
  public isBrowserSupport(ua: string): boolean {
    if (!!this.window['chrome'] && !!this.window['chrome']['webstore']) {
      const regexps = [ /Chrome\/(\d+\.)/, /Crios\/(\d+\.)/, /Crmo\/(\d+\.)/ ];
      const match = regexps.map(re => re.exec(ua)).find(array => !isEmpty(array));
      return !isEmpty(match) && (+match[1] >= 55); // Chrome 55+
    } else if (ua.indexOf('MSIE') !== -1) {
      return false;
    } else if (ua.indexOf('Trident') !== -1) {
      return false;
    } else if (ua.indexOf('Edge') !== -1) {
      return true; // Edge
    } else if (ua.indexOf('Firefox') !== -1) {
      const regexps = [
        /(?!Gecko.+)Firefox[ /]\d{1,16}/,
        /Gecko(?!.+Firefox)[ /]\d{1,16}/,
      ];
      const matched = regexps.map(re => re.exec(ua)).find(array => !isEmpty(array));
      if (!isEmpty(matched)) {
        const [version] = /\d+$/.exec(matched[0]); // NOSONAR - Disabled sonar analysis to ignore false positive security hotspot
        return (+version >= 27);
      }
      return false;
    } else if (ua.indexOf('Safari') !== -1) {
      return this.safariBrowserSupport(ua);
    }
    return false;
  }
  private safariBrowserSupport(ua: string): boolean {
    const regexps = [
      /iPod.+Version\/(\d{1,16}\.\d{1,16})/,
      /iPhone.+Version\/(\d{1,16}\.\d{1,16})/,
      /iPad.+Version\/(\d{1,16}\.\d{1,16})/,
    ];
    let match = regexps.map(re => re.exec(ua)).find(array => !isEmpty(array));
    let ver = isEmpty(match) ? NaN : +match[1];
    if (!isNaN(ver)) {
      return ver >= 7.1;
    }
    match = /Version\/(\d+)\./.exec(ua); // OS X Safari 9+
    ver = isEmpty(match) ? 0: +match[1];
    if (!isNaN(ver)) {
      return ver >= 9;
    }
    return false;
  }
}
