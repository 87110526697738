import { Component, OnInit, ViewChild } from '@angular/core';
import { FieldType } from '@ngx-formly/material';
import { FieldTypeConfig } from '@ngx-formly/core';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { Observable, of, isObservable } from 'rxjs';
import {
  startWith,
  switchMap,
  map,
  skipWhile,
  debounceTime,
  tap,
} from 'rxjs/operators';
import { isArray } from 'lodash';
import { MatInput } from '@angular/material/input';

@Component({
  selector: 'bp2s-formly-autocomplete',
  templateUrl: './formly-autocomplete.component.html',
  styleUrls: ['./formly-autocomplete.component.scss'],
})
export class FormlyAutocompleteComponent extends FieldType<FieldTypeConfig>
  implements OnInit {
  @ViewChild(MatInput, { static: true }) formFieldControl: MatInput;

  @ViewChild(MatAutocompleteTrigger, { static: true })
  autocomplete: MatAutocompleteTrigger;
  filter: Observable<any>;
  isLoading = false;

  ngOnInit() {
    this.filter = this.formControl.valueChanges.pipe(
      startWith(this.props.initFilter?this.value:''),
      debounceTime(300),
      tap({ next: (x) => (this.isLoading = true) }),
      switchMap((term) =>
        this.props.filter ? this.props.filter(term) : this.filterOptions(term)
      ),
      tap({ next: (x) => (this.isLoading = false) })
    );
  }

  filterOptions(term: string) {
    if (!this.props.options) {
      return of([]);
    }

    let options = this.props.options as Observable<
      [{ label: string; value: string }]
    >;

    if (!isObservable(options)) {
      options = of(options);
    }

    return options.pipe(
      skipWhile((objects) => {
        return !isArray(objects);
      }),
      map((x) => {
        return x.filter((option) =>
          this.getDisplayWith(option)?.toLowerCase().includes(term)
        );
      })
    );
  }

  getDisplayWith(item: any) {
    if (this.props.displayWith) {
      return this.props.displayWith(item);
    }
    return item.label;
  }

}
