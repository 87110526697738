<div class="row">
  <button mat-button class="close-icon close" mat-dialog-close>
    <mat-icon>close</mat-icon>
  </button>
</div>

<div class="errorContent row">
  <div class="icon">
    <mat-icon color="warn">error</mat-icon>
  </div>
  <section class="content">
    <h2>Errors Found</h2>

    <ul class="err-cat__list">
      <li
        *ngFor="let cat of errorCategories; let i = index"
        class="err-cat__item"
      >
        Error Category: {{ cat }}
        <ul class="err-item__list">
          <ng-container *ngFor="let err of listOfErrors.get(cat)">
            <li
              *ngIf="err.message"
              class="err-item__item"
              [textContent]="err.message"
            ></li>
          </ng-container>
        </ul>
      </li>
    </ul>
  </section>
</div>
