import { Injectable, Injector, InjectionToken } from '@angular/core';
import {
  ActivatedRoute,
  ActivatedRouteSnapshot,
  Router,
  ActivationEnd
} from '@angular/router';
import { Observable, BehaviorSubject, of, concat } from 'rxjs';
import {
  filter,
  flatMap,
  distinct,
  toArray,
  first,
  take,
} from 'rxjs/operators';
import { IBreadcrumb } from './breadcrumb.model';
import { BreadcrumbResolve } from './breadcrumb.resolver';
import { wrapIntoObservable } from './breadcrumb.util';
import { BreadcrumbsConfig } from './breadcrumb.config';

@Injectable({
  providedIn: 'root'
})
export class BreadcrumbService {
  private _breadcrumbs = new BehaviorSubject<IBreadcrumb[]>([]);
  private _defaultResolver = new BreadcrumbResolve();

  currentRoot;
  constructor(
    private _router: Router,
    private _config: BreadcrumbsConfig,
    private route: ActivatedRoute,
    private _injector: Injector
  ) {
    if (this._router.navigated) {
      this.currentRoot = this.route.snapshot.root;

      this._resolveCrumbs(this.currentRoot)
        .pipe(
          flatMap(x => x),
          distinct(x => x.text),
          toArray(),
          flatMap(x => {
            if (this._config.postProcess) {
              const y = this._config.postProcess(x);
              return wrapIntoObservable<IBreadcrumb[]>(y).pipe(first());
            } else {
              return of(x);
            }
          }),
          take(1)
        )
        .subscribe({
          next: x => {
            this._breadcrumbs.next(x);
          }
        }); /* No error handling required here. */
    }

    this._router.events
      .pipe(filter(x => x instanceof ActivationEnd))
      .subscribe({
        next: (event: ActivationEnd) => {
          this.currentRoot = _router.routerState.snapshot.root;
          if (!event.snapshot.queryParams?.keepUrl) {
            this._resolveCrumbs(this.currentRoot)
              .pipe(
                flatMap(x => x),
                distinct(x => x.text),
                toArray(),
                flatMap(x => {
                  if (this._config.postProcess) {
                    const y = this._config.postProcess(x);
                    return wrapIntoObservable<IBreadcrumb[]>(y).pipe(first());
                  } else {
                    return of(x);
                  }
                })
              )
              .subscribe({
                next: x => {
                  this._breadcrumbs.next(x);
                }
              }); /* No error handling required here. */
          }
        }
      }); /* No error handling required for router Observables. */
  }

  get crumbs$(): BehaviorSubject<IBreadcrumb[]> {
    return this._breadcrumbs;
  }

  private _resolveCrumbs(
    route: ActivatedRouteSnapshot
  ): Observable<IBreadcrumb[]> {
    let crumbs$: Observable<IBreadcrumb[]>;

    const data = route.routeConfig && route.routeConfig.data;

    if (data && data.breadcrumbs) {
      let resolver: BreadcrumbResolve;

      if (data.breadcrumbs.prototype instanceof BreadcrumbResolve) {
        resolver = this._injector.get(new InjectionToken<BreadcrumbResolve>(data.breadcrumbs));
      } else {
        resolver = this._defaultResolver;
      }

      const result = resolver.resolve(route, this._router.routerState.snapshot);

      crumbs$ = wrapIntoObservable<IBreadcrumb[]>(result).pipe(first());
    } else {
      crumbs$ = of([]);
    }

    if (route.firstChild) {
      crumbs$ = concat(crumbs$, this._resolveCrumbs(route.firstChild));
    }

    return crumbs$;
  }
}
