import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ThrFiltersSelectComponent } from './thr-filters-select/thr-filters-select.component';

import { ThrFiltersInputComponent } from './thr-filters-input/thr-filters-input.component';
import { ThrService } from './thr-service.service';
import { ReactiveFormsModule } from '@angular/forms';
import { ThrFiltersClearComponent } from './thr-filters-clear/thr-filters-clear.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';

@NgModule({
  declarations: [
    ThrFiltersSelectComponent,
    ThrFiltersInputComponent,
    ThrFiltersClearComponent
  ],
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatSelectModule,
    MatButtonModule,
    MatIconModule,
    MatInputModule,
    ReactiveFormsModule
  ],
  exports: [
    ThrFiltersSelectComponent,
    ThrFiltersInputComponent,
    ThrFiltersClearComponent
  ],
  providers: [ThrService]
})
export class TableHeaderRowFiltersModule {}
