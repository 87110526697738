<div
  cdkDropList
  class="dynamic-tabs-container"
  cdkDropListOrientation="horizontal"
  (cdkDropListDropped)="drop($event)"
>
<ng-container
*ngFor="
let tab of dynamicTabService.tabs | async;
let index = index;
let first = first
"
>
<hr>
  <section
    (click)="setActiveTab($event, tab.path, index)"
    (middleclick)="closeTab($event, index)"
    cdkDrag
    cdkDragBoundary=".dynamic-tabs-container"
    [class.active]="index === (dynamicTabService.currentTab | async)%dynamicTabService.NO_PAGE_RELOAD"
    class="dynamic-tabs-tab tab"
    [title]="tab?.subBreadCrumb?.text || ' '"
  >
    <button
      (click)="closeTab($event, index)"
      class="dynamic-tabs-close-btn"
      *ngIf="(dynamicTabService.tabs | async)?.length > 1"
      mat-icon-button
    >
      <mat-icon>close</mat-icon>
    </button>
    <header [ngClass]="((dynamicTabService.tabs | async)?.length === 1)?'singleTab':''">
      <h1>{{ tab?.breadCrumb?.text || 'Loading...' }}</h1>
    </header>
    <h6>{{ tab?.subBreadCrumb?.text || ' ' }}</h6>
    <mat-progress-bar
      mode="indeterminate"
      *ngIf="
    index === ((dynamicTabService.currentTab | async)%dynamicTabService.NO_PAGE_RELOAD) &&
    (dynamicTabService.progress | async) === true
  "
    ></mat-progress-bar>
  </section>
</ng-container>
</div>

<section class="add">
  <button
    *ngIf="!noMenu; else noMenuElse"
    [matMenuTriggerFor]="menu"
    mat-flat-button
  >
    <mat-icon>add</mat-icon>
    New Tab
  </button>
  <ng-template #noMenuElse>
    <button (click)="createNewTab(noMenu)" mat-flat-button>
      <mat-icon>add</mat-icon>
      New Tab
    </button>
  </ng-template>
</section>

<mat-menu #menu="matMenu">
  <ng-container *ngFor="let item of dynamicTabService.menuItems | async">
    <button
      *ngIf="item.enabled"
      (click)="createNewTab(item.path)"
      id="dy-{{ item.name }}-tab"
      mat-menu-item
    >
      {{ item.name }}
    </button>
  </ng-container>
</mat-menu>
