import { GLOBAL_ERROR } from '@bp2s/core/consts/global-error.consts';
export const ERROR_PAGE_MESSAGES = {
  [GLOBAL_ERROR.MISSING_PERMISSIONS]: { message: `You don't have the permissions needed to view this part of The Investment Office.<br><br>If you think this is a mistake please contact tech support.` },
  [GLOBAL_ERROR.NOT_FOUND]: { message: `We cant find this page, please check the URL and try again.` },
  [GLOBAL_ERROR.AUTH_FAILURE]: {
    link: ['/', 'auth', 'login'],
    message: `You don't have authorisation to view The Investment Office.`
  },
};

