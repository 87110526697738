import { Injectable } from '@angular/core';
import { PMO_LOOKUP_CATEGORIES, PMO_MAX_RESULTS, PMO_TOO_MANY_RESULTS_MSG } from '@bp2s/portfolio-modifiers/consts/pmo-lookup-constants';
import { Observable, of, combineLatest, forkJoin } from 'rxjs';
import { map, tap, catchError, take } from 'rxjs/operators';
import { forOwn, uniqBy } from 'lodash';
import { NGXLogger } from 'ngx-logger';
import { ErrorHandleService } from '@bp2s/core/errors/error-handle.service';
import { BnpLookupResponse } from '@bp2s/model/bnpLookupResponse';
import { BnpLookupControllerService } from '@bp2s/api/bnpLookupController.service';
import { DaisyChainControllerService } from '@bp2s/api/daisyChainController.service';
import { DCMasterRefModel } from '@bp2s/model/dCMasterRefModel';
import { ListOfAccountsControllerService } from '@bp2s/api/listOfAccountsController.service';
import { LookupServiceUtil } from '@bp2s/app/shared/utils/lookup-service.util';

const SEARCH_API_FAIL = 'Sorry there was a problem searching the accounts - please try again momentarily';

const COMMON_ACCOUNT_SEARCH_TERMS = [
  {fieldName: 'clientAccountStatus', value: 'Active'},
  {fieldName: 'accountType', value: 'Account'},
  {fieldName: 'accountSubtype', value: 'Core'},
];
/*****************************************************************************/
/* Download dropdown options from the backend:                               */
/*   - cache them in memory                                                  */
/*   - let the metamodel reference them easily                               */
/*   - let them be used in pipes for displaying labels instead of raw values */
/*****************************************************************************/

@Injectable({
  providedIn: 'root',
})
export class PmoLookupService {
  /* fetch data from the API only once */
  initialized = false;
  /* dropdown options for each category */
  optionTables = {};
  /* map values to labels for each category*/
  directMappings = {};
  /* map labels to values for each category */
  reverseMappings = {};

  lookup$: Observable<BnpLookupResponse>;
  dcList$: Observable<any[]>;
  refList$: Observable<DCMasterRefModel>;

  constructor(
    private bnpLookupControllerService: BnpLookupControllerService,
    private daisyChainControllerService: DaisyChainControllerService,
    private errorHandleService: ErrorHandleService,
    private accountListController: ListOfAccountsControllerService,
    private logger: NGXLogger,
  ) {
    this.lookup$ = this.bnpLookupControllerService.bnpLookupGetLookupByCategoriesUsingGET(
      PMO_LOOKUP_CATEGORIES
    );
    this.refList$ = this.daisyChainControllerService
      .daisyChainGetDaisyChainMasterRefDataUsingGET()
      .pipe(map((element) => element));
  }

  /* get a dropdown list, filtered or not, with or without an empty item */
  getOptions(
    category: string,
    addEmptyEntry: boolean,
    onlyThisValue: string = null
  ): Observable<{ label: string; value: any }[]> {
    return LookupServiceUtil.getOptions(this.optionTables[category], addEmptyEntry, onlyThisValue);
  }

  mapSingleValue(category, value) {
    return LookupServiceUtil.mapSingleValue(this.directMappings[category], value);
  }

  mapValue(category: string, value: any): any {
    return LookupServiceUtil.mapValue(this.directMappings[category], value);
  }

  initOptions(
    tableName: string,
    list: any[],
    labelName: string,
    valueName: string
  ) {
    const { optionTable, directMapping, reverseMapping } = LookupServiceUtil.initOptions(list, labelName, valueName);
    this.optionTables[tableName] = optionTable;
    this.directMappings[tableName] = directMapping;
    this.reverseMappings[tableName] = reverseMapping;
  }

  generateParser(category: string): (x: any) => any {
    const reverseMapping = this.reverseMappings[category];
    if (reverseMapping) {
      return (x) => {
        const ret = reverseMapping[x];
        if (ret === 'none') {
          return null;
        }
        return ret;
      };
    } else {
      this.logger.error(`no reverse mapping for category ${category}`);
      return (x) => x;
    }
  }

  /* Fetch all lookup tables and set up dropdown option lists and direct/reverse mappings */
  fetchLookupData(): Observable<boolean> {
    this.logger.info('Initializing PMO Lookup');
    if (this.initialized) {
      return of(true);
    }
    return combineLatest([
      this.lookup$,
      this.refList$,
    ]).pipe(
      tap({ 
        next: ([response, refList]) => {
          this.optionTables = {};
          this.directMappings = {};
          this.reverseMappings = {};
          forOwn(response.lookupDetails, (val, key) => {
            this.initOptions(key, val, 'codeValue', 'code');
          });
          refList.daisyChainMasters.unshift({daisyChainId: 'none', daisyChainName: 'No Daisy Chain'});
          this.initOptions('BOR_IDS', refList.borMasters, 'borName', 'borId');
          this.initOptions(
            'DAISY_CHAIN_IDS',
            refList.daisyChainMasters,
            'daisyChainName',
            'daisyChainId'
          );
          this.initOptions(
            'PURPOSE_IDS',
            response?.lookupDetails?.EA_PURPOSE,
            'codeValue',
            'code'
          );
          this.initialized = true;
        }
      }),
      map((r) => this.initialized),
      take(1),
      catchError((err) => {
        this.errorHandleService.handleWithNotification(
          err,
          'Sorry there was a problem retrieving Business Rules metadata'
        );
        return of(false);
      })
    );
  }

  searchAccounts(term: string, exactmatch = false): Observable<any[]> {
    if (!term || term.length <3) {
      return of([]);
    }
    const lcPattern = term.toLowerCase();
    return forkJoin(
      ['accountName', 'sourceAccountId'].map(fieldName =>
        this.accountListController.listOfAccountsSearchListOfAccountsUsingPOST({
          paged: true,
          terms: [...COMMON_ACCOUNT_SEARCH_TERMS, {fieldName, value: term}],
          pageRequest: { offset: 0, pageNumber: 0, pageSize: exactmatch?0:PMO_MAX_RESULTS },
        })))
    .pipe(
      map(([names, ids]) => uniqBy([...names.content, ...ids.content], 'sourceAccountId')),
      map(list => {
        if (exactmatch) {
          return list.filter(acc => (acc.accountName.toLowerCase() === lcPattern) || (acc.sourceAccountId.toLowerCase() === lcPattern));
        }
        return list;
      }),
      map((list: any[]) => list.map((acc) => ({ value: acc.sourceAccountId, label: `${acc.sourceAccountId} - ${acc.accountName}` }) )),
      map((list: any[]) => {
        list.sort((a,b) => a.label.localeCompare(b.label));
        return list;
      }),
      map((list: any[]) => {
        if (list && list.length>=PMO_MAX_RESULTS) {
          return [{ value: null, label: PMO_TOO_MANY_RESULTS_MSG }];
        }
        return list;
      }),
      catchError((err) => {
        try {
          this.errorHandleService.handleWithNotification(err, SEARCH_API_FAIL);
        } catch(err2) {
          this.logger.error(err, err2);
        }
        return of([]);
      })
    );
  }
}
