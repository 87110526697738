import { Directive, Output, HostListener, EventEmitter } from '@angular/core';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[middleclick]'
})
export class MiddleClickDirective {
  // eslint-disable-next-line @angular-eslint/no-output-rename
  @Output('middleclick') middleclick = new EventEmitter();

  @HostListener('mouseup', ['$event'])
  middleclickEvent(event) {
    if (event.which === 2) {
      this.middleclick.emit(event);
    }
  }
}
