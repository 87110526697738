<label [attr.for]="inputId" class="bp2s-button-toggle-label" #label>
  <div class="bp2s-button-toggle-container">
    <div class="bp2s-button-toggle-label-content">
      <!-- Add an invisible span so JAWS can read the label -->
      <span style="display:none">&nbsp;</span>
      <ng-content></ng-content>
    </div>
  </div>

  <input
    #input
    class="bp2s-button-toggle-input cdk-visually-hidden"
    type="radio"
    [id]="inputId"
    [checked]="checked"
    [disabled]="disabled"
    [tabIndex]="tabIndex"
    [attr.name]="name"
    [required]="required"
    [attr.aria-label]="ariaLabel"
    [attr.aria-labelledby]="ariaLabelledby"
    [attr.aria-describedby]="ariaDescribedby"
    (change)="_onInputChange($event)"
    (click)="_onInputClick($event)"
  />
</label>
