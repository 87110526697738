import {
  trigger,
  animateChild,
  transition,
  animate,
  style,
  query,
  state,
  stagger
} from '@angular/animations';

export const leftNavExpansionAnimation = {
  expansion: trigger('expansion', [
    state(
      'true',
      style({
        width: '270px'
      })
    ),
    state(
      'false',
      style({
        width: '64px'
      })
    ),
    transition('open => closed', [animate('1s')]),
    transition('closed => open', [
      animate('0.5s'),
      query('.left-nav-flow__sections', [
        animateChild(),
        style({ opacity: 0 }),
        stagger(50, [animate('300ms ease-out', style({ opacity: 1 }))])
      ])
    ])
  ])
};
