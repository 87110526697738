import { Component, Inject, HostListener } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CommonDialogData } from '@bp2s/core/common-dialog/common-dialog-data';

@Component({
  selector: 'bp2s-common-dialog',
  templateUrl: './common-dialog.component.html',
  styleUrls: ['./common-dialog.component.scss'],
})
export class CommonDialogComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: CommonDialogData,
    private mdDialogRef: MatDialogRef<CommonDialogComponent>
  ) {}

  cancel() {
    this.close(false);
  }

  close(value) {
    this.mdDialogRef.close(value);
  }

  confirm() {
    this.close(true);
  }

  @HostListener('keydown.esc')
  onEsc() {
    this.close(false);
  }

}
