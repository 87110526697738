<input
  matInput
  [matAutocomplete]="auto"
  [formControl]="formControl"
  [formlyAttributes]="field"
  [placeholder]="props.placeholder"
  [id]="field.id"
  [errorStateMatcher]="errorStateMatcher"
/>
<mat-autocomplete [displayWith]="props.displayWith" (optionSelected)="props.optionSelected?props.optionSelected($event.option.value):undefined" #auto="matAutocomplete">
  <mat-option
    *ngFor="let item of filter | async"
    [value]="props.displayWith ? item : item.value"
  >
    {{ props.displayWith ? getDisplayWith(item) : item.label }}
  </mat-option>
</mat-autocomplete>
<mat-spinner *ngIf="isLoading" [strokeWidth]="2" [diameter]="25"></mat-spinner>
