import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { PmoLookupService } from '@bp2s/portfolio-modifiers/services/pmo-lookup.service';
import {  Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PmoLookupResolver  {
  constructor(private pmoLookupService: PmoLookupService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.pmoLookupService.fetchLookupData();
  }
}
