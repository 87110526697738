/* eslint-disable @typescript-eslint/no-non-null-assertion */
import {
  Overlay,
  OverlayRef,
  ScrollStrategy,
  OverlayConfig,
  FlexibleConnectedPositionStrategy,
  HorizontalConnectionPos,
  VerticalConnectionPos
} from '@angular/cdk/overlay';
import { normalizePassiveListenerOptions } from '@angular/cdk/platform';
import { TemplatePortal } from '@angular/cdk/portal';
import {
  Directive,
  ElementRef,
  EventEmitter,
  HostBinding,
  Inject,
  InjectionToken,
  Input,
  Output,
  ViewContainerRef,
  AfterContentInit,
  OnDestroy,
  HostListener
} from '@angular/core';
import { Subscription, merge } from 'rxjs';
import { TopNavOverlayComponent } from './top-nav-overlay.component';
import { MenuPositionX, MenuPositionY } from '@angular/material/menu';
import { isFakeMousedownFromScreenReader } from '@angular/cdk/a11y';

export const TOP_NAV_OVERLAY_SCROLL_STRATEGY = new InjectionToken<
  () => ScrollStrategy
>('top-nav-overlay-scroll-strategy');

export function TOP_NAV_OVERLAY_SCROLL_STRATEGY_FACTORY(
  overlay: Overlay
): () => ScrollStrategy {
  return () => overlay.scrollStrategies.reposition();
}

export const TOP_NAV_OVERLAY_SCROLL_STRATEGY_FACTORY_PROVIDER = {
  provide: TOP_NAV_OVERLAY_SCROLL_STRATEGY,
  deps: [Overlay],
  useFactory: TOP_NAV_OVERLAY_SCROLL_STRATEGY_FACTORY
};

const passiveEventListenerOptions = normalizePassiveListenerOptions({
  passive: true
});

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[topNavOverlayTriggerFor]'
})
export class TopNavOverlayTriggerDirective
  implements AfterContentInit, OnDestroy {
  @HostBinding('class.top-nav-overlay-trigger')
  get overlayTriggerClass() {
    return true;
  }

  @HostBinding('attr.aria-haspopup')
  get ariaHasPopup() {
    return true;
  }

  @HostBinding('attr.aria-expanded')
  get ariaIsExpanded() {
    return this._open;
  }

  @HostBinding('attr.aria-controls')
  get ariaControls() {
    return this.topNavOverlay ? this.topNavOverlay.overlayId : null;
  }

  _openedBy: 'mouse' | 'touch' | null = null;

  @Output() readonly opened: EventEmitter<void> = new EventEmitter<void>();
  @Output() readonly closed: EventEmitter<void> = new EventEmitter<void>();

  private _topNavOverlay: TopNavOverlayComponent;
  private _portal: TemplatePortal;
  private _overlayRef: OverlayRef;
  private _open = false;
  private _closingActionsSubscription = Subscription.EMPTY;
  private _overlayCloseSubscription = Subscription.EMPTY;
  private _scrollStrategy: () => ScrollStrategy;
  private _handleTouchStart = () => (this._openedBy = 'touch');

  @Input('topNavOverlayTriggerFor')
  get topNavOverlay() {
    return this._topNavOverlay;
  }
  set topNavOverlay(overlay: TopNavOverlayComponent) {
    if (overlay === this._topNavOverlay) {
      return;
    }

    this._topNavOverlay = overlay;
    this._overlayCloseSubscription.unsubscribe();

    if (overlay) {
      this._overlayCloseSubscription = overlay.closed
        .asObservable()
        .subscribe({
          next: () => {
            this._destroyOverlay();
          }
        }); /* No error handling required for material Observables. */
    }
  }

  get overlayOpen(): boolean {
    return this._open;
  }

  constructor(
    private _overlay: Overlay,
    private _element: ElementRef<HTMLElement>,
    private _viewContainerRef: ViewContainerRef,
    @Inject(TOP_NAV_OVERLAY_SCROLL_STRATEGY) scrollStrategy: any
  ) {
    _element.nativeElement.addEventListener(
      'touchstart',
      this._handleTouchStart,
      passiveEventListenerOptions
    );

    this._scrollStrategy = scrollStrategy;
  }

  ngAfterContentInit() {
    this.checkOverlayIsAttachedk();
  }

  ngOnDestroy() {
    if (this._overlayRef) {
      this._overlayRef.dispose();
      this._overlayRef = null;
    }

    this._element.nativeElement.removeEventListener(
      'touchstart',
      this._handleTouchStart,
      passiveEventListenerOptions
    );

    this._overlayCloseSubscription.unsubscribe();
    this._closingActionsSubscription.unsubscribe();
  }

  toggleOpen(): void {
    this._open ? this.closeOverlay() : this.openOverlay();
  }

  openOverlay(): void {
    if (this._open) {
      return;
    }

    this.checkOverlayIsAttachedk();

    const overlayRef = this._createOverlay();
    const overlayConfig = overlayRef.getConfig();

    this._setPosition(
      overlayConfig.positionStrategy as FlexibleConnectedPositionStrategy
    );
    overlayConfig.hasBackdrop = true;
    overlayRef.attach(this._getPortal());

    this._closingActionsSubscription = this._overlayClosingActions().subscribe({
      next: () => this.closeOverlay()
    }); /* No error handling required for material Observables. */
    this._initOverlay();
  }

  closeOverlay(): void {
    this.topNavOverlay.closed.emit();
  }

  private _initOverlay(): void {
    this._setOverlayOpen(true);
  }

  private _destroyOverlay() {
    if (!this._overlayRef || !this.overlayOpen) {
      return;
    }

    this._closingActionsSubscription.unsubscribe();
    this._overlayRef.detach();

    this._setOverlayOpen(false);
  }

  private _setOverlayOpen(isOpen: boolean): void {
    this._open = isOpen;
    this._open ? this.opened.emit() : this.closed.emit();
  }

  private checkOverlayIsAttachedk() {
    if (!this._topNavOverlay) {
      throw new Error(
        'topNavOverlayTriggerFor must be attached to a TopNavOverlayComponent'
      );
    }
  }

  private _createOverlay(): OverlayRef {
    if (!this._overlayRef) {
      const config = this._getOverlayConfig();
      this._subscribeToPositions(
        config.positionStrategy as FlexibleConnectedPositionStrategy
      );
      this._overlayRef = this._overlay.create(config);

      this._overlayRef.keydownEvents().subscribe(); /* No error handling required for keyboard events. */
    }

    return this._overlayRef;
  }

  private _getOverlayConfig(): OverlayConfig {
    return new OverlayConfig({
      positionStrategy: this._overlay
        .position()
        .flexibleConnectedTo(this._element)
        .withLockedPosition()
        .withTransformOriginOn('.bp2s-top-nav-overlay')
        .withDefaultOffsetY(-50),
      backdropClass: 'cdk-overlay-transparent-backdrop',
      scrollStrategy: this._scrollStrategy()
    });
  }

  private _subscribeToPositions(
    position: FlexibleConnectedPositionStrategy
  ): void {
    if (this.topNavOverlay.setPositionClasses) {
      position.positionChanges.subscribe({
        next: change => {
          const posX: MenuPositionX =
            change.connectionPair.overlayX === 'start' ? 'after' : 'before';
          const posY: MenuPositionY =
            change.connectionPair.overlayY === 'top' ? 'below' : 'above';
          this.topNavOverlay.setPositionClasses(posX, posY);
        }
      }); /* No error handling required for material Observables. */
    }
  }

  private _setPosition(positionStrategy: FlexibleConnectedPositionStrategy) {
    const [originX, originFallbackX]: HorizontalConnectionPos[] =
      this.topNavOverlay.xPosition === 'before'
        ? ['end', 'start']
        : ['start', 'end'];

    const [overlayY, overlayFallbackY]: VerticalConnectionPos[] =
      this.topNavOverlay.yPosition === 'above'
        ? ['bottom', 'top']
        : ['top', 'bottom'];

    const [overlayX, overlayFallbackX] = [originX, originFallbackX];
    const offsetY = 10;

    const originY = overlayY === 'top' ? 'bottom' : 'top';
    const originFallbackY = overlayFallbackY === 'top' ? 'bottom' : 'top';

    positionStrategy.withPositions([
      { originX, originY, overlayX, overlayY, offsetY },
      {
        originX: originFallbackX,
        originY,
        overlayX: overlayFallbackX,
        overlayY,
        offsetY
      },
      {
        originX,
        originY: originFallbackY,
        overlayX,
        overlayY: overlayFallbackY,
        offsetY: -offsetY
      },
      {
        originX: originFallbackX,
        originY: originFallbackY,
        overlayX: overlayFallbackX,
        overlayY: overlayFallbackY,
        offsetY: -offsetY
      }
    ]);
  }

  private _overlayClosingActions() {
    const backdrop = this._overlayRef.backdropClick();
    const detachments = this._overlayRef.detachments();

    return merge(backdrop, detachments);
  }

  @HostListener('mousedown', ['$event'])
  _handleMousedown(event: MouseEvent): void {
    if (!isFakeMousedownFromScreenReader(event)) {
      this._openedBy = event.button === 0 ? 'mouse' : null;
    }
  }

  @HostListener('click', ['$event'])
  _handleClick(event: MouseEvent): void {
    this.toggleOpen();
  }

  /** Gets the portal that should be attached to the overlay. */
  private _getPortal(): TemplatePortal {
    if (
      !this._portal ||
      this._portal.templateRef !== this.topNavOverlay.templateRef
    ) {
      this._portal = new TemplatePortal(
        this.topNavOverlay.templateRef,
        this._viewContainerRef
      );
    }

    return this._portal;
  }
}
