import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogData } from '@bp2s/approvals/components/apr-approvals-dialog/apr-approvals-dialog.component';

@Component({
  selector: 'bp2s-disclaimer',
  templateUrl: './disclaimer.component.html',
  styleUrls: ['./disclaimer.component.scss']
})
export class DisclaimerComponent {

  items: any[] = [];
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private dialogRef: MatDialogRef<DisclaimerComponent>,
  ) {
    this.items = data as unknown as any[];
  }

  onClose(evt: any) {
    this.dialogRef.close();
  }

}
