import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LeftNavItemComponent } from './left-nav-item.component';
import { MatIconModule } from '@angular/material/icon';
import { LeftNavFlowModule } from '../left-nav-flow/left-nav-flow.module';

@NgModule({
  declarations: [LeftNavItemComponent],
  imports: [CommonModule, MatIconModule, LeftNavFlowModule],
  exports: [LeftNavItemComponent]
})
export class LeftNavItemModule {}
