import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from '@bp2s/app/app.module';
import { environment } from '@bp2s/environments/environment';

import { hmrBootstrap } from '@bp2s/hmr';

if (environment.production) {
  enableProdMode();
}

const bootstrap = () => platformBrowserDynamic().bootstrapModule(AppModule);

if (environment.hmr) {
  if (module['hot']) {
    hmrBootstrap(module, bootstrap);
  } else if (!environment.production) {
    
    // eslint-disable-next-line no-console
    console.error('HMR is not enabled for webpack-dev-server!');
    // eslint-disable-next-line no-console
    console.log('Are you using the --hmr flag for ng serve?');
  }
} else if (environment.production) {
  bootstrap();
} else {
  bootstrap().catch(err => {
    // eslint-disable-next-line no-console
    console.error(err)
  });
}
