import { Component } from '@angular/core';
import { FieldWrapper } from '@ngx-formly/core';

@Component({
  selector: 'bp2s-formly-fieldgroup-header',
  templateUrl: './formly-fieldgroup-header.component.html',
  styleUrls: ['./formly-fieldgroup-header.component.scss']
})
export class FormlyFieldgroupHeaderComponent extends FieldWrapper {
}
