import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpResponse
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { NGXLogger } from 'ngx-logger';
import { SHARED_HEADERS } from '@bp2s/core/consts/ls-tokens.const';
import { DynamicTabService } from '@bp2s/components';

const EXCLUDE_PROGRESS_URLS = [
  'asyncjob/byuserid'
];

@Injectable({ providedIn: 'root' })
export class ProgressInterceptor implements HttpInterceptor {
  private requests: HttpRequest<any>[] = [];

  constructor(
    private logger: NGXLogger,
    private dynamicTabService: DynamicTabService
  ) {}

  remove(req: HttpRequest<any>) {
    const i = this.requests.indexOf(req);
    if (i >= 0) {
      this.requests.splice(i, 1);
    }

    this.dynamicTabService.setProgressActive(this.requests.length > 0);
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (req.headers.get(SHARED_HEADERS.DISABLED_PROGRESS)) {
      const headers = req.headers.delete(SHARED_HEADERS.DISABLED_PROGRESS);
      const reqClone = req.clone({
        headers
      });
      return next.handle(reqClone);
    }
    if(!EXCLUDE_PROGRESS_URLS.find(pattern => req.url.includes(pattern))) {
      this.requests.push(req);
      this.dynamicTabService.setProgressActive(true);
    }

    this.logger.debug(
      `Current Requests Count ${this.requests.length}`,
      this.requests
    );

    return new Observable(obs => {
      const sub = next.handle(req).subscribe({
        next: (res) => {
          if (res instanceof HttpResponse) {
            this.remove(req);
            obs.next(res);
          }
        },
        error: (err) => {
          this.remove(req);
          obs.error(err);
        },
        complete: () => {
          this.remove(req);
          obs.complete();
        }
      });
      return () => {
        this.remove(req);
        sub.unsubscribe();
      };
    });
  }
}

