import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { BrListService } from '@bp2s/business-rules/services/br-list.service';
import { of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class BrAccountResolve  {
  constructor(private brListService: BrListService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (route.params.id !== 'daisychain') {
      return this.brListService.getAccountById(route.params.id);
    }
    return of({});
  }
}
