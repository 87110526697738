import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ErrorSnackComponent } from '@bp2s/core/errors/error-snack/error-snack.component';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatIconModule } from '@angular/material/icon';



@NgModule({
    declarations: [ErrorSnackComponent],
    imports: [
        CommonModule,
        MatSnackBarModule,
        MatIconModule
    ]
})
export class ErrorSnackModule { }

