import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BaseLayoutComponent } from '@bp2s/layout/base-layout/base-layout.component';
import { MatSidenavModule } from '@angular/material/sidenav';
import {
  TopNavModule,
  LeftNavModule,
  LeftNavItemModule,
  DynamicTabModule,
  TopNavOverlayModule,
} from '@bp2s/components';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatBadgeModule } from '@angular/material/badge';
import { UserProfilePanelModule } from '@bp2s/layout/user-profile-panel/user-profile-panel.module';
import { CoreDirectivesModule } from '@bp2s/core/directives/directives.module';
import { JobsPanelModule } from '@bp2s/layout/jobs-panel/jobs-panel.module';

@NgModule({
  declarations: [BaseLayoutComponent],
  imports: [
    CommonModule,
    MatSidenavModule,
    TopNavModule,
    LeftNavModule,
    LeftNavItemModule,
    DynamicTabModule,
    TopNavOverlayModule,
    RouterModule,
    MatButtonModule,
    MatIconModule,
    UserProfilePanelModule,
    CoreDirectivesModule,
    JobsPanelModule,
    MatBadgeModule
  ],
  exports: [BaseLayoutComponent],
})
export class BaseLayoutModule {}
