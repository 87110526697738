import { Component, OnInit } from '@angular/core';
import { AuthService } from '@bp2s/core/auth/auth.service';

@Component({ selector: 'bp2s-auth-login', template: '' })
export class AuthLoginComponent implements OnInit {

    constructor(private authService: AuthService) { }
    ngOnInit() {
        this.authService.startAuthentication();
    }
}

