import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { GlobalPermissionsService } from '@bp2s/core/services/global-permissions.service';
import { RESPONSIBILITY } from '@bp2s/core/consts/role_responsibility.consts';
import { GLOBAL_ERROR } from '@bp2s/core/consts/global-error.consts';
import { AuthService } from '@bp2s/core/auth/auth.service';

@Injectable({
  providedIn: 'root',
})
export class PermissionsGuard  {
  PATH_PERMISSIONS_MAP = {
    accounts: RESPONSIBILITY.VIEW_AM,
    'fund-structures': RESPONSIBILITY.VIEW_PT,
    issuers: RESPONSIBILITY.VIEW_SM,
    measurements: RESPONSIBILITY.VIEW_MES,
    'portfolio-modifiers': RESPONSIBILITY.VIEW_AM,
    securities: RESPONSIBILITY.VIEW_SM,
    xref: RESPONSIBILITY.VIEW_XREF
  };

  constructor(
    private router: Router,
    private authService: AuthService,
    private permissionService: GlobalPermissionsService
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    const baseURL = state.root.firstChild.routeConfig.path;
    const permission = this.PATH_PERMISSIONS_MAP[baseURL];

    if (this.authService.isLoggedIn() && permission && !this.permissionService.checkPermission(permission)) {
      this.router.navigate(['/', 'error'], {
        queryParams: { error: GLOBAL_ERROR.MISSING_PERMISSIONS, keepUrl: true },
        replaceUrl: false,
        skipLocationChange: true,
      });

      return false;
    }

    return true;
  }
}
