import { Component } from '@angular/core';
import { GlobalJobsService, JOB_STATUS, GlobalJob } from '@bp2s/core/services/global-jobs.service';
import { Router, NavigationEnd } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil, filter, take, tap } from 'rxjs/operators';
import { isNil, noop } from 'lodash';
import { AuthService } from '@bp2s/core/auth/auth.service';
import { NGXLogger } from 'ngx-logger';

@Component({
  selector: 'bp2s-jobs-panel',
  templateUrl: './jobs-panel.component.html',
  styleUrls: ['./jobs-panel.component.scss']
})
export class JobsPanelComponent {

  STATUSES  = JOB_STATUS;
    
  kill$ = new Subject<boolean>();

  currentRouteURL = '';

  constructor(
    public jobsService: GlobalJobsService,
    private router: Router,
    private authService: AuthService,
    private logger: NGXLogger
  ) { 
    this.router.events
    .pipe(
      filter(event => event instanceof NavigationEnd),
      takeUntil(this.kill$)
    )
    .subscribe({
      next: (event: NavigationEnd) => {
        this.currentRouteURL = event.url || '';
      }
    }); /* No error handling required for router Observables. */

    this.authService.user$.pipe(
      filter(user => !isNil(user.token_type) && !isNil(user.access_token) && user.profile.authorities.length),
      take(1),
      tap({
        next: (user) => this.logger.info(`fetching Async Job Info with authorization: ${user.token_type} ${user.access_token}`)
      }),
    ).subscribe({
      next: (res) => {
        this.jobsService.pollForAsyncJobs();
      },
      error: (err) => noop()
    });
  }

  hasJobData(): boolean {
    return this.jobsService.hasJobData;
  }

  viewAsyncJob(job: GlobalJob) {
    if(job.status === this.STATUSES.COMPLETED) {
      this.jobsService.removeAsyncJob(job.id);
    }

    if(this.currentRouteURL === job.link[0]) {
      this.router
      .navigateByUrl('/', { skipLocationChange: true })
      .then(() => {
        this.router.navigate(job.link);
      });
    } else {
      this.router.navigate(job.link);
    }
  }

}
