<bp2s-button-toggle-group
  [id]="id"
  [formControl]="formControl"
  [formlyAttributes]="field"
>
  <bp2s-button-toggle
    *ngFor="
      let option of props.options | formlySelectOptions: field | async;
      let i = index
    "
    [id]="id + '-' + option.value"
    [value]="option.value"
  >
    <span [innerHTML]="option.label"></span>
  </bp2s-button-toggle>
</bp2s-button-toggle-group>
