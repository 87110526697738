import { DaisyChainItem } from '@bp2s/model/daisyChainItem';

export interface DataActions {
  container?: BrMenuActions;
  dcAction?: DaisyChainActions;
  brAreDisplayed?: boolean;
}

export enum BrMenuActions {
  VIEW = 'VIEW',
  EDIT = 'EDIT',
  CREATE = 'CREATE',
  CLOSED = 'CLOSED',
}
export enum DaisyChainActions {
  VIEW = 'VIEW',
  EDIT = 'EDIT',
  CLOSED = 'CLOSED',
}
export enum FormStatus {
  PRISTINE = 'PRISTINE',
  ERROR = 'ERROR',
  VALID = 'VALID',
}

export interface DaisyChainItemWithPendingChanges extends DaisyChainItem {
  fieldsChanged: { [key: string]: any };
}

export const DISCARD_DIALOG_DETAILS = {
  TITLE: 'DISCARD CHANGES',
  OPEN_DC_MESSAGE: `<p>Are you sure?</p><p>All the changes you've made will be lost and you won't be able to recover them again.</p>`,
  DISCARD_DC_MESSAGE:
    '<p>Please save the changes before navigating away otherwise you’ll lose all the changes you’ve made so far.</p>',
  CANCEL_TEXT: 'NO, TAKE ME BACK',
  OK_TEXT: 'YES, DISCARD',
};

export const DAISYCHAIN_VALIDATION_DETAILS = {
  TITLE: 'INVALID DAISY CHAIN ',
  EDIT_TITLE: 'DATE CHANGE WARNING',
  OPEN_DC_MESSAGE: `<p>The combination of Account / BOR / Daisy Chain must be unique for any date range.</p><p>Please review the BOR and date range.</p>`,
  EDIT_MESSAGE: `<p>Please consider the impact of changing Start Date or End Date will have on each Business Rule in this Daisy Chain.</p>`,
  CANCEL_TEXT: '',
  OK_TEXT: 'OK, GOT IT',
};

export const DAISYCHAIN_EA_OVERLAPPING_INFO = {
  TITLE: 'INFORMATION',
  EA_OVERLAP_MESSAGE:`<p>There is an Exposure Adjustment linked to this Account / BOR / effective period.</p><p>Please end date the stand alone Exposure Adjustment and create another Exposure Adjustment in combination with the Daisy Chain if required.</p>`,
  EA_DC_LINKED_MSG: '<p>There is an Exposure Adjustment linked to this Account / BOR / effective period.</p><p>Please end date the Exposure Adjustment(s) and create another Exposure Adjustment(s) if required.</p>',
  CANCEL_TEXT: '',
  OK_TEXT: 'OK, GOT IT',
};

export const BUSINESS_RULES_NEW_PERIOD = {
  TITLE: 'NEW PERIOD WARNING ',
  EDIT_MESSAGE: `<p>Please consider the impact of adding a new period. There should be no gap between the previous Start / End Date and the next Start / End Date for the same Business Rule. The Start date of the first period should match the Start Date of the Daisy Chain and the End Date of the last period should match the End Date of the Daisy Chain.</p>`,
  CANCEL_TEXT: '',
  OK_TEXT: 'OK, GOT IT',
};

export const INSTRUMENT_ID_EMPTY = {
  TITLE: 'ERROR ',
  MESSAGE: `<p>Instrument IDs not found for this Account</p>`,
  CANCEL_TEXT: '',
  OK_TEXT: 'OK, GOT IT',
};

export const BR_TABLE_FILTERS = {
  brStatus: [
    { label: 'Active', value: 'ACTIVE' },
    { label: 'Inactive', value: 'INACTIVE' },
  ],
};

export const BR_LOOKUP_CATEGORIES = [
  'AGGREGATION_TYPE',
  'SW_AGGREGATION_TYPE',
  'SWAP_TYPE',
  'INDEX',
  'LT_ENTITY_VALUE',
  'LT_MODEL'
];

export const BR_SAVE_FAILURE =
  'Sorry, there was a problem saving this business rule. Please try again';
