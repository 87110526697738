export const environment = {
  production: true,
  hmr: false,
  envName: 'dev',
  apiBasePath: '/api',
  version: require('../../../../package.json').version,
  sentry: true,
  auth: {
    mocked: false
    /* SSO variables are set by the Ansible script during deployment */
  },
  features: ['EA', 'BR', 'PRI', 'ISS', 'MES'],
};
