<ng-template>
    <div
      class="top-nav-overlay-panel"
      [id]="overlayId"
      [ngClass]="_classList"
      tabindex="-1"
      role="menu"
      [attr.aria-label]="ariaLabel || null"
      [attr.aria-labelledby]="ariaLabelledby || null"
      [attr.aria-describedby]="ariaDescribedby || null">
      <div class="top-nav-overlay-content">
        <ng-content></ng-content>
      </div>
    </div>
  </ng-template>