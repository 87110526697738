import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/material';
import { FieldTypeConfig } from '@ngx-formly/core';

@Component({
  selector: 'bp2s-formly-button-toggle',
  templateUrl: './formly-button-toggle.component.html',
  styleUrls: ['./formly-button-toggle.component.scss']
})
export class FormlyButtonToggleComponent extends FieldType<FieldTypeConfig> {
}
