import { environment } from '@bp2s/environments/environment';

export function getBaseUrl() {
  if (window['INSECURE_API']) {
    return window['INSECURE_API'];
  }

  return environment.apiBasePath;
}

