import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutesModule } from '@bp2s/app/app.routes';
import { CoreModule } from '@bp2s/core/core.module';
import { AppComponent } from '@bp2s/layout/base/app.component';
import { LayoutModule } from '@bp2s/layout/layout.module';
import { RouteReuseStrategy } from '@angular/router';
import { AppUncachedRouteReuseStrategy } from '@bp2s/app/app.uncached-route-reuse.strategy';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    CoreModule,
    LayoutModule,
    AppRoutesModule
  ],
  providers: [{
    provide: RouteReuseStrategy,
    useClass: AppUncachedRouteReuseStrategy
  }],
  bootstrap: [AppComponent]
})
export class AppModule {}

