import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { MetaModelMapping } from '@bp2s/model/metaModelMapping.js';
import { kebabCase } from 'lodash';

/************************************************************/
/*                   VERY IMPORTANT                         */
/************************************************************/
/* Please use node ./tools/scripts/metamodel.js --create    */
/* to create/add any metamodel definition.                  */
/************************************************************/

@Injectable({
  providedIn: 'root',
})
export class MetamodelMockControllerService {
  constructor(private http: HttpClient) {}

  getMetaModelUsingGET(
    key: string,
    feature: string
  ): Observable<Array<MetaModelMapping>> {
    const kebab = kebabCase(feature);
    let path = `assets/metamodel/formly/${kebab}.metamodel.json`;
    if (key !== 'edit') {
      path = `assets/metamodel/formly/${kebab}.${key}.metamodel.json`;
    }
    return this.http.get<any[]>(path);
  }
}
