import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpXsrfTokenExtractor,
} from '@angular/common/http';

@Injectable({ providedIn: 'root' })
export class XsrfInterceptor implements HttpInterceptor {
  constructor(
    private tokenExtracter: HttpXsrfTokenExtractor
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    const cookieHeaderName = "X-XSRF-TOKEN";
    const xsrfToken = this.tokenExtracter.getToken();

    if(xsrfToken !== null && !req.headers.has(cookieHeaderName)) {
      req = req.clone({
        headers: req.headers.set(
          cookieHeaderName, xsrfToken
        )
      });
    }

    return next.handle(req);
  }
}
