<bp2s-top-nav #topNav>
  <div class="header">
    <img src="assets/logo-default.png" alt="" />
  </div>
  <bp2s-dynamic-tab [noMenu]="'/accounts'"></bp2s-dynamic-tab>
  <div class="actions">
    <button [topNavOverlayTriggerFor]="user" mat-icon-button>
      <mat-icon>account_circle</mat-icon>
    </button>
    <button [topNavOverlayTriggerFor]="notifications" mat-icon-button>
      <mat-icon
        [matBadge]="globalJobsService.activeJobCount$ | async"
        [matBadgeHidden]="(globalJobsService.activeJobCount$ | async) === 0"
        matBadgePosition="above after"
        >subject</mat-icon
      >
    </button>
  </div>
</bp2s-top-nav>
<mat-sidenav-container [style.height]="topHeight">
  <mat-sidenav mode="side" opened>
    <bp2s-left-nav (expanded)="expandSideNav($event)">
      <ng-container
        *ngFor="let item of globalNavService.navItems | async; let idx = index"
      >
        <bp2s-left-nav-item
          *permissionHide="item.permission"
          [routerLink]="item.route"
          [queryParams]="item.queryParams"
          [routerLinkActive]="['selected']"
          [routerLinkActiveOptions]="{ exact: false }"
          (keyup)="selectNavItem($event, item)"
          [item]="item"
        ></bp2s-left-nav-item>
      </ng-container>
    </bp2s-left-nav>
  </mat-sidenav>
  <mat-sidenav-content>
    <router-outlet
      (activate)='onActivate($event)'
    ></router-outlet>

    <div class="bottom-outlet">
      <router-outlet name="bottombar"></router-outlet>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>

<bp2s-top-nav-overlay #user>
  <bp2s-user-profile-panel></bp2s-user-profile-panel>
</bp2s-top-nav-overlay>

<bp2s-top-nav-overlay #notifications>
  <!-- disabled until we implement async jobs notification -->
  <bp2s-jobs-panel></bp2s-jobs-panel>
</bp2s-top-nav-overlay>
