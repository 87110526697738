import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MiddleClickDirective } from './middle-click/middle-click.directive';



@NgModule({
  declarations: [MiddleClickDirective],
  imports: [
    CommonModule
  ],
  exports: [MiddleClickDirective]
})
export class DirectivesModule { }
