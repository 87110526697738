<div class="page">
  <header>
    <h1>Disclaimer</h1>
  </header>
  <section class="panel">
    <ng-container *ngFor="let element of items">
      <ng-container [ngSwitch]="element.type">
        <p *ngSwitchCase="'p'">{{element.content}}</p>
        <a *ngSwitchCase="'link'" [href]="element.content">{{element.content}}</a>
      </ng-container>
    </ng-container>
  </section>
</div>
<br />
<button
  mat-flat-button
  class="close-button"
  color="primary"
  (click)="onClose($event)"
>
  CLOSE
</button>
