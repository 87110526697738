// tslint:disable: no-duplicate-string

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatChipsModule } from '@angular/material/chips';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ButtonToggleFieldModule } from '@bp2s/components';
import { FormlyFieldConfig, FormlyModule } from '@ngx-formly/core';
import { FormlySelectModule } from '@ngx-formly/core/select';
import { FormlyMaterialModule } from '@ngx-formly/material';
import { FormlyMatFormFieldModule } from '@ngx-formly/material/form-field';
import { FormlyAutocompleteChipsComponent } from '@bp2s/shared/forms/formly-autocomplete-chips/formly-autocomplete-chips.component';
import { FormlyAutocompleteComponent } from '@bp2s/shared/forms/formly-autocomplete/formly-autocomplete.component';
import { FormlyButtonToggleComponent } from '@bp2s/shared/forms/formly-button-toggle/formly-button-toggle.component';
import { FormlyYearPickerComponent } from '@bp2s/shared/forms/formly-year-picker/formly-year-picker.component';
import { FormlyExpansionPanelComponent } from '@bp2s/shared/forms/formly-expansion-panel/formly-expansion-panel.component';
import { FormlyFieldgroupHeaderComponent } from '@bp2s/shared/forms/formly-fieldgroup-header/formly-fieldgroup-header.component';
import { FormlyFieldgroupComponent } from '@bp2s/shared/forms/formly-fieldgroup/formly-fieldgroup.component';
import { FormlyMatPrefixesComponent } from '@bp2s/shared/forms/formly-mat-prefixes/formly-mat-prefixes.component';
import { prefixesExtension } from '@bp2s/shared/forms/formly-mat-prefixes/formly-mat-prefixes.extension';
import { FormlyPanelWrapperComponent } from '@bp2s/shared/forms/formly-panel-wrapper/formly-panel-wrapper.component';
import { FormlyRepeatComponent } from '@bp2s/shared/forms/formly-repeat/formly-repeat.component';
import { autocompleteObjectValidator } from '@bp2s/shared/forms/validators/autocompleteObject.validator';
import {
  numberValidator,
  DEFAULT_PRECISION,
} from '@bp2s/shared/forms/validators/number.validator';
import { uniqueSourceAccountIdValidation } from '@bp2s/shared/forms/validators/uniqueSourceAccount.validator';
import { charsetValidator } from '@bp2s/shared/forms/validators/charset.validator';

import { FORMS_VALIDATION_MSGS } from '@bp2s/shared/forms/consts/forms-validations.consts';
import { FormlySelectChipsComponent } from '@bp2s/shared/forms/formly-select-chips/formly-select-chips.component';
import { MatSelectModule } from '@angular/material/select';
import { uniqueDirectParentAccountValidation } from '@bp2s/shared/forms/validators/uniqueDirectParentAccount.validator';
import { FormlyTooltipWrapperComponent } from '@bp2s/shared/forms/formly-tooltip-wrapper/formly-tooltip-wrapper.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { OverlayModule } from '@angular/cdk/overlay'
import { MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { FZW_DATE_FORMAT, FZW_UI_DATE_FORMAT } from '@bp2s/core/consts/date-format.consts';
export * from '@bp2s/shared/forms/formly-button-toggle/formly-button-toggle.component';
export * from '@bp2s/shared/forms/formly-year-picker/formly-year-picker.component';

@NgModule({
  declarations: [
    FormlyButtonToggleComponent,
    FormlyYearPickerComponent,
    FormlyFieldgroupComponent,
    FormlyFieldgroupHeaderComponent,
    FormlyExpansionPanelComponent,
    FormlyRepeatComponent,
    FormlyAutocompleteComponent,
    FormlyAutocompleteChipsComponent,
    FormlyPanelWrapperComponent,
    FormlyMatPrefixesComponent,
    FormlySelectChipsComponent,
    FormlyTooltipWrapperComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormlyModule,
    MatInputModule,
    MatIconModule,
    MatButtonModule,
    MatSelectModule,
    FormlyMaterialModule,
    ButtonToggleFieldModule,
    MatAutocompleteModule,
    FormlyMatFormFieldModule,
    FormlySelectModule,
    MatExpansionModule,
    MatChipsModule,
    MatProgressSpinnerModule,
    MatTooltipModule,
    MatDatepickerModule,
    MatMomentDateModule,
    OverlayModule,

    FormlyModule.forRoot({
      types: [
        { name: 'button-toggle', component: FormlyButtonToggleComponent },
        { name: 'repeat', component: FormlyRepeatComponent },
        {
          name: 'year-picker',
          component: FormlyYearPickerComponent,
          wrappers: ['form-field'],
          defaultOptions: {
            validation: {
              messages: {
                pattern: 'Enter a year (YYYY)'
              }
            },
          }
        },
        {
          name: 'autocomplete',
          component: FormlyAutocompleteComponent,
          wrappers: ['form-field'],
        },
        {
          name: 'autocomplete-chips',
          component: FormlyAutocompleteChipsComponent,
          wrappers: ['form-field'],
        },
        {
          name: 'select-chips',
          component: FormlySelectChipsComponent,
          wrappers: ['form-field'],
        },
      ],
      wrappers: [
        { name: 'group', component: FormlyFieldgroupComponent },
        { name: 'group-header', component: FormlyFieldgroupHeaderComponent },
        { name: 'expansion', component: FormlyExpansionPanelComponent },
        { name: 'prefixes', component: FormlyMatPrefixesComponent },
        { name: 'panel', component: FormlyPanelWrapperComponent },
        { name: 'tooltip', component: FormlyTooltipWrapperComponent },
      ],
      extensions: [
        { name: 'prefixes', extension: { onPopulate: prefixesExtension } },
      ],
      validators: [
        {
          name: 'uniqueSourceAccountId',
          validation: uniqueSourceAccountIdValidation,
        },
        {
          name: 'uniqueDirectParentAccount',
          validation: uniqueDirectParentAccountValidation,
        },
        {
          name: 'invalidAutocompleteObject',
          validation: autocompleteObjectValidator,
        },
        {
          name: 'number',
          validation: numberValidator,
        },
        {
          name: 'charset',
          validation: charsetValidator
        }
      ],
      validationMessages: [
        {
          name: 'required',
          message: FORMS_VALIDATION_MSGS.REQUIRED,
        },
        {
          name: 'minLength',
          message: FORMS_VALIDATION_MSGS.MIN_LENGTH,
        },
        {
          name: 'maxLength',
          message: FORMS_VALIDATION_MSGS.MAX_LENGTH,
        },
        {
          name: 'min',
          message: FORMS_VALIDATION_MSGS.MIN,
        },
        {
          name: 'max',
          message: FORMS_VALIDATION_MSGS.MAX,
        },
        {
          name: 'invalidAutocompleteObject',
          message: FORMS_VALIDATION_MSGS.INVALID_AUTOCOMPLETE,
        },
        {
          name: 'uniqueSourceAccountId',
          message: FORMS_VALIDATION_MSGS.UNIQUE_SOURCE_ACCOUNTID,
        },
        {
          name: 'uniqueDirectParentAccount',
          message: FORMS_VALIDATION_MSGS.INVALD_DIRECT_PARENT_ACC,
        },
        {
          name: 'number',
          message: `Type a number (max precision: ${DEFAULT_PRECISION} digits)`,
        },
        {
          name: 'matDatepickerParse',
          message: `date in ${FZW_UI_DATE_FORMAT} format`,
        },
        {
          name: 'matDatepickerMin',
          message: FORMS_VALIDATION_MSGS.DATEPICKER_MIN,
        },
        {
          name: 'matDatepickerMax',
          message: FORMS_VALIDATION_MSGS.DATEPICKER_MAX,
        },
        {
          name: 'invalidCharacter',
          message: (error, field: FormlyFieldConfig) => `Invalid character found (${error.char}) at pos ${error.index}`
        }
      ],
      extras : {
        checkExpressionOn: 'changeDetectionCheck',
        resetFieldOnHide: false
      }
    }),
  ],
  exports: [
    FormlyModule,
    ReactiveFormsModule,
    FormlyButtonToggleComponent,
    FormlyYearPickerComponent,
    FormlyFieldgroupComponent,
    FormlyFieldgroupHeaderComponent,
    FormlyRepeatComponent,
    FormlyAutocompleteComponent,
    FormlySelectChipsComponent,
    FormlyAutocompleteChipsComponent,
  ],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'en-AU' },
    { provide: MAT_DATE_FORMATS, useValue: FZW_DATE_FORMAT },
  ],
})
export class DynamicFormsModule {}
