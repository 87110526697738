import {
  Component,
  OnInit,
  Input,
  OnChanges,
  SimpleChanges,
  Inject,
} from '@angular/core';
import { AuthService } from '@bp2s/core/auth/auth.service';
import { UntypedFormControl } from '@angular/forms';
import { ROLES } from '@bp2s/core/consts/role_responsibility.consts';
import { TOKENS } from '@bp2s/core/auth/auth.consts';
import { User } from 'oidc-client';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'bp2s-diag-user-config',
  templateUrl: './diag-user-config.component.html',
  styleUrls: ['./diag-user-config.component.scss'],
})
export class DiagUserConfigComponent implements OnInit, OnChanges {
  /* ignore coverage */
  @Input() user: User;
  userSelector: UntypedFormControl = new UntypedFormControl();
  roleSelector: UntypedFormControl = new UntypedFormControl();
  ROLES = ROLES;
  PROFILES = TOKENS;

  constructor(
    /* ignore coverage */ private authService: AuthService,
    /* ignore coverage */ @Inject(DOCUMENT) private document: Document) {}

  ngOnInit(): void {
    this.updateUser();

    this.roleSelector.valueChanges.subscribe({
      next: (roles) => {
        if (!this.authService.user) {
          return;
        }
        this.authService.useMockResponsibilities(roles);
      }
    }); /* No error handling required here. */


    this.userSelector.valueChanges.subscribe({
      next: (user) => {
        this.authService.useMockUser(user);
      }
    }); /* No error handling required for form observables. */
  }

  ngOnChanges(_: SimpleChanges) {
    if (_.user.currentValue) {
      this.updateUser();
    }
  }

  updateUser() {
    if (!this.user?.profile) {
      return;
    }

    this.userSelector.patchValue(this.user);

    const roles = this.user.profile.authorities;

    if (roles.length) {
      this.roleSelector.patchValue(
        roles.map((role: string) => role.toUpperCase())
      );
    }
  }

  resetRoles(evt: MouseEvent) {
    evt.preventDefault();
    evt.stopImmediatePropagation();
    this.roleSelector.reset();
  }

  compareWith(tk1, tk2) {
    return tk1?.profile?.sub === tk2?.profile.sub;
  }

  reload() {
      this.document.defaultView.location.reload();
  }
}
