import {
  Directive,
  TemplateRef,
  ViewContainerRef,
  Input,
  OnInit,
  OnDestroy,
} from '@angular/core';
import { GlobalPermissionsService } from '@bp2s/core/services/global-permissions.service';
import { RESPONSIBILITY } from '@bp2s/core/consts/role_responsibility.consts';
import { Subject } from 'rxjs';
import { takeUntil, delay } from 'rxjs/operators';

@Directive({
  selector: '[permissionHide]',
})
export class PermissionHideDirective implements OnInit, OnDestroy {
  _permission: RESPONSIBILITY;
  private kill$: Subject<void> = new Subject();
  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private permission: GlobalPermissionsService
  ) {}

  ngOnInit() {
    this.permission.userChange$
      .pipe(takeUntil(this.kill$), delay(300))
      .subscribe({
        next: () => {
          this.setVisiblity();
        }
      }); /* No error handling required here. */
    }

  ngOnDestroy() {
    this.kill$.next();
    this.kill$.complete();
  }

  @Input() set permissionHide(permission: RESPONSIBILITY) {
    this._permission = permission;
    this.setVisiblity();
  }

  setVisiblity() {
    this.viewContainer.clear();
    if (
      this.permission.getAvailableActions().has(this._permission) &&
      this._permission
    ) {
      // If condition is true add template to DOM
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      // Else remove template from DOM
      this.viewContainer.clear();
    }
  }
}
