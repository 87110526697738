import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ViewChild } from '@angular/core';
import { FieldType } from '@ngx-formly/material';
import { FieldTypeConfig } from '@ngx-formly/core';
import { MatCalendar } from '@angular/material/datepicker';
import * as moment from 'moment';

@Component({
  selector: 'bp2s-formly-year-picker',
  templateUrl: './formly-year-picker.component.html',
  styleUrls: ['./formly-year-picker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormlyYearPickerComponent extends FieldType<FieldTypeConfig> implements AfterViewInit {
  @ViewChild(MatCalendar) calendar: MatCalendar<moment.Moment>;

  height = 354;
  width = 296;

  selected: moment.Moment = null;

  calendarIsVisible = false;

  defaultOptions = {
    props: {
      pattern: '^[0-9]{4}$'
    },
  };

  constructor(private cdr: ChangeDetectorRef) {
    super();
  }

  ngAfterViewInit() {
    this.formControl.valueChanges.subscribe({
      next: (res) => {
        if (res && this.formControl.valid) {
          this.selected = moment(res, 'YYYY');
        } else {
          this.selected = null;
        }
      }
    }); /* No error handling required for form Observables. */
  }

  yearSelected($event: moment.Moment) {
    /* 
    Can be removed later
    this.formFieldControl.value = $event.format('YYYY');
    */
    this.formControl.setValue($event.format('YYYY'));
    this.formControl.markAsDirty();
    this.formControl.updateValueAndValidity();
    this.calendarIsVisible = false;
    this.cdr.detectChanges();
  }

  toggleVisible() {
    this.calendarIsVisible = !this.calendarIsVisible;
    this.cdr.detectChanges();
  }

  onClose(mouseEvent) {
    this.calendarIsVisible = false;
    this.cdr.detectChanges();
  }
}
