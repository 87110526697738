import { Component, Inject } from '@angular/core';
import {
  MatSnackBarRef,
  MAT_SNACK_BAR_DATA
} from '@angular/material/snack-bar';

@Component({
  selector: 'bp2s-error-snack',
  templateUrl: './error-snack.component.html',
  styleUrls: ['./error-snack.component.scss']
})
export class ErrorSnackComponent {
  constructor(
    @Inject(MAT_SNACK_BAR_DATA) public error: any,
    public snackBar: MatSnackBarRef<ErrorSnackComponent>
  ) {}
}
