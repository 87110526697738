import { Component, OnInit } from '@angular/core';
import { AuthService } from '@bp2s/core/auth/auth.service';

@Component({ selector: 'bp2s-auth-callback', template: '' })
export class AuthCallbackComponent implements OnInit {
  SSOErrors = ['APP_NOT_GRANTED', 'APP_DISABLED'];

  constructor(private authService: AuthService) {}
  async ngOnInit() {
    try {
      await this.authService.completeAuthentication();
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  }
}
