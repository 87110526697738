import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DisclaimerComponent } from '@bp2s/core/disclaimer/disclaimer.component';
import { MatButtonModule } from '@angular/material/button';



@NgModule({
    declarations: [DisclaimerComponent],
    imports: [
        CommonModule,
        MatButtonModule,
    ],
    exports: [DisclaimerComponent]
})
export class DisclaimerModule { }
