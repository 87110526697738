export const FZW_DATE_FORMAT = {
  parse: {
    dateInput: 'L'
  },
  display: {
    dateInput: 'L',
    monthYearLabel: 'MM YYYY',
    dateA11yLabel: 'L',
    monthYearA11yLabel: 'MM YYYY'
  },
  DATE_FORMAT: 'YYYY-MM-DD',
  DATETIME_FORMAT: 'YYYY-MM-DD HH:mm:ss',
  DATETIME_WITH_AMPM_FORMAT: 'YYYY-MM-DD HH:mm:ss a'
};

export const FZW_UI_DATE_FORMAT: string = 'DD/MM/YYYY';
export const FZW_UI_DATETIME_FORMAT = 'DD/MM/YYYY HH:mm:ss';

export const FZW_DATE_RANGE_ERROR = {
  startDate: 'Start Date must be before End date',
  endDate: 'End Date must be after Start Date',
  startDateBr: 'Start Date must be same as Daisy Chain start date',
  addPeriodDate: 'Start Date must be later than the previous Start Date',
};
