import { get } from 'lodash';
import { Observable, of } from 'rxjs';

export class LookupServiceUtil {

  static addOptions(
    optionTable: { label: string, value: string }[],
    ret: { label: string; value: any }[],
    onlyThisValue: string
  ) {
    if (onlyThisValue) {
      const found = optionTable.find(
        (option) => option.value === onlyThisValue
      );
      if (found) {
        ret.push(found);
      }
    } else {
      ret.push(...optionTable);
    }
  }

  static getOptions(
    optionTable: { label: string, value: string }[],
    addEmptyEntry: boolean,
    onlyThisValue: string = null
  ): Observable<{ label: string; value: any }[]> {
    const ret = [];
    if (addEmptyEntry) {
      ret.push({ label: '', value: null });
    }
    if (optionTable) {
      this.addOptions(optionTable, ret, onlyThisValue);
    }
    return of(ret);
  }

  static mapSingleValue(categoryDirectMapping: { [key: string] : string }, value: any) {
    if (!categoryDirectMapping[value]) {
      return value;
    }
    return categoryDirectMapping[value];
  }

  static mapValue(categoryDirectMapping: { [key: string] : string }, value: any): any {
    if (!categoryDirectMapping) {
      return value;
    }
    if (Array.isArray(value)) {
      return value.map((v) => LookupServiceUtil.mapSingleValue(categoryDirectMapping, v));
    }
    return this.mapSingleValue(categoryDirectMapping, value);
  }

  static initOptions(
    list: any[],
    labelName: string,
    valueName: string
  ): any {
    let optionTable = [];
    const directMapping = {};
    const reverseMapping = {};
    list.forEach((element: any) => {
      const val = get(element, valueName);
      const name = get(element, labelName);
      optionTable.push({ value: val, label: name });
      directMapping[val] = name;
      reverseMapping[name] = val;
    });
    optionTable = optionTable.sort((a, b) =>
      a?.label?.localeCompare(b?.label)
    );

    return { optionTable, directMapping, reverseMapping};
  }

}
