import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, from } from 'rxjs';
import { AuthService } from '@bp2s/core/auth/auth.service';
import { LocalStorageService } from 'ngx-webstorage';
import { STORAGE_TOKENS } from '@bp2s/core/consts/ls-tokens.const';
import { map } from 'rxjs/operators';
import { ConfigurationService } from '@bp2s/core/services/configuration.service';
import { noop } from 'lodash';

@Injectable({
  providedIn: 'root',
})
export class AuthGuardService  {
  constructor(
    private authService: AuthService,
    private configService: ConfigurationService,
    private localStorageService: LocalStorageService
  ) {}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    this.localStorageService.store(STORAGE_TOKENS.ST_PREVIOUS_URL, state.url);

    return from(this.authService.isLoggedInPromise()).pipe(
      map((user) => {
        if (
          (user && !user.expired) ||
          this.configService.configuration.auth.mocked
        ) {
          return true;
        }

        this.authService.startAuthentication().then(
          (res) => noop(),
          (err) => noop()
        );

        return false;
      })
    );
  }
}
