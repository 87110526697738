<ng-container *ngIf="item?.type === types.default">
  <div class="selected-arrow"></div>

  <div class="nav-item" [class.selected]="selected">
    <mat-icon class="nav-item__icon">{{ item?.icon }}</mat-icon>
    <h1 class="nav-item__text">{{ item?.name }}</h1>
  </div>
</ng-container>

<ng-container *ngIf="item?.type === types.flow">
  <bp2s-left-nav-flow
    [selected]="selected"
    [icon]="item?.icon"
    [module]="item?.name"
    [page]="item?.params?.page"
    [sections]="item?.params?.sections"
  ></bp2s-left-nav-flow>
</ng-container>
