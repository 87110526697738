import {
  trigger,
  transition,
  animate,
  style,
  query,
  stagger
} from '@angular/animations';

export const leftNavFlowExpansionAnimation = {
  flowExpanded: trigger('flowExpanded', [
    transition(
      ':enter',
      query('.left-nav-flow__section', [
        stagger('0.3s', [
          style({ opacity: 0 }),
          animate('0.3s', style({ opacity: 1 }))
        ])
      ])
    )
  ]),
  flowSelected: trigger('flowSelected', [])
};
