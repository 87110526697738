import { Injectable } from '@angular/core';
import { GlobalNavItem } from '../models/global-nav-item.model';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GlobalNavService {
  navItems: BehaviorSubject<GlobalNavItem[]> = new BehaviorSubject(null);
  constructor() {}
  setNav(nav: GlobalNavItem[]) {
    this.navItems.next(nav);
  }
}
