import { Component, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { FieldType } from '@ngx-formly/material';
import { FieldTypeConfig } from '@ngx-formly/core';
import { MatSelectTrigger } from '@angular/material/select';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Observable, of, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'bp2s-formly-select-chips',
  templateUrl: './formly-select-chips.component.html',
  styleUrls: ['./formly-select-chips.component.scss'],
})
export class FormlySelectChipsComponent extends FieldType<FieldTypeConfig>
  implements OnInit, OnDestroy {
  @ViewChild('selectInput', { static: true }) selectInput: ElementRef<
    HTMLSelectElement
  >;

  @ViewChild(MatSelectTrigger, { static: true })
  select: MatSelectTrigger;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  selectable = true;
  removable = true;
  list: Observable<any[]> = of([]);
  private kill$: Subject<void> = new Subject();
  private lookupTable = {};

  ngOnInit() {
    this.selectable = this.props.selectable ?? false;
    this.removable = this.props.removable ?? true;
    if (this.props.options) {
      if (this.props.options instanceof Observable) {
        this.list = this.props.options;
      } else {
        this.list = of(this.props.options);
      }
      this.list.pipe(takeUntil(this.kill$))
        .subscribe({
          next: (options: any[]) => {
            options.forEach((option) => {
              this.lookupTable[option.value] = option.label;
            });
          }
        }); /* No error handling required here. */
    }
  }

  ngOnDestroy() {
    this.kill$.next();
  }

  optionLookup(value: string) {
    let ret = value;
    if (this.lookupTable[value] !== undefined) {
      ret = this.lookupTable[value];
    }
    return ret;
  }

  remove(item: any): void {
    const items = this.formControl.value as any[];
    this.removeFirst(items, item);
    this.formControl.setValue([ ...items ]); // To trigger change detection
    this.formControl.markAllAsTouched();
    this.formControl.markAsDirty();
    this.formControl.updateValueAndValidity();
  }

  private removeFirst<T>(array: T[], toRemove: T): void {
    const index = array.indexOf(toRemove);
    if (index !== -1) {
      array.splice(index, 1);
    }
  }
}
