import { Component, Inject, HostBinding } from '@angular/core';
import { BrowserDetectService } from '@bp2s/core/services/browser-detect.service';
import { DOCUMENT } from '@angular/common';
import { AuthService } from '@bp2s/core/auth/auth.service';
import { SentryService } from '@bp2s/core/services/sentry-error-handler.service';

@Component({
  selector: 'bp2s-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'fzw-ui';

  @HostBinding('@.disabled')
  public animationsDisabled = true;

  constructor(
    private sentryService: SentryService,
    private authService: AuthService,

    @Inject(DOCUMENT) document: Document,
    browserDetect: BrowserDetectService
  ) {
    this.sentryService.init();
    this.authService.init();

    const window = document.defaultView;
    this.animationsDisabled = browserDetect.isBrowserSupport(
      window.navigator.userAgent
    );
  }
}
