export enum STORAGE_TOKENS {
  ST_PREVIOUS_URL = 'ST_PREVIOUS_URL',
  MOCK_USER_TOKEN = 'MK-USER_TOKEN',
}

export enum SHARED_HEADERS {
  DISABLED_RETRY = 'DISABLED_RETRY',
  DISABLED_PROGRESS = 'DISABLED_PROGRESS',
  DISABLED_ERRORS = 'DISABLED_ERRORS',
}
