import { Injectable } from '@angular/core';
import { BR_LOOKUP_CATEGORIES } from '@bp2s/business-rules/consts/business-rules.consts';
import { Observable, of, combineLatest } from 'rxjs';
import { map, tap, catchError, take, first } from 'rxjs/operators';
import { forOwn, sortBy } from 'lodash';
import { NGXLogger } from 'ngx-logger';
import { ErrorHandleService } from '@bp2s/core/errors/error-handle.service';
import { MetamodelService } from '@bp2s/shared/metamodel/metamodel.service';
import { BorMaster } from '@bp2s/model/borMaster';
import { BnpLookupResponse } from '@bp2s/model/bnpLookupResponse';
import { PolicyTreeSeriesMetadata } from '@bp2s/model/policyTreeSeriesMetadata';
import { DaisyChain } from '@bp2s/model/daisyChain';
import { BrLookupDependenciesService } from '@bp2s/business-rules/services/br-lookup-dependencies.service';
import { LookupServiceUtil } from '@bp2s/app/shared/utils/lookup-service.util';

@Injectable({
  providedIn: 'root',
})
export class BrLookupService {
  /* fetch data from the API only once */
  initialized = false;
  /* dropdown options for each category */
  optionTables = {};
  /* map values to labels for each category*/
  directMappings = {};
  /* map labels to values for each category */
  reverseMappings = {};

  lookup$: Observable<BnpLookupResponse>;
  borList$: Observable<BorMaster[]>;
  dcList$: Observable<any[]>;
  brList$: Observable<any[]>;
  fsList$: Observable<PolicyTreeSeriesMetadata[]>;
  fscoList$: Observable<any[]>;
  fscoInstrList$: Observable<any[]>;

  constructor(
    private api: BrLookupDependenciesService,
    private metamodelService: MetamodelService,
    private errorHandleService: ErrorHandleService,
    private logger: NGXLogger,
  ) {
    this.lookup$ = this.api.bnpLookupControllerService.bnpLookupGetLookupByCategoriesUsingGET(
      BR_LOOKUP_CATEGORIES
    );

    this.borList$ = this.api.daisyChainControllerService
      .daisyChainGetDaisyChainMasterRefDataUsingGET()
      .pipe(map((element) => element.borMasters));

    this.dcList$ = this.metamodelService.getMetamodelByKey(
      'edit',
      'businessRules',
      'all'
    );
    this.brList$ = this.dcList$.pipe(
      map((data) => data[0].metaData),
      map((list: any[]) => {
        const ret = [];
        for (const dc of list) {
          const brConfig: any[] = dc.businessRulesConfig;
          for (const br of brConfig) {
            ret.push(br);
          }
        }
        return ret;
      })
    );

    /* warning: newly-created PolicyTrees will not be listed here! */
    this.fsList$ = this.api.policyTreeVersionControllerService.policyTreeVersionGetAllDistinctPolicyTreesMetadataUsingGET().pipe(
      map((list) => {
        return sortBy(
          list.filter(
            (fs) =>
              fs.workflowStatus ===
              PolicyTreeSeriesMetadata.WorkflowStatusEnum.ACTIVE
          ),
          'name'
        );
      })
    );

    this.fscoInstrList$ = this.api.referenceService.referenceDataGetValuesByLevelTypeUsingGET('CLIENT_INSTR_SUB_DESC');
  }

  /* get a dropdown list, filtered or not, with or without an empty item */
  getOptions(
    category: string,
    addEmptyEntry: boolean,
    onlyThisValue: string = null
  ): Observable<{ label: string; value: any }[]> {
    return LookupServiceUtil.getOptions(this.optionTables[category], addEmptyEntry, onlyThisValue);
  }

  mapSingleValue(category, value) {
    return LookupServiceUtil.mapSingleValue(this.directMappings[category], value);
  }

  mapValue(category: string, value: any): any {
    return LookupServiceUtil.mapValue(this.directMappings[category], value);
  }

  initOptions(
    tableName: string,
    list: any[],
    labelName: string,
    valueName: string
  ) {
    const { optionTable, directMapping, reverseMapping } = LookupServiceUtil.initOptions(list, labelName, valueName);
    this.optionTables[tableName] = optionTable;
    this.directMappings[tableName] = directMapping;
    this.reverseMappings[tableName] = reverseMapping;
  }

  /* Fetch all lookup tables and set up dropdown option lists and direct/reverse mappings */
  fetchLookupData(): Observable<boolean> {
    this.logger.info('Initializing BR Lookup');
    if (this.initialized) {
      return of(true);
    }
    return combineLatest([
      this.lookup$,
      this.borList$,
      this.dcList$,
      this.brList$,
      this.fsList$,
      this.fscoInstrList$
    ]).pipe(
      tap({
        next: ([response, borList, dcList, brList, fsList, fscoInst]) => {
          this.optionTables = {};
          this.directMappings = {};
          this.reverseMappings = {};
          forOwn(response.lookupDetails, (val, key) => {
            this.initOptions(key, val, 'codeValue', 'code');
          });
          this.initOptions('BOR_IDS', borList, 'borName', 'borId');
          this.initOptions(
            'DAISY_CHAIN_IDS',
            dcList[0].metaData,
            'daisyChainName',
            'daisyChainID'
          );
          this.initOptions(
            'BUSINESSRULE_IDS',
            brList,
            'props.label',
            'key'
          );
          this.initOptions(
            'FSCO_INSTRUMENTS',
            fscoInst,
            'value',
            'key'
          );
          this.initOptions(
            'FUND_STRUCTURE_NAMES',
            fsList,
            'name',
            'policyTreeId'
          );
          this.initialized = true;
        }
      }),
      map((r) => this.initialized),
      take(1),
      catchError((err) => {
        this.errorHandleService.handleWithNotification(
          err,
          'Sorry there was a problem retrieving Business Rules metadata'
        );
        return of(false);
      })
    );
  }

  getDaisyChainPendingChanges(approvalId): Observable<DaisyChain> {
    return this.api.daisyChainControllerService
      .daisyChainGetDaisyChainPendingChangesUsingGET(approvalId)
      .pipe(
        first(),
        tap({ next: (x) => this.logger.info('getDaisyChainPendingChanges', x) })
      );
  }
}
