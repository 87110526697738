import { Component, OnInit, OnDestroy } from '@angular/core';
import { AuthService } from '@bp2s/core/auth/auth.service';
import { environment } from '@bp2s/environments/environment';
import { ConfigurationService } from '@bp2s/core/services/configuration.service';
import { UI_GIT_BRANCH, UI_GIT_VERSION, UI_GIT_TAG } from '@bp2s/core/consts/git-info';
import { InfoControllerService } from '@bp2s/api/infoController.service';
import { Info } from '@bp2s/model/info';
import { of, Subject } from 'rxjs';
import { catchError, debounceTime, filter, switchMap, takeUntil, tap } from 'rxjs/operators';
import { isNil } from 'lodash';
import { NGXLogger } from 'ngx-logger';
@Component({
  selector: 'bp2s-user-profile-panel',
  templateUrl: './user-profile-panel.component.html',
  styleUrls: ['./user-profile-panel.component.scss'],
})
export class UserProfilePanelComponent implements OnInit, OnDestroy {
  kill$ = new Subject<void>();
  isMock = false;
  version = environment.version;
  gitInfo = {
    ui: {
      branch: UI_GIT_BRANCH,
      sha1: UI_GIT_VERSION,
      tag: UI_GIT_TAG,
    }
  };
  apiInfo: Info = null;

  constructor(
    private configService: ConfigurationService,
    public authService: AuthService,
    private infoControllerService: InfoControllerService,
    private logger: NGXLogger,
  ) {}

  ngOnInit(): void {

    this.isMock = this.configService.configuration?.auth?.mocked ?? false;

    this.authService.user$.pipe(
      takeUntil(this.kill$),
      filter(user => !isNil(user.token_type) && !isNil(user.access_token)),
      debounceTime(500),
      tap({
        next: (user) => this.logger.info(`fetching api info with authorization: ${user.token_type} ${user.access_token}`)
      }),
      switchMap(user => this.infoControllerService.infoGetInfoUsingGET()),
      catchError(err => of(null))
    ).subscribe({
      next: (info) => {
        this.apiInfo = info;
      }
    }); /* No error handling required here. */
  }
  ngOnDestroy() {
    this.kill$.next();
  }
  async logout() {
    await this.authService.startLogout();
  }
}
