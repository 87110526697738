<header>
  <mat-icon>account_circle</mat-icon>
  <div class="user-details">
    <h3>{{ (authService.user$ | async)?.profile?.name }}</h3>
    <h5>{{ (authService.user$ | async)?.profile?.sub }}</h5>
  </div>
</header>

<bp2s-diag-user-config
  *ngIf="isMock"
  [user]="authService.user$ | async"
></bp2s-diag-user-config>

<section *ngIf="!isMock" class="actions">
  <button (click)="logout()" color="warn" mat-flat-button>Logout</button>
</section>

<section class="version">
  <p>Version {{gitInfo.ui.tag?gitInfo.ui.tag:version}}</p>
  <p class="git-version" *ngIf="gitInfo.ui.sha1 !== ''">WebUI: {{gitInfo.ui.branch}} - {{gitInfo.ui.sha1}}</p>
  <p class="git-version" *ngIf="apiInfo">WebAPI: {{apiInfo?.git.branch}} - {{apiInfo?.git.commit.shortId}}</p>
</section>
