
<input
  #yearInput
  matInput
  [id]="id"
  [errorStateMatcher]="errorStateMatcher"
  [formControl]="formControl"
  [max]="props.max"
  [min]="props.min"
  [maxLength]="4"
  [formlyAttributes]="field"
  [placeholder]="props.placeholder"
  [tabindex]="props.tabindex"
  [readonly]="props.readonly"
  [required]="props.required"
  [pattern]="props.pattern?props.pattern:'^[0-9]{4}$'"
  cdkOverlayOrigin
  #trigger="cdkOverlayOrigin"
/>
<button mat-icon-button
  [disabled]="props.disabled"
  (click)="toggleVisible()">
  <mat-icon>today</mat-icon>
</button>
<ng-template
  cdkConnectedOverlay
  [cdkConnectedOverlayOrigin]="trigger"
  [cdkConnectedOverlayOpen]="calendarIsVisible"
  [cdkConnectedOverlayHeight]="height"
  [cdkConnectedOverlayWidth]="width"
  [cdkConnectedOverlayHasBackdrop]="true"
  [cdkConnectedOverlayBackdropClass]="'cdk-overlay-transparent-backdrop'"
  [cdkConnectedOverlayPositions]="props.overlayPositions"
  [cdkConnectedOverlayPanelClass]="'year-picker'"
  (backdropClick)="onClose($event)"
>
<div class="mat-datepicker-content">
  <mat-calendar
    #calendar
    [selected]="selected"
    [dateClass]="props.dateClass"
    startView="multi-year"
    (yearSelected)="yearSelected($event)"
  ></mat-calendar>
</div>
</ng-template>
