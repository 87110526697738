<mat-expansion-panel
  [expanded]="props.expanded"
  class="mat-elevation-z-0"
  [togglePosition]="props.togglePosition"
>
  <mat-expansion-panel-header>
    <mat-panel-title>
      {{ props.label }}
    </mat-panel-title>
    <mat-panel-description></mat-panel-description>
  </mat-expansion-panel-header>
  <ng-container #fieldComponent></ng-container>
</mat-expansion-panel>
