<header class="checkbox-tree__header">
  <mat-checkbox
    id="checkbox-tree__select-all-btn"
    [color]="color"
    (change)="$event ? toggleAllSelected() : null"
    [checked]="isAllOptionsSelected()"
  >
    Select All
  </mat-checkbox>
</header>

<section
  *ngFor="let group of groupedOptions | keyvalue; let grpIndex = index"
  class="checkbox-tree__section"
>
  <header class="checkbox-tree__section__header">
    <mat-checkbox
      [disableRipple]="true"
      [color]="color"
      id="checkbox-tree__{{ grpIndex }}-grp-btn"
      (change)="$event ? toggleGroupSelect(group.key) : null"
      [checked]="isGroupSelected(group.key)"
      >{{ group.key || 'Other' }}</mat-checkbox
    >
  </header>

  <article class="checkbox-tree__section__content">
    <mat-checkbox
      *ngFor="let item of group.value; let index = index"
      [disableRipple]="true"
      [color]="color"
      [disabled]="item.locked"
      [class.locked]="item.locked"
      id="checkbox-tree__{{ grpIndex }}-{{ index }}-btn"
      (change)="$event ? selectItem(item) : null"
      [checked]="selection.isSelected(item)"
    >
      {{ item.label }}{{ item.locked ? '*' : '' }}</mat-checkbox
    >
  </article>
</section>
