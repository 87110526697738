/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

import { KeyExistenceCheckRequest } from '../model/models';
import { PendingChangesResponse } from '../model/models';
import { PolicyTreeAddPeriodRequest } from '../model/models';
import { PolicyTreeSaveRequest } from '../model/models';
import { PolicyTreeSeries } from '../model/models';
import { PolicyTreeSeriesMetadata } from '../model/models';
import { PolicyTreeVersion } from '../model/models';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';



@Injectable({
  providedIn: 'root'
})
export class PolicyTreeVersionControllerService {

    protected basePath = 'http://ffma-server:8080';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }


    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key,
                        (value as Date).toISOString().substr(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * addNewPeriod
     * @param policyTreeAddPeriodRequest policyTreeAddPeriodRequest
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public policyTreeVersionAddNewPeriodUsingPOST(policyTreeAddPeriodRequest: PolicyTreeAddPeriodRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<PolicyTreeSeries>;
    public policyTreeVersionAddNewPeriodUsingPOST(policyTreeAddPeriodRequest: PolicyTreeAddPeriodRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<PolicyTreeSeries>>;
    public policyTreeVersionAddNewPeriodUsingPOST(policyTreeAddPeriodRequest: PolicyTreeAddPeriodRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<PolicyTreeSeries>>;
    public policyTreeVersionAddNewPeriodUsingPOST(policyTreeAddPeriodRequest: PolicyTreeAddPeriodRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        if (policyTreeAddPeriodRequest === null || policyTreeAddPeriodRequest === undefined) {
            throw new Error('Required parameter policyTreeAddPeriodRequest was null or undefined when calling policyTreeVersionAddNewPeriodUsingPOST.');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<PolicyTreeSeries>(`${this.configuration.basePath}/policytrees/periods`,
            policyTreeAddPeriodRequest,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * cancelPolicyTreeDraftBySeriesId
     * @param seriesId seriesId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public policyTreeVersionCancelPolicyTreeDraftBySeriesIdUsingDELETE(seriesId: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<any>;
    public policyTreeVersionCancelPolicyTreeDraftBySeriesIdUsingDELETE(seriesId: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpResponse<any>>;
    public policyTreeVersionCancelPolicyTreeDraftBySeriesIdUsingDELETE(seriesId: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpEvent<any>>;
    public policyTreeVersionCancelPolicyTreeDraftBySeriesIdUsingDELETE(seriesId: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined}): Observable<any> {
        if (seriesId === null || seriesId === undefined) {
            throw new Error('Required parameter seriesId was null or undefined when calling policyTreeVersionCancelPolicyTreeDraftBySeriesIdUsingDELETE.');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.delete<any>(`${this.configuration.basePath}/policytrees/series/${encodeURIComponent(String(seriesId))}`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getAllDistinctPolicyTreesMetadata
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public policyTreeVersionGetAllDistinctPolicyTreesMetadataUsingGET(observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<Array<PolicyTreeSeriesMetadata>>;
    public policyTreeVersionGetAllDistinctPolicyTreesMetadataUsingGET(observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<Array<PolicyTreeSeriesMetadata>>>;
    public policyTreeVersionGetAllDistinctPolicyTreesMetadataUsingGET(observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<Array<PolicyTreeSeriesMetadata>>>;
    public policyTreeVersionGetAllDistinctPolicyTreesMetadataUsingGET(observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<Array<PolicyTreeSeriesMetadata>>(`${this.configuration.basePath}/policytrees/series/latest/metadata`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getPolicyTreeHistory
     * @param policyTreeId policyTreeId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public policyTreeVersionGetPolicyTreeHistoryUsingGET(policyTreeId: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<Array<PolicyTreeSeries>>;
    public policyTreeVersionGetPolicyTreeHistoryUsingGET(policyTreeId: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<Array<PolicyTreeSeries>>>;
    public policyTreeVersionGetPolicyTreeHistoryUsingGET(policyTreeId: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<Array<PolicyTreeSeries>>>;
    public policyTreeVersionGetPolicyTreeHistoryUsingGET(policyTreeId: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        if (policyTreeId === null || policyTreeId === undefined) {
            throw new Error('Required parameter policyTreeId was null or undefined when calling policyTreeVersionGetPolicyTreeHistoryUsingGET.');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<Array<PolicyTreeSeries>>(`${this.configuration.basePath}/policytrees/${encodeURIComponent(String(policyTreeId))}/history`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getPolicyTreePeriodById
     * @param periodId periodId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public policyTreeVersionGetPolicyTreePeriodByIdUsingGET(periodId: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<PolicyTreeVersion>;
    public policyTreeVersionGetPolicyTreePeriodByIdUsingGET(periodId: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<PolicyTreeVersion>>;
    public policyTreeVersionGetPolicyTreePeriodByIdUsingGET(periodId: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<PolicyTreeVersion>>;
    public policyTreeVersionGetPolicyTreePeriodByIdUsingGET(periodId: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        if (periodId === null || periodId === undefined) {
            throw new Error('Required parameter periodId was null or undefined when calling policyTreeVersionGetPolicyTreePeriodByIdUsingGET.');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<PolicyTreeVersion>(`${this.configuration.basePath}/policytrees/periods/${encodeURIComponent(String(periodId))}`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getPolicyTreeSeriesByPolicyTreeId
     * @param policyTreeId policyTreeId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public policyTreeVersionGetPolicyTreeSeriesByPolicyTreeIdUsingGET(policyTreeId: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<PolicyTreeSeries>;
    public policyTreeVersionGetPolicyTreeSeriesByPolicyTreeIdUsingGET(policyTreeId: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<PolicyTreeSeries>>;
    public policyTreeVersionGetPolicyTreeSeriesByPolicyTreeIdUsingGET(policyTreeId: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<PolicyTreeSeries>>;
    public policyTreeVersionGetPolicyTreeSeriesByPolicyTreeIdUsingGET(policyTreeId: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        if (policyTreeId === null || policyTreeId === undefined) {
            throw new Error('Required parameter policyTreeId was null or undefined when calling policyTreeVersionGetPolicyTreeSeriesByPolicyTreeIdUsingGET.');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<PolicyTreeSeries>(`${this.configuration.basePath}/policytrees/series/${encodeURIComponent(String(policyTreeId))}`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getPolicyTreeSeriesBySeriesId
     * @param seriesId seriesId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public policyTreeVersionGetPolicyTreeSeriesBySeriesIdUsingGET(seriesId: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<PolicyTreeSeries>;
    public policyTreeVersionGetPolicyTreeSeriesBySeriesIdUsingGET(seriesId: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<PolicyTreeSeries>>;
    public policyTreeVersionGetPolicyTreeSeriesBySeriesIdUsingGET(seriesId: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<PolicyTreeSeries>>;
    public policyTreeVersionGetPolicyTreeSeriesBySeriesIdUsingGET(seriesId: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        if (seriesId === null || seriesId === undefined) {
            throw new Error('Required parameter seriesId was null or undefined when calling policyTreeVersionGetPolicyTreeSeriesBySeriesIdUsingGET.');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<PolicyTreeSeries>(`${this.configuration.basePath}/policytrees/series/seriesid/${encodeURIComponent(String(seriesId))}`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getPolicyTreeSeriesPendingChangesByPolicyTreeId
     * @param policyTreeId policyTreeId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public policyTreeVersionGetPolicyTreeSeriesPendingChangesByPolicyTreeIdUsingGET(policyTreeId: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<PendingChangesResponse>;
    public policyTreeVersionGetPolicyTreeSeriesPendingChangesByPolicyTreeIdUsingGET(policyTreeId: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<PendingChangesResponse>>;
    public policyTreeVersionGetPolicyTreeSeriesPendingChangesByPolicyTreeIdUsingGET(policyTreeId: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<PendingChangesResponse>>;
    public policyTreeVersionGetPolicyTreeSeriesPendingChangesByPolicyTreeIdUsingGET(policyTreeId: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        if (policyTreeId === null || policyTreeId === undefined) {
            throw new Error('Required parameter policyTreeId was null or undefined when calling policyTreeVersionGetPolicyTreeSeriesPendingChangesByPolicyTreeIdUsingGET.');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<PendingChangesResponse>(`${this.configuration.basePath}/policytrees/series/pendingchanges/${encodeURIComponent(String(policyTreeId))}`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * migratePolicyTreeByItsId
     * @param policyTreeId policyTreeId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public policyTreeVersionMigratePolicyTreeByItsIdUsingGET(policyTreeId: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<string>;
    public policyTreeVersionMigratePolicyTreeByItsIdUsingGET(policyTreeId: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<string>>;
    public policyTreeVersionMigratePolicyTreeByItsIdUsingGET(policyTreeId: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<string>>;
    public policyTreeVersionMigratePolicyTreeByItsIdUsingGET(policyTreeId: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        if (policyTreeId === null || policyTreeId === undefined) {
            throw new Error('Required parameter policyTreeId was null or undefined when calling policyTreeVersionMigratePolicyTreeByItsIdUsingGET.');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<string>(`${this.configuration.basePath}/policytrees/migration/${encodeURIComponent(String(policyTreeId))}`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * savePolicyTreeMetadata
     * @param policyTreeSaveRequest policyTreeSaveRequest
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public policyTreeVersionSavePolicyTreeMetadataUsingPOST(policyTreeSaveRequest: PolicyTreeSaveRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<PolicyTreeSeries>;
    public policyTreeVersionSavePolicyTreeMetadataUsingPOST(policyTreeSaveRequest: PolicyTreeSaveRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<PolicyTreeSeries>>;
    public policyTreeVersionSavePolicyTreeMetadataUsingPOST(policyTreeSaveRequest: PolicyTreeSaveRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<PolicyTreeSeries>>;
    public policyTreeVersionSavePolicyTreeMetadataUsingPOST(policyTreeSaveRequest: PolicyTreeSaveRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        if (policyTreeSaveRequest === null || policyTreeSaveRequest === undefined) {
            throw new Error('Required parameter policyTreeSaveRequest was null or undefined when calling policyTreeVersionSavePolicyTreeMetadataUsingPOST.');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<PolicyTreeSeries>(`${this.configuration.basePath}/policytrees/metadata`,
            policyTreeSaveRequest,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * savePolicyTreePeriod
     * @param policyTreeSaveRequest policyTreeSaveRequest
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public policyTreeVersionSavePolicyTreePeriodUsingPOST(policyTreeSaveRequest: PolicyTreeSaveRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<PolicyTreeSeries>;
    public policyTreeVersionSavePolicyTreePeriodUsingPOST(policyTreeSaveRequest: PolicyTreeSaveRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<PolicyTreeSeries>>;
    public policyTreeVersionSavePolicyTreePeriodUsingPOST(policyTreeSaveRequest: PolicyTreeSaveRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<PolicyTreeSeries>>;
    public policyTreeVersionSavePolicyTreePeriodUsingPOST(policyTreeSaveRequest: PolicyTreeSaveRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        if (policyTreeSaveRequest === null || policyTreeSaveRequest === undefined) {
            throw new Error('Required parameter policyTreeSaveRequest was null or undefined when calling policyTreeVersionSavePolicyTreePeriodUsingPOST.');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<PolicyTreeSeries>(`${this.configuration.basePath}/policytrees/nodes`,
            policyTreeSaveRequest,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * savePolicyTree
     * @param policyTreeSaveRequest policyTreeSaveRequest
     * @param kind kind
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public policyTreeVersionSavePolicyTreeUsingPOST(policyTreeSaveRequest: PolicyTreeSaveRequest, kind?: boolean, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<PolicyTreeSeries>;
    public policyTreeVersionSavePolicyTreeUsingPOST(policyTreeSaveRequest: PolicyTreeSaveRequest, kind?: boolean, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<PolicyTreeSeries>>;
    public policyTreeVersionSavePolicyTreeUsingPOST(policyTreeSaveRequest: PolicyTreeSaveRequest, kind?: boolean, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<PolicyTreeSeries>>;
    public policyTreeVersionSavePolicyTreeUsingPOST(policyTreeSaveRequest: PolicyTreeSaveRequest, kind?: boolean, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        if (policyTreeSaveRequest === null || policyTreeSaveRequest === undefined) {
            throw new Error('Required parameter policyTreeSaveRequest was null or undefined when calling policyTreeVersionSavePolicyTreeUsingPOST.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (kind !== undefined && kind !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>kind, 'kind');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<PolicyTreeSeries>(`${this.configuration.basePath}/policytrees`,
            policyTreeSaveRequest,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getPolicyTreeMetadataPendingChangesById
     * @param policyTreeId policyTreeId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public policyTreeVersiongetPolicyTreeMetadataPendingChangesByIdUsingGET(policyTreeId: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<PolicyTreeSeries>;
    public policyTreeVersiongetPolicyTreeMetadataPendingChangesByIdUsingGET(policyTreeId: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<PolicyTreeSeries>>;
    public policyTreeVersiongetPolicyTreeMetadataPendingChangesByIdUsingGET(policyTreeId: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<PolicyTreeSeries>>;
    public policyTreeVersiongetPolicyTreeMetadataPendingChangesByIdUsingGET(policyTreeId: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        if (policyTreeId === null || policyTreeId === undefined) {
            throw new Error('Required parameter policyTreeId was null or undefined when calling policyTreeVersiongetPolicyTreeMetadataPendingChangesByIdUsingGET.');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<PolicyTreeSeries>(`${this.configuration.basePath}/policytrees/series/pendingchanges/metadata/${encodeURIComponent(String(policyTreeId))}`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getPolicyTreePeriodPendingChangesByStartDate
     * @param policyTreeId policyTreeId
     * @param effectiveStartDate effectiveStartDate
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public policyTreeVersiongetPolicyTreePeriodPendingChangesByStartDateUsingGET(policyTreeId: string, effectiveStartDate: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<Array<PolicyTreeVersion>>;
    public policyTreeVersiongetPolicyTreePeriodPendingChangesByStartDateUsingGET(policyTreeId: string, effectiveStartDate: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<Array<PolicyTreeVersion>>>;
    public policyTreeVersiongetPolicyTreePeriodPendingChangesByStartDateUsingGET(policyTreeId: string, effectiveStartDate: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<Array<PolicyTreeVersion>>>;
    public policyTreeVersiongetPolicyTreePeriodPendingChangesByStartDateUsingGET(policyTreeId: string, effectiveStartDate: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        if (policyTreeId === null || policyTreeId === undefined) {
            throw new Error('Required parameter policyTreeId was null or undefined when calling policyTreeVersiongetPolicyTreePeriodPendingChangesByStartDateUsingGET.');
        }
        if (effectiveStartDate === null || effectiveStartDate === undefined) {
            throw new Error('Required parameter effectiveStartDate was null or undefined when calling policyTreeVersiongetPolicyTreePeriodPendingChangesByStartDateUsingGET.');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<Array<PolicyTreeVersion>>(`${this.configuration.basePath}/policytrees/series/pendingchanges/period/${encodeURIComponent(String(policyTreeId))}/${encodeURIComponent(String(effectiveStartDate))}`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getPolicyTreePeriodsPendingChangesById
     * @param policyTreeId policyTreeId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public policyTreeVersiongetPolicyTreePeriodsPendingChangesByIdUsingGET(policyTreeId: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<Array<PolicyTreeVersion>>;
    public policyTreeVersiongetPolicyTreePeriodsPendingChangesByIdUsingGET(policyTreeId: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<Array<PolicyTreeVersion>>>;
    public policyTreeVersiongetPolicyTreePeriodsPendingChangesByIdUsingGET(policyTreeId: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<Array<PolicyTreeVersion>>>;
    public policyTreeVersiongetPolicyTreePeriodsPendingChangesByIdUsingGET(policyTreeId: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        if (policyTreeId === null || policyTreeId === undefined) {
            throw new Error('Required parameter policyTreeId was null or undefined when calling policyTreeVersiongetPolicyTreePeriodsPendingChangesByIdUsingGET.');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<Array<PolicyTreeVersion>>(`${this.configuration.basePath}/policytrees/series/pendingchanges/periods/${encodeURIComponent(String(policyTreeId))}`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * nameExists
     * @param policyTreeName policyTreeName
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public policytreeversionNameExistsUsingPOST(policyTreeName: KeyExistenceCheckRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<boolean>;
    public policytreeversionNameExistsUsingPOST(policyTreeName: KeyExistenceCheckRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<boolean>>;
    public policytreeversionNameExistsUsingPOST(policyTreeName: KeyExistenceCheckRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<boolean>>;
    public policytreeversionNameExistsUsingPOST(policyTreeName: KeyExistenceCheckRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        if (policyTreeName === null || policyTreeName === undefined) {
            throw new Error('Required parameter policyTreeName was null or undefined when calling policytreeversionNameExistsUsingPOST.');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<boolean>(`${this.configuration.basePath}/policytrees/name-exists`,
            policyTreeName,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
