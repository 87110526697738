import {
  Component,
  AfterViewInit,
  ViewChild,
  TemplateRef,
} from '@angular/core';
import { FieldWrapper } from '@ngx-formly/core';

@Component({
  selector: 'bp2s-formly-mat-prefixes',
  templateUrl: './formly-mat-prefixes.component.html',
  styleUrls: ['./formly-mat-prefixes.component.scss'],
})
export class FormlyMatPrefixesComponent extends FieldWrapper
  implements AfterViewInit {
  @ViewChild('matPrefix') matPrefix: TemplateRef<any>;
  @ViewChild('matSuffix') matSuffix: TemplateRef<any>;

  ngAfterViewInit() {
    if (this.props.prefixLeft) {
      Promise.resolve().then(() => (this.props.prefix = this.matPrefix));
    }

    if (this.props.prefixRight || this.props.prefixRightSecondaryIcon) {
      Promise.resolve().then(() => (this.props.suffix = this.matSuffix));
    }
  }

  prefixRightClick($event: any) {
    if (this.props.prefixRight.onClick) {
      this.props.prefixRight.onClick(this.props, this, $event);
    }
  }

  prefixLeftClick($event: any) {
    if (this.props.prefixLeft.onClick) {
      this.props.prefixLeft.onClick(this.props, this, $event);
    }
  }
}
