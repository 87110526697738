import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { AuthCallbackComponent } from '@bp2s/core/auth/auth.callback.component';
import { AuthLogoutComponent } from '@bp2s/core/auth/auth.logout.component';
import { AuthGuardService } from '@bp2s/core/guards/auth.guard';
import { ErrorPageComponent } from '@bp2s/layout/error-page/error-page.component';
import { PermissionsGuard } from '@bp2s/core/guards/permissions.guard';
import { AuthLoginComponent } from '@bp2s/core/auth/auth.login.component';
import { BrAccountResolve } from '@bp2s/business-rules/resolves/br-account.resolve';
import { BrLookupResolve } from '@bp2s/business-rules/resolves/br-lookup.resolve';
import { PmoLookupResolver } from '@bp2s/portfolio-modifiers/resolves/pmo-lookup.resolver';
import { OwuwGetTypeResolve } from '@bp2s/overwrite-underwrite/resolves/owuw-get-type.resolve';

const routes: Routes = [
  {
    path: 'error',
    component: ErrorPageComponent,
    data: {
      breadcrumbs: 'Error',
    },
  },
  { path: 'auth/logout', component: AuthLogoutComponent },
  { path: 'auth/callback', component: AuthCallbackComponent },
  { path: 'auth/login', component: AuthLoginComponent },
  {
    path: 'accounts',
    canActivate: [AuthGuardService, PermissionsGuard],
    data: {
      breadcrumbs: 'Accounts',
    },
    loadChildren: () =>
      import('./features/account-mastering/account-mastering.module').then(
        (m) => m.AccountMasteringModule
      ),
  },
  {
    path: 'approvals',
    canActivate: [AuthGuardService, PermissionsGuard],
    data: {
      breadcrumbs: 'Approvals',
    },
    loadChildren: () =>
      import('./features/approvals/approvals.module').then(
        (m) => m.ApprovalsModule
      ),
  },
  {
    path: 'bulk-upload',
    canActivate: [AuthGuardService, PermissionsGuard],
    data: {
      breadcrumbs: 'Bulk Upload',
    },
    loadChildren: () =>
      import('./features/bulk-upload/bulk-upload.module').then(
        (m) => m.BulkUploadModule
      ),
  },

  {
    path: 'dashboard',
    canActivate: [AuthGuardService, PermissionsGuard],
    data: {
      breadcrumbs: 'Dashboard',
    },
    loadChildren: () =>
      import('./features/dashboard/dashboard.module').then(
        (m) => m.DashboardModule
      ),
  },
  {
    path: 'export',
    canActivate: [AuthGuardService, PermissionsGuard],
    data: {
      breadcrumbs: 'Export',
    },
    loadChildren: () =>
      import('./features/exports/exports.module').then((m) => m.ExportsModule),
  },
  {
    path: 'fund-structures',
    canActivate: [AuthGuardService, PermissionsGuard],
    data: {
      breadcrumbs: 'Fund Structures',
    },
    loadChildren: () =>
      import('./features/fund-structures/fund-structures.module').then(
        (m) => m.FundStructureModule
      ),
  },

  {
    path: 'issuers',
    canActivate: [AuthGuardService, PermissionsGuard],
    data: {
      breadcrumbs: 'Issuers',
    },
    loadChildren: () =>
      import('./features/issuers/issuers.module').then((m) => m.IssuersModule),
  },

  {
    path: 'xref',
    canActivate: [AuthGuardService, PermissionsGuard],
    data: {
      breadcrumbs: 'XREF',
    },
    loadChildren: () =>
      import('./features/xref/xref.module').then((m) => m.XrefModule),
  },

  {
    path: 'measurements',
    canActivate: [AuthGuardService, PermissionsGuard],
    data: {
      breadcrumbs: 'Measurements',
    },
    loadChildren: () =>
      import('./features/measurements/measurements.module').then(
        (m) => m.MeasurementsModule
      ),
  },

  {
    path: 'portfolio-modifiers',
    canActivate: [AuthGuardService, PermissionsGuard],
    data: {
      breadcrumbs: 'Portfolio Modifiers',
    },
    resolve: {
      lookup: PmoLookupResolver,
    },
    loadChildren: () =>
      import('./features/portfolio-modifiers/portfolio-modifiers.module').then(
        (m) => m.PortfolioModifiersModule
      ),
  },

  {
    path: 'securities',
    canActivate: [AuthGuardService, PermissionsGuard],
    data: {
      breadcrumbs: 'Securities',
    },
    loadChildren: () =>
      import('./features/security-mastering/security-mastering.module').then(
        (m) => m.SecurityMasteringModule
      ),
  },

  {
    path: 'business-rules/account/:id',
    canActivate: [AuthGuardService, PermissionsGuard],
    data: {
      breadcrumbs:
        "{{account.accountName? account.accountName : 'DAISY CHAIN'}}",
    },
    resolve: {
      account: BrAccountResolve,
      lookup: BrLookupResolve,
    },
    loadChildren: () =>
      import('./features/business-rules/business-rules.module').then(
        (m) => m.BusinessRulesModule
      ),
  },
  {
    path: 'indices',
    canActivate: [AuthGuardService, PermissionsGuard],
    data: {
      breadcrumbs: 'INDICES',
    },
    loadChildren: () =>
      import('./features/indices/ind.module').then((m) => m.IndicesModule),
  },
  {
    path: 'overwrite-underwrite/:type',
    canActivate: [AuthGuardService, PermissionsGuard],
    resolve: {
      title: OwuwGetTypeResolve
    },
    data: {
      breadcrumbs: '{{title}}',
    },
    loadChildren: () =>
      import(
        './features/overwrite-underwrite/overwrite-underwrite.module'
      ).then((m) => m.OverwriteUnderwriteModule),
  },
  {
    path: 'jobs',
    canActivate: [AuthGuardService, PermissionsGuard],
    data: {
      breadcrumbs: 'JOBS',
    },
    loadChildren: () =>
      import('./features/jobs/jobs.module').then((m) => m.JobsModule),
  },
  {
      path: 'borexceptions',
      canActivate: [AuthGuardService, PermissionsGuard],
      data: {
        breadcrumbs: 'BOR Exceptions',
      },
      loadChildren: () =>
        import('./features/bor-exceptions/bor-exceptions.module').then(
          (m) => m.BorExceptionsModule
        ),
  },
  { path: '**', redirectTo: '/accounts' },
];

@NgModule({
  declarations: [],
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
      /* reload the page if you navigate to the same page */
      onSameUrlNavigation: 'reload',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutesModule {}
