import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserProfilePanelComponent } from '@bp2s/layout/user-profile-panel/user-profile-panel.component';
import { DiagUserConfigModule } from '@bp2s/core/diagnostics/diag-user-config/diag-user-config.module';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';



@NgModule({
  declarations: [UserProfilePanelComponent],
  imports: [
    CommonModule,
    MatDividerModule,
    MatIconModule,
    MatButtonModule,
    DiagUserConfigModule
  ],
  exports: [UserProfilePanelComponent]
})
export class UserProfilePanelModule { }

