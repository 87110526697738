import {
  ErrorHandler,
  Injectable,
} from '@angular/core';
import {
  init,
  captureException,
  showReportDialog,
  setUser,
} from '@sentry/browser';
import { environment } from '@bp2s/environments/environment';
import { User } from 'oidc-client';
import { ConfigurationService } from '@bp2s/core/services/configuration.service';

@Injectable({ providedIn: 'root' })
export class SentryErrorHandler implements ErrorHandler {
  constructor(private configService: ConfigurationService) {}
  handleError(error) {
    if (this.configService.configuration.sentry) {
      const eventId = captureException(error.originalError || error);
      showReportDialog({ eventId });
    } else {
      throw error;
    }
  }
}

@Injectable({
  providedIn: 'root',
})
export class SentryService {
  constructor(private configService: ConfigurationService) {}

  init() {
    if (this.configService.configuration.sentry) {
      init({
        dsn:
          'https://f41ccad8c35340769f5105b940db0b3d@fzw-bp2s-dev02.dev.echonet/2',
        release: 'fzw-ui@' + environment.version,
        environment: environment.envName,
      });
    }
  }

  setUser(user: User) {
    if (this.configService.configuration.sentry) {
      setUser({
        email: user.profile.email,
        id: user.profile.sub,
        username: user.profile.name,
      });
    }
  }
}
