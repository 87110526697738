import { UntypedFormControl } from '@angular/forms';
import { map, first } from 'rxjs/operators';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { ListOfAccountsControllerService } from '@bp2s/api/listOfAccountsController.service';

export function uniqueSourceAccountIdValidation(
  control: UntypedFormControl,
  field: FormlyFieldConfig
) {
  return field.options.formState.checkSourceAccountId(control.value);
}

export function uniqueSourceAccountIdValidationConfig(
  accountControllerService: ListOfAccountsControllerService
) {
  return {
    validationMessages: [
      {
        name: 'uniqueSourceAccountId',
        message: 'This source account Id is already in use.'
      }
    ],
    validators: [
      {
        name: 'uniqueSourceAccountId',
        validation: (control: UntypedFormControl) => {
          return accountControllerService
            .listOfAccountsSearchListOfAccountsUsingPOST({
              paged: false,
              terms: [{ fieldName: 'sourceAccountId', value: control.value }],
              pageRequest: { isPaged: false }
            })
            .pipe(
              first(),
              map(x =>
                x.content.findIndex(
                  _x => _x.sourceAccountId === control.value
                ) === -1
                  ? null
                  : { uniqueSourceAccountId: true }
              )
            );
        }
      }
    ]
  };
}
