import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { Location } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { NavigationEnd, NavigationError, Router } from '@angular/router';
import { isEmpty } from 'lodash';
import { Observable } from 'rxjs';
import { IBreadcrumb } from '../../../core/breadcrumb/breadcrumb.model';
import { BreadcrumbService } from '../../../core/breadcrumb/breadcrumb.service';
import { DynamicTabMenuItem } from '../../../core/models/dynamic-tab-menu-item.model';
import { DynamicTabService } from '../../../core/services/dyamic-tab.service';

@Component({
  selector: 'bp2s-dynamic-tab',
  templateUrl: './dynamic-tab-container.component.html',
  styleUrls: ['./dynamic-tab-container.component.scss']
})
export class DynamicTabContainerComponent implements OnInit {
  @Input() items: DynamicTabMenuItem[] = [];
  @Input() noMenu: string;
  public currentBreadcrumbs: IBreadcrumb[];

  constructor(
    public dynamicTabService: DynamicTabService,
    private location: Location,
    private router: Router,
    private breadcrumbs: BreadcrumbService
  ) {}

  createNewTab(path: string) {
    this.dynamicTabService.createNewTabNoFocus({path: '/accounts', breadCrumb: { text: 'accounts', path: '/accounts' }});
  }

  async setActiveTab(evt: MouseEvent, path: string, index: number) {
    if (index === this.dynamicTabService._currentTab) {
      return false;
    }
    /* All our "canDeactivate" guards call component.canDeactivate() */
    /* => it makes it possible to execute the guard early,           */
    /*    when processing the "tab clicked" event.                   */
    if (!isEmpty(this.dynamicTabService.outletGuards) && this.dynamicTabService.outletComponent.canDeactivate) {
        const ret = this.dynamicTabService.outletComponent.canDeactivate();
        if (typeof ret === 'boolean') {
          if (!ret) {
            return ret;
          }
        } else if (ret instanceof Observable) {
          const doIt = await ret.toPromise();
          if (!doIt) {
            return false;
          }
        } else {
          throw new Error(typeof ret);
        }
    }
    this.dynamicTabService.skipGuards = true;
    this.dynamicTabService.setActiveTab(index);
  }

  drop(event: CdkDragDrop<string[]>) {
    this.dynamicTabService.moveTab(event);
  }

  async closeTab(event: MouseEvent, index: number) {
    event.stopImmediatePropagation();
    if (this.dynamicTabService.tabs.getValue().length === 1) {
      return false;
    }
    if ((index === this.dynamicTabService._currentTab) &&
        !isEmpty(this.dynamicTabService.outletGuards) &&
        this.dynamicTabService.outletComponent.canDeactivate) {
        const ret = this.dynamicTabService.outletComponent.canDeactivate();
        if (typeof ret === 'boolean') {
          if (!ret) {
            return ret;
          }
        } else if (ret instanceof Observable) {
          const doIt = await ret.toPromise();
          if (!doIt) {
            return false;
          }
        } else {
          throw new Error(typeof ret);
        }
        this.dynamicTabService.skipGuards = true;
    }
    this.dynamicTabService.removeTab(index);
  }

  ngOnInit() {
    this.breadcrumbs.crumbs$.subscribe({
      next: x => {
        this.updateTabInfo();
      }
    }); /* No error handling required here. */

    this.dynamicTabService.currentTab.subscribe({
      next: async index => {
        if (index<this.dynamicTabService.NO_PAGE_RELOAD) {
          const tab = this.dynamicTabService.tabs.getValue()[index];
          await this.router.navigateByUrl(tab.path);
          this.dynamicTabService.skipGuards = false;
          this.updateTabInfo();
        }
      }
    }); /* No error handling required here. */
    this.router.events.subscribe({
      next: evt => {
        if (evt instanceof NavigationEnd || evt instanceof NavigationError) {
          this.dynamicTabService.skipGuards = false;
        }
      }
    }); /* No error handling required for router Observables. */
  }

  updateTabInfo() {
    this.currentBreadcrumbs = this.breadcrumbs.crumbs$.getValue();

    this.dynamicTabService.updateTabInfo(
      this.location.path(),
      this.currentBreadcrumbs
    );
  }
}
