<mat-chip-grid #chipList aria-label="Fruit selection" [disabled]="props.disabled">
  <mat-chip-row
    *ngFor="let item of formControl?.value"
    [removable]="props.removable"
    (removed)="remove(item)"
    [disabled]="props.disabled"
  >
    {{ props.displayWith ? props.displayWith(item) : item }}
    <mat-icon matChipRemove *ngIf="props.removable">cancel</mat-icon>
  </mat-chip-row>

<input
  matInput
  [class.isDisabled]="inputCtrl.disabled"
  [placeholder]="props.placeholder"
  [required]="props.required"
  #chipInput
  [formlyAttributes]="field"
  [formControl]="inputCtrl"
  [matAutocomplete]="auto"
  [id]="field.id"
  [matChipInputFor]="chipList"
  [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
  (matChipInputTokenEnd)="add($event)"
  (blur)="clearSearchTerms()"
/>
<mat-spinner *ngIf="isLoading" matSuffix [strokeWidth]="2" [diameter]="25"></mat-spinner>
<mat-hint *ngIf="minCharLenMsg; else checkNoMatch" class="warning smaller">{{minCharLenMsg}}</mat-hint>
<ng-template #checkNoMatch>
  <mat-hint *ngIf="noMatchFor" class="warning smaller">no match for <strong>{{noMatchFor}}</strong></mat-hint>
</ng-template>
</mat-chip-grid>
<mat-autocomplete [displayWith]="props.displayWith" [panelWidth]="props.panelWidth" (optionSelected)="selected($event)" #auto="matAutocomplete">
  <mat-option *ngFor="let item of filter | async" [value]="props.displayWith ? item : item.value">
    {{ item.label }}
  </mat-option>
</mat-autocomplete>
