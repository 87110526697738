import { UntypedFormControl } from '@angular/forms';
import { isString } from 'lodash';

export function autocompleteObjectValidator(control: UntypedFormControl) {
  if (isString(control.value)) {
    return false;
  }

  return true;
}
