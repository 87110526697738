import {
  Component,
  HostBinding,
  QueryList,
  ContentChildren,
  AfterContentInit,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { LeftNavItemComponent } from '../left-nav-item/left-nav-item.component';
import { leftNavExpansionAnimation } from './left-nav.animation';
import { LeftNavFlowComponent } from '../left-nav-flow/left-nav-flow.component';

@Component({
  selector: 'bp2s-left-nav',
  templateUrl: './left-nav.component.html',
  styleUrls: ['./left-nav.component.scss'],
  animations: [leftNavExpansionAnimation.expansion],
})
export class LeftNavComponent implements AfterContentInit {
  _selected = null;
  @Output() expanded: EventEmitter<boolean> = new EventEmitter();
  @Input() set selected(v) {
    this.setSelectedItem(v);
    this._selected = v;
  }

  get selected() {
    return this._selected;
  }
  @ContentChildren(LeftNavItemComponent) items: QueryList<LeftNavItemComponent>;
  @ContentChildren(LeftNavFlowComponent) flowItems: QueryList<
    LeftNavFlowComponent
  >;

  private _isExpanded = false;
  @HostBinding('class.expanded')
  @HostBinding('@expansion')
  get isExpanded() {
    return this._isExpanded;
  }

  toggleExpanded() {
    this._isExpanded = !this.isExpanded;
    this.expanded.next(this.isExpanded);
    this.setExpanded();
  }

  constructor() {}

  setSelectedItem(index = this.selected) {
    if (this.items && this.items.length) {
      this.items.forEach((x, i) => {
        x.selected = i === index;
      });
    }
  }

  setExpanded() {
    if (this.items && this.items.length) {
      this.flowItems.forEach((x, i) => {
        x.expanded = this.isExpanded;
      });
    }
  }

  ngAfterContentInit() {
    this.setSelectedItem();
  }
}
