<mat-select [formControl]="formControl" multiple>
  <mat-option #selectInput *ngFor="let item of list | async" [value]="item.value">{{item.label}}</mat-option>
  <mat-select-trigger>
    <mat-chip-listbox #chipList aria-label="selected list">
      <mat-chip-option
        *ngFor="let item of formControl.value"
        [selectable]="selectable"
        [removable]="removable"
        (removed)="remove(item)"
      >
        {{ props.displayWith ? props.displayWith(item) : optionLookup(item) }}
        <mat-icon matChipRemove *ngIf="props.removable">cancel</mat-icon>
      </mat-chip-option>
    </mat-chip-listbox>
  </mat-select-trigger>
</mat-select>
