import { Component, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { FieldType } from '@ngx-formly/material';
import { FormlyFieldConfig } from '@ngx-formly/core';
import {
  MatAutocompleteTrigger,
  MatAutocompleteSelectedEvent,
} from '@angular/material/autocomplete';
import { COMMA, ENTER } from '@angular/cdk/keycodes';

import { Observable, of, Subject } from 'rxjs';
import {
  startWith,
  debounceTime,
  tap,
  switchMap,
  skipWhile,
  map,
  takeUntil,
} from 'rxjs/operators';
import { isArray, isEmpty, isNull, isUndefined, reject } from 'lodash';
import { UntypedFormControl } from '@angular/forms';
import { MatChipInputEvent } from '@angular/material/chips';

let AUTOCOMPLETE_DEBOUNCE_TIME = 300;
@Component({
  selector: 'bp2s-formly-autocomplete-chips',
  templateUrl: './formly-autocomplete-chips.component.html',
  styleUrls: ['./formly-autocomplete-chips.component.scss'],
})
export class FormlyAutocompleteChipsComponent extends FieldType<FormlyFieldConfig>
  implements OnInit, OnDestroy {
  @ViewChild('chipInput', { static: true }) chipInput: ElementRef<
    HTMLInputElement
  >;

  kill$: Subject<void> = new Subject();
  inputCtrl: UntypedFormControl = new UntypedFormControl();

  @ViewChild(MatAutocompleteTrigger, { static: true })
  autocomplete: MatAutocompleteTrigger;
  filter: Observable<any>;
  isLoading = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  labelKey = 'label';
  selectable = false;
  removable = true;
  displayWith = null;
  term = '';
  noMatchFor = '';
  minCharLenMsg = ''

  ngOnInit() {
    /* Shorten input delay when running tests */
    try {
      if (process.env.JEST_WORKER_ID) {
        AUTOCOMPLETE_DEBOUNCE_TIME = 1;
      }
    } catch(err) {}

    this.labelKey = this.props.labelKey ?? 'label';
    this.selectable = this.props.selectable ?? false;
    if (isUndefined(this.props.removable)) {
      this.props.removable = true;
    }
    this.removable = this.props.removable;
    this.displayWith = this.props.displayWith ?? null;

    this.filter = this.processControlChanges();

    if (this.props.disabled) {
      this.inputCtrl.disable();
    }
    if (this.field.defaultValue) {
      this.formControl.setValue([...this.field.defaultValue]);
    }
    this.setModelInit();
  }

  ngOnDestroy() {
    this.kill$.next();
  }

  private setModelInit() {
    if (this.props.setModel && this.options.formState[this.props.setModel]) {
      const obs$ = this.options.formState[this.props.setModel](this.model);
      obs$.pipe(takeUntil(this.kill$)).subscribe(
        res => {
          if (res) {
            this.formControl.setValue([res]);
            if (!this.props.multiple) {
              this.inputCtrl.disable();
            }
          } else {
            this.formControl.setValue([]);
          }
        }
      ); /* No error handling required for form Observables. */
    }
  }

  processControlChanges() {
    return this.inputCtrl.valueChanges.pipe(
      startWith(''),
      debounceTime(AUTOCOMPLETE_DEBOUNCE_TIME),
      tap({ next: () => (this.isLoading = true) }),
      tap({ next: (term) => (this.term = term) }),
      switchMap((term) =>
        this.props.filter ? this.props.filter(term) : this.filterOptions(term)
      ),
      map((options: Partial<any[]>) =>
        this.getFilteredWithoutSelected(options)
      ),
      tap({ next: options => this.checkEmptyResults(options) }),
      tap({ next: () => (this.isLoading = false) })
    );
  }

  checkEmptyResults(options: any[]){
    if (isEmpty(options)) {
      this.noMatchFor = this.term;
      if (this.props.minCharLength) {
        if (this.term?.length && this.term.length < this.props.minCharLength) {
          this.minCharLenMsg = `at least ${this.props.minCharLength} characters`;
        } else {
          this.minCharLenMsg = '';
        }
      }
    } else {
      this.noMatchFor = '';
      this.minCharLenMsg = '';
    }
  }

  getFilteredWithoutSelected(options: any[]) {
    const selectedOptions = this.formControl?.value || [];
    if (this.displayWith) {
      return reject(options, (option) =>
        selectedOptions.find(
          (selected) => this.displayWith(selected) === this.displayWith(option)
        )
      );
    }

    return reject(options, (option) =>
      selectedOptions.includes(option.value)
    );
  }

  filterOptions(term: string) {
    if (!this.props.options) {
      return of([]);
    }

    let options = this.props.options as Observable<
      [{ label: string; value: string }]
    >;

    if (isArray(options)) {
      options = <any>of(options);
    }

    if(!term) {
      return options;
    }

    return options.pipe(
      skipWhile((objects) => {
        return !isArray(objects) || !objects.length;
      }),
      map((x) => {
        return x.filter((option) =>
          option[this.labelKey]?.toLowerCase().includes(term.toLowerCase())
        );
      })
    );
  }

  add(event: MatChipInputEvent): void {
    const { chipInput, value } = event;
    const selectedOptions = this.formControl?.value || [];
    if (this.props.displayWith || !this.props.acceptInput) {
      chipInput.inputElement.value = '';
      if (!this.props.multiple && !this.props.disabled) {
        this.inputCtrl.enable();
      }
      return this.inputCtrl.setValue(null);
    }

    if ((value || '').trim()) {
      selectedOptions.push(value.trim());      
    }

    if (chipInput.inputElement) {
      chipInput.inputElement.value = '';
    }

    this.formControl.setValue(selectedOptions);
    if (this.props.chipsChange) {
      this.props.chipsChange(this.field, selectedOptions);
    }
    this.inputCtrl.setValue(null);
    if (!this.props.multiple && !this.props.disabled) {
      if (selectedOptions.length) {
        this.inputCtrl.disable();
      } else {
        this.inputCtrl.enable();
      }
    }
  }

  remove(option: any): void {
    const opt = this.props.displayWith ? this.props.displayWith(option) : option;

    const selectedOptions = this.formControl?.value || [];
    
    const index = this.props.displayWith
      ? selectedOptions.findIndex((sel) => {
          return this.props.displayWith(sel) === opt;
        })
      : selectedOptions.indexOf(opt);

    if (index >= 0) {
      selectedOptions.splice(index, 1);
    }

    this.formControl.setValue(
      selectedOptions.length ? selectedOptions : null
    );
    if (this.props.chipsChange) {
      this.props.chipsChange(this.field, selectedOptions);
    }

    if (!this.props.multiple && !this.props.disabled) {
        this.inputCtrl.enable();
    }
    this.formControl.markAllAsTouched();
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    const selectedOptions = this.formControl?.value || [];
    if (!isNull(event.option.value?.value)) {
      selectedOptions.push(event.option.value);
    }
    this.chipInput.nativeElement.value = '';
    this.inputCtrl.setValue(null);
    this.formControl.setValue(selectedOptions);
    if (this.props.chipsChange) {
      this.props.chipsChange(this.field, selectedOptions);
    }
    if (!this.props.multiple && !this.props.disabled) {
      /* I did not find any scenario where this condition would be false. */
      /* Keeping the test anyway (defensive programming). */
      if (selectedOptions.length) {
        this.inputCtrl.disable();
      } else {
        /* istanbul ignore next */
        this.inputCtrl.enable();
      }
    }
  }

  clearSearchTerms() {
    this.chipInput.nativeElement.value = '';
    this.inputCtrl.setValue(null);
    this.formControl.markAllAsTouched();//
  }
}
