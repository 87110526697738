import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { ErrorParser } from '@bp2s/core/errors/error-handle.parser';
import { ErrorPopupComponent } from '@bp2s/core/errors/error-popup/error-popup.component';
import { ErrorSnackComponent } from '@bp2s/core/errors/error-snack/error-snack.component';
import { DEFAULT_NOTIFICATION_DURATION } from '@bp2s/core/consts/global-notification.const';

@Injectable({
  providedIn: 'root',
})
export class ErrorHandleService {
  oldParser = new ErrorParser();
  constructor(private snackBar: MatSnackBar, private dialog: MatDialog) {}

  handleWithNotification(err: HttpErrorResponse, message?: string) {
    if (message) {
      this.snackBar.openFromComponent(ErrorSnackComponent, {
        panelClass: 'error-snack-generic',
        data: message,
        duration: DEFAULT_NOTIFICATION_DURATION,
      });
    }
    if (err?.message) {
      throw new Error(err.message);
    }
  }
  handleErrorWithNotification(err: HttpErrorResponse, message?: string) {
    if (message) {
      this.snackBar.openFromComponent(ErrorSnackComponent, {
        panelClass: 'error-snack',
        data: message,
        duration: DEFAULT_NOTIFICATION_DURATION,
      });
    }
    throw(err);
  }

  handleOldErrorParser(err: { [id: string]: any }) {
    const errors = this.oldParser.parse({ errors: err });

    if (err) {
      this.dialog.open(ErrorPopupComponent, {
        panelClass: 'error-dialog',
        data: errors,
        hasBackdrop: true,
      });
    }
  }
}

