import { Observable } from 'rxjs';

import { Injectable } from '@angular/core';
import { IBreadcrumb } from './breadcrumb.model';

export type IPostProcessFunc = (
  crumbs: IBreadcrumb[]
) => Promise<IBreadcrumb[]> | Observable<IBreadcrumb[]> | IBreadcrumb[];

@Injectable({
  providedIn: 'root'
})
export class BreadcrumbsConfig {
  postProcess: IPostProcessFunc;
}
