import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PermissionHideDirective } from '@bp2s/core/directives/permission-hide.directive';
import { PermissionNoUserDirective } from '@bp2s/core/directives/permission-no-user.directive';
import { FeatureSwitchDirective } from '@bp2s/core/directives/feature-switch.directive';
import { EllipsisWithTitleDirective } from '@bp2s/core/directives/ellipsis-with-title.directive';



@NgModule({
  declarations: [PermissionHideDirective, PermissionNoUserDirective, FeatureSwitchDirective, EllipsisWithTitleDirective],
  imports: [
    CommonModule
  ],
  exports: [PermissionHideDirective, PermissionNoUserDirective, FeatureSwitchDirective, EllipsisWithTitleDirective]
})
export class CoreDirectivesModule { }
