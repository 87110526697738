import { RouteReuseStrategy } from '@angular/router/';
import { ActivatedRouteSnapshot, DetachedRouteHandle } from '@angular/router';
import { Injectable } from '@angular/core';
import { noop } from 'lodash';
import { RouteCacheService } from '@bp2s/app/core/services/route-cache.service';

@Injectable({
  providedIn: 'root'
})
export class AppUncachedRouteReuseStrategy implements RouteReuseStrategy {

  constructor(
    private routeCacheService: RouteCacheService
  ) {}

  shouldReuseRoute(before: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
    if(this.routeCacheService.shouldCache) {
      return this.shouldReuseCachedRoute(before, curr);
    }
    return false;
  }

  shouldReuseCachedRoute(before: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
    const oldpath = this.getPath(before);
    const path = this.getPath(curr);
    /* YOU CANNOT HAVE TWO TABS WITH SAME URL AND DIFFERENT CONTENT. */
    /* If you want to keep/restore pagination settings between tabs  */
    /* then you should store them as route parameters.               */
    return ((before.routeConfig === curr.routeConfig) && (oldpath === path));
  }

  shouldAttach(route: ActivatedRouteSnapshot): boolean {
    return false;
  }

  shouldDetach(route: ActivatedRouteSnapshot): boolean {
    return false;
  }

  store(route: ActivatedRouteSnapshot, detachedTree: DetachedRouteHandle): void {
    noop();
  }

  retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle | null {
    return null;
  }
  private getPath(route: ActivatedRouteSnapshot): string {
    return '/' + route.pathFromRoot
        .map(v => v.url.map(segment => segment.toString()).filter(x => !!x).join('/'))
        .filter(x => !!x)
        .join('/');
  }

}
