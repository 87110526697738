import { ChangeDetectorRef, Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatSidenavContainer } from '@angular/material/sidenav';
import { GlobalNavService, GlobalNavItem, DynamicTabService } from '@bp2s/components';
import { Router } from '@angular/router';
import { GlobalJobsService } from '@bp2s/core/services/global-jobs.service';
import { get } from 'lodash';
import { UnloadService } from '@bp2s/core/services/unload.service';

@Component({
  selector: 'bp2s-base-layout',
  templateUrl: './base-layout.component.html',
  styleUrls: ['./base-layout.component.scss'],
})
export class BaseLayoutComponent implements OnInit, OnDestroy {
  @ViewChild('topNav', {read: ElementRef, static:false}) topNav;
  @ViewChild(MatSidenavContainer, { static: true })
  sidenav: MatSidenavContainer;
  isLocalhost4301 = window?.location?.host === 'localhost:4301';
  isExpanded = false;
  _lastHeight = 60;
  topHeight = 'calc(100% - 64px)';
  intervalId: NodeJS.Timeout = null;

  @HostListener('window:beforeunload', ['$event'])
  unloadListener(event: Event) {
    this.unloadService.unloadListener(event);
  }

  expandSideNav(toggle: boolean) {
    this.isExpanded = toggle;
    setTimeout(() => {
      this.sidenav.updateContentMargins();
    }, 350);
  }

  constructor(
    private dynamicTabService: DynamicTabService,
    private cdr: ChangeDetectorRef,
    private router: Router,
    public globalNavService: GlobalNavService,
    public globalJobsService: GlobalJobsService,
    private unloadService: UnloadService,
  ) {}

  ngOnInit() {
    this.intervalId = setInterval(() => {
      this.computeContainerHeight();
    }, 1000);
  }
  ngOnDestroy() {
    clearInterval(this.intervalId);
  }

  selectNavItem(event: KeyboardEvent, item: GlobalNavItem) {
    if (event.key !== 'Enter' && event.key !== ' ') {
      return;
    }

    let route = item.route;
    if (item.queryParams) {
      const tree = this.router.parseUrl(route);
      tree.queryParams = item.queryParams;
      route = this.router.serializeUrl(tree);
    }
    this.router.navigateByUrl(route);
  }

  onActivate(comp: any) {
    /* Save an instance of the current page component */
    this.dynamicTabService.outletComponent = comp;
    /* Save the corresponding guard definitions */
    this.dynamicTabService.outletGuards = get(this.router, 'routerState.root.firstChild.children[0].routeConfig.canDeactivate');
  }

  computeContainerHeight() {
    const h = this.topNav?.nativeElement?.getBoundingClientRect().height;
    if (h && h !== this._lastHeight) {
      this._lastHeight = h;
      this.topHeight = `calc(100% - ${h}px)`;
      this.cdr.detectChanges();
    }
  }
}
