import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, timer, combineLatest, Subscription } from 'rxjs';
import { map, concatMap, retry, share } from 'rxjs/operators';
import { cloneDeep } from 'lodash';
import { AsyncJobControllerService } from '@bp2s/api/asyncJobController.service';
import { AsyncJobUiAsyncApprovalResponse } from '@bp2s/model/asyncJobUiAsyncApprovalResponse';
import { AsyncJobResponse } from '@bp2s/model/asyncJobResponse';

export enum JOB_STATUS {
  PENDING = 'PENDING',
  COMPLETE = 'COMPLETE',
  INPROGRESS = 'IN_PROGRESS',
  COMPLETED = 'COMPLETED',
  INTERRUPTED = 'INTERRUPTED'
}

export interface GlobalJob {
  id?: number | string;
  status: JOB_STATUS | AsyncJobResponse.StatusEnum;
  link: string[]
  displayText: string;
  type: string;
}


let JOB_ID = 1;

@Injectable({
  providedIn: 'root'
})
export class GlobalJobsService {

  POLL_INTERVAL = 10000;

  pollingSubscription: Subscription;

  hasJobData = false;
  jobs: BehaviorSubject<GlobalJob[]> = new BehaviorSubject([]);
  _jobs: GlobalJob[] = [];

  _asyncJobs: GlobalJob[] = [];
  asyncJobs: BehaviorSubject<GlobalJob[]> = new BehaviorSubject([]);

  jobsCount$ = this.jobs.pipe(map(x => x.filter((job) => this.isJobInProgress(job.status)).length));
  asyncJobsCount$ = this.asyncJobs.pipe(map(x => x.filter((job) => this.isJobInProgress(job.status)).length));
  
  activeJobCount$ = combineLatest([this.jobsCount$, this.asyncJobsCount$]).pipe(
    map(([jobsList, asyncJobsList]) => {
      return jobsList + asyncJobsList;
    })
  );


  constructor(
    private jobsController: AsyncJobControllerService
  ) { }

  createJob(job: GlobalJob) {
    this.hasJobData = true;
    job = { ...job, id: JOB_ID++ }
    this._jobs = [...this._jobs, job];
    this.jobs.next(cloneDeep(this._jobs));
    return job;
  }

  updateJob(id: number, job: Partial<GlobalJob>) {
    const idx = this._jobs.findIndex((x) => x.id === id);
    if (idx === -1) {
      return;
    }
    const newJob = { ...this._jobs[idx], ...job };

    this._jobs.splice(idx, 1);
    this._jobs.push(newJob);
    this.jobs.next(cloneDeep(this._jobs));
  }

  removeJob(id: number) {
    const idx = this._jobs.findIndex((x) => x.id === id);
    if (idx === -1) {
      return;
    }
    this._jobs.splice(idx, 1);
    this.jobs.next(cloneDeep(this._jobs));
  }

  clear() {
    this._jobs = [];
    this.jobs.next(cloneDeep(this._jobs));
  }

  clearJobData() {
    this.hasJobData = false;
  }

  /* for test purposes */
  reset() {
    this.clear();
    this.clearJobData();
    JOB_ID = 1;
  }

  activeJobs(): string[] {
    const ret = [];
    for (const job of this._jobs) {
      if (job.status === JOB_STATUS.PENDING) {
        ret.push(job.displayText);
      }
    }
    return ret;
  }

  /**
   * Async Jobs
   */

  createAsyncJob(job: GlobalJob) {
    this.pollForAsyncJobs();

    const idx = this._asyncJobs.findIndex((x) => x.id === job.id);
    if (idx !== -1) {
      return;
    }
    this._asyncJobs = [...this._asyncJobs, job];
    this.asyncJobs.next(cloneDeep(this._asyncJobs));
  }

  removeAsyncJob(id: string | number) {
    const idx = this._asyncJobs.findIndex((x) => x.id === id);
    if (idx === -1) {
      return;
    }
    this._asyncJobs.splice(idx, 1);
    this.asyncJobs.next(cloneDeep(this._asyncJobs));
  }

  pollForAsyncJobs() {
    if(this.pollingSubscription && !this.pollingSubscription.closed) {
      return true;
    }
    this.pollingSubscription = this.getPollingSubscription()
      .subscribe({
        next: (resp) => {
          this._asyncJobs = this.computeAsyncJobs(resp);
          this.asyncJobs.next(cloneDeep(this._asyncJobs));
          if(this._asyncJobs.every((job) => this.isJobStatusCompleted(job.status))) {
            this.pollingSubscription.unsubscribe();
          }
        }
      });
  }

  getPollingSubscription(): Observable<Array<AsyncJobUiAsyncApprovalResponse>> {
    return timer(1, this.POLL_INTERVAL).pipe(
      concatMap(() => this.jobsController.asyncjobgetAllByUserIdUsingGET()),
      retry(),
      share(),
    );
  }

  computeAsyncJobs(asyncJobs: Array<AsyncJobUiAsyncApprovalResponse>): Array<GlobalJob> {
    let jobsList = [];
    jobsList = asyncJobs
      .filter(job => (this.isJobAlreadyExists(job.jobId) || this.isJobInProgress(job.status)))
      .map(item => {
        return {
          id: item.jobId,
          status: item.status,
          link: [`/jobs/${item.jobId}`],
          displayText: `Approving ${item.entityType} records.`,
          type: 'Bulk Approve'
        };
    });
    return jobsList;
  }

  isJobStatusCompleted(jobStatus: string): boolean {
    return [JOB_STATUS.COMPLETED, JOB_STATUS.INTERRUPTED].includes(jobStatus as JOB_STATUS);
  }

  isJobInProgress(jobStatus: string): boolean {
    return [JOB_STATUS.PENDING, JOB_STATUS.INPROGRESS].includes(jobStatus as JOB_STATUS);
  }

  isJobAlreadyExists(jobId: string): boolean {
    return this._asyncJobs.some(job => job.id === jobId);
  }
}
