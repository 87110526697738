import { Component } from '@angular/core';
import { FieldWrapper } from '@ngx-formly/core';

@Component({
  selector: 'bp2s-formly-fieldgroup',
  templateUrl: './formly-fieldgroup.component.html',
  styleUrls: ['./formly-fieldgroup.component.scss']
})
export class FormlyFieldgroupComponent extends FieldWrapper {
}
