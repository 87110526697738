import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { BrLookupService } from '@bp2s/business-rules/services/br-lookup.service';
import { Observable } from 'rxjs';
 
@Injectable({
  providedIn: 'root',
})
export class BrLookupResolve  {
  constructor(private brLookupService: BrLookupService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.brLookupService.fetchLookupData();
  }
}

