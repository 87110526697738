<section class="left-nav-flow">
  <div
    *ngIf="selected && !sections.length"
    class="selected-arrow__no-expand"
  ></div>

  <header [class.selected] class="left-nav-flow__header">
    <mat-icon class="left-nav-flow__icon">{{ icon }}</mat-icon>
    <h1 class="left-nav-flow__text">{{ module }}</h1>
  </header>

  <ng-container [@flowExpanded]="expanded" *ngIf="selected && page">
    <header class="left-nav-flow__sub-header">
      <mat-icon class="left-nav-flow__icon">add</mat-icon>
      <h1 class="left-nav-flow__text">{{ page }}</h1>
    </header>

    <ul *ngIf="sections.length" class="left-nav-flow__sections">
      <div class="left-nav-flow__sections-line"></div>
      <li
        *ngFor="let item of sections; let index = index"
        (click)="select(item)"
        class="left-nav-flow__section"
      >
        <div
          [class.is-complete]="index < sectionIndex"
          [class.selected]="index === sectionIndex"
          class="left-nav-flow__section-indicator"
        ></div>
        <div class="left-nav-flow__section-text">{{ item.name }}</div>
        <div *ngIf="index === sectionIndex" class="selected-arrow"></div>
      </li>
    </ul>
  </ng-container>
</section>
