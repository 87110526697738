import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
} from '@angular/common/http';
import { retry, catchError, finalize } from 'rxjs/operators';
import { genericRetryStrategy } from '@bp2s/core/utils/rxjs-retry.util';
import { Router } from '@angular/router';
import { GLOBAL_ERROR } from '@bp2s/core/consts/global-error.consts';
import { NO_MEASUREMENT_RETRY_URLS } from '@bp2s/measurements/consts/mes-urls.consts';
import { SHARED_HEADERS } from '@bp2s/core/consts/ls-tokens.const';
import { BULK_UPLOAD_URL_PATHS } from '@bp2s/bulk-upload/consts/bku-upload-urls.consts';
import { BULK_APPROVAL_URL_PATHS } from '@bp2s/approvals/consts/apr-bulk-urls.consts';
import { of } from 'rxjs';
import { noop } from 'lodash';

const NO_RETRY_URLS = [
  ...BULK_UPLOAD_URL_PATHS,
  ...BULK_APPROVAL_URL_PATHS,
  ...NO_MEASUREMENT_RETRY_URLS,
  'asyncjob/byuserid'
];
const OPTIONAL_URLS = [ 'api/info', 'api/allowed-charset-table', 'asyncjob/byuserid' ];
@Injectable({ providedIn: 'root' })
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    private router: Router,
  ) {}
  intercept(req: HttpRequest<any>, next: HttpHandler) {
    if (req.headers.get(SHARED_HEADERS.DISABLED_ERRORS)) {
      const headers = req.headers.delete(SHARED_HEADERS.DISABLED_ERRORS);

      const newReq = req.clone({
        headers,
      });
      return next.handle(newReq);
    }

    return next.handle(req).pipe(
      retry({
        count: 3,
        delay: genericRetryStrategy({
          maxRetryAttempts: 3,
          // Temp include 504 because Gateway is timing out for Bulk Updates of Securities
          excludedStatusCodes: [400, 401, 404, 409, 504],
          // bulk upload/updates are POST & PUT requests with /bulkupload or /bulkupdate in the URL
          //measurementsets api call is added in the exception list
          disabled: req.method !== 'GET' || !!NO_RETRY_URLS.find(pattern => req.url.includes(pattern))
        })
      }),
      catchError((error) => {
        if (OPTIONAL_URLS.find(path => req.url.includes(path))) {
          return of(null);
        }
        if (
          error?.status === 0 ||
          error?.status === 401 ||
          error?.status === 403 ||
          error?.status === 404 ||
          (error?.status?.toString().substr(0, 1) === '5'  && error?.status !== 504
           // Do not redirect on bulk-upload timeouts
           && !NO_RETRY_URLS.find(pattern => req.url.includes(pattern)))
        ) {
          this.router.navigate(['/', 'error'], {
            queryParams: { error: this.getErrorObject(error?.status), keepUrl: true },
            replaceUrl: false,
            skipLocationChange: true,
          });
        }
        throw error;
      }),
      finalize(() => noop())
    );
  }

  getErrorObject(status: number) {
    switch (status) {
      case 0:
        return GLOBAL_ERROR.NO_CONNECTION;
      case 404:
        return GLOBAL_ERROR.NOT_FOUND;
      case 401:
      case 403:
        return GLOBAL_ERROR.AUTH_FAILURE;
    }
  }
}
