export const PMO_LOOKUP_CATEGORIES = [
    'EA_PURPOSE',
    'EA_SCOPE',
    'EA_LEVEL',
    'EA_TYPE',
    'EA_POS_TRAN'
  ];

export const PMO_TOO_MANY_RESULTS_MSG = '--- Too many results. Please narrow your search. ---';
export const PMO_MAX_RESULTS = 150;
