import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BaseLayoutModule } from '@bp2s/layout/base-layout/base-layout.module';

@NgModule({
  declarations: [],
  imports: [CommonModule, BaseLayoutModule],
  exports: [BaseLayoutModule],
})
export class LayoutModule {}

