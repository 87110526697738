import { Component, OnInit } from '@angular/core';
import { FieldArrayType } from '@ngx-formly/core';

@Component({
  selector: 'bp2s-formly-repeat',
  templateUrl: './formly-repeat.component.html',
  styleUrls: ['./formly-repeat.component.scss'],
})
export class FormlyRepeatComponent extends FieldArrayType implements OnInit {

  ngOnInit(): void {
    if (!this.model || this.model.length === 0) {
      setTimeout(() => this.add(0, {}), 10);
    }
  }

/*
  // creates an exception on init, but no need to add() + remove() + add()
  onPopulate(field:FormlyFieldConfig) {
    super.onPopulate(field);
    field.formControl?.updateValueAndValidity();
  }
*/

  duplicate() {
    let objToDuplicate = this.model[this.model.length - 1];
    if (this.props.duplicateFilter) {
      objToDuplicate = this.props.duplicateFilter({ ...objToDuplicate });
    }
    this.add(this.model.length, objToDuplicate);
  }
}
