import { FocusMonitor } from '@angular/cdk/a11y';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { UniqueSelectionDispatcher } from '@angular/cdk/collections';
import {
  AfterContentInit,
  AfterViewInit,
  Attribute,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  Directive,
  ElementRef,
  EventEmitter,
  forwardRef,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  QueryList,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import {
  CanDisableRipple,
  mixinDisableRipple
} from '@angular/material/core';
/*import {
  HasTabIndex,
  HasTabIndexCtor,
  mixinTabIndex
} from '@angular/material/core';*/
import { ANIMATION_MODULE_TYPE } from '@angular/platform-browser/animations';

export class BP2SButtonToggleChange {
  constructor(
    /** The MatRadioButton that emits the change event. */
    public source: BP2SButtonToggle,
    /** The value of the MatRadioButton. */
    public value: any
  ) {}
}

let nextUniqueId = 0;

export const BP2S_BUTTON_TOGGLE_GROUP_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => BP2SButtonToggleGroup),
  multi: true
};

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: 'bp2s-button-toggle-group',
  exportAs: 'bp2sButtonToggleGroup',
  providers: [BP2S_BUTTON_TOGGLE_GROUP_CONTROL_VALUE_ACCESSOR],
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    role: 'radiogroup',
    class: 'bp2s-button-toggle-group'
  }
})
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export class BP2SButtonToggleGroup
  implements AfterContentInit, ControlValueAccessor {
  /** Selected value for the button toggle group. */
  private _value: any = null;

  private _name = `bp2s-button-toggle-group-${nextUniqueId++}`;
  private _selected: BP2SButtonToggle | null = null;
  private _isInitialized = false;
  private _disabled = false;
  private _required = false;

  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() readonly change: EventEmitter<
    BP2SButtonToggleChange
  > = new EventEmitter<BP2SButtonToggleChange>();

  @ContentChildren(forwardRef(() => BP2SButtonToggle), {
    descendants: true
  })
  _buttons: QueryList<BP2SButtonToggle>;

  @Input()
  get name(): string {
    return this._name;
  }
  set name(value: string) {
    this._name = value;
    this._updateButtonNames();
  }

  @Input()
  get value(): any {
    return this._value;
  }
  set value(newValue: any) {
    if (this._value !== newValue) {
      // Set this before proceeding to ensure no circular loop occurs with selection.
      this._value = newValue;

      this._updateSelectedButtonFromValue();
      this._checkSelectedButton();
    }
  }
  _controlValueAccessorChangeFn: (value: any) => void = () => {};
  onTouched: () => any = () => {};

  _checkSelectedButton() {
    if (this._selected && !this._selected.checked) {
      this._selected.checked = true;
    }
  }

  @Input()
  get selected() {
    return this._selected;
  }
  set selected(selected: any | null) {
    this._selected = selected;
    this.value = selected ? selected.value : null;
    this._checkSelectedButton();
  }

  @Input()
  get disabled(): boolean {
    return this._disabled;
  }
  set disabled(value) {
    this._disabled = coerceBooleanProperty(value);
    this._markButtonsForCheck();
  }

  @Input()
  get required(): boolean {
    return this._required;
  }
  set required(value: boolean) {
    this._required = coerceBooleanProperty(value);
    this._markButtonsForCheck();
  }

  constructor(private _changeDetector: ChangeDetectorRef) {
  }

  ngAfterContentInit() {
    // Mark this component as initialized in AfterContentInit because the initial value can
    // possibly be set by NgModel on MatRadioGroup, and it is possible that the OnInit of the
    // NgModel occurs *after* the OnInit of the MatRadioGroup.
    this._isInitialized = true;
  }

  _touch() {
    if (this.onTouched) {
      this.onTouched();
    }
  }

  private _updateButtonNames(): void {
    if (this._buttons) {
      this._buttons.forEach(button => {
        button.name = this.name;
        button._markForCheck();
      });
    }
  }

  private _updateSelectedButtonFromValue(): void {
    // If the value already matches the selected radio, do nothing.
    const isAlreadySelected =
      this._selected !== null && this._selected.value === this._value;

    if (this._buttons && !isAlreadySelected) {
      this._selected = null;
      this._buttons.forEach(button => {
        button.checked = this.value === button.value;
        if (button.checked) {
          this._selected = button;
        }
      });
    }
  }

  _emitChangeEvent(): void {
    if (this._isInitialized) {
      this.change.emit(new BP2SButtonToggleChange(this._selected, this._value));
    }
  }

  _markButtonsForCheck() {
    if (this._buttons) {
      this._buttons.forEach(button => button._markForCheck());
    }
  }

  writeValue(value: any) {
    this.value = value;
    this._changeDetector.markForCheck();
  }

  registerOnChange(fn: (value: any) => void) {
    this._controlValueAccessorChangeFn = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
    this._changeDetector.markForCheck();
  }
}

/*class BP2SButtonToggleFieldComponentBase {
  disabled: boolean;

  constructor(public _elementRef: ElementRef) {}
}

const _BP2SButtonToggleFieldComponentMixinBase: HasTabIndexCtor &
  typeof BP2SButtonToggleFieldComponentBase = mixinTabIndex(
  BP2SButtonToggleFieldComponentBase
);*/

const _BP2SButtonToggleFieldComponentMixinBase = mixinDisableRipple(class {})

@Component({
  encapsulation: ViewEncapsulation.None,
  exportAs: 'bp2sButtonToggle',
  changeDetection: ChangeDetectionStrategy.OnPush,

  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'bp2s-button-toggle',
  templateUrl: './button-toggle-field.component.html',
  styleUrls: ['./button-toggle-field.component.scss'],
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    class: 'bp2s-button-toggle',
    '[class.bp2s-button-toggle-checked]': 'checked',
    '[class.bp2s-button-toggle-disabled]': 'disabled',
    '[class._mat-animation-noopable]': '_animationMode === "NoopAnimations"',
    // Needs to be -1 so the `focus` event still fires.
    '[attr.tabindex]': '-1',
    '[attr.id]': 'id',
    // Note: under normal conditions focus shouldn't land on this element, however it may be
    // programmatically set, for example inside of a focus trap, in this case we want to forward
    // the focus to the native element.
    '(focus)': '_inputElement.nativeElement.focus()'
  }
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class BP2SButtonToggle extends _BP2SButtonToggleFieldComponentMixinBase
  implements OnInit, AfterViewInit, OnDestroy, CanDisableRipple {
  private _uniqueId = `bp2s-button-toggle-${++nextUniqueId}`;
  @Input() id: string = this._uniqueId;

  @Input() name: string;
  @Input('aria-label') ariaLabel: string;

  @Input('aria-labelledby') ariaLabelledby: string;

  @Input('aria-describedby') ariaDescribedby: string;

  @Input()
  get checked(): boolean {
    return this._checked;
  }
  set checked(value: boolean) {
    const newCheckedState = coerceBooleanProperty(value);
    if (this._checked !== newCheckedState) {
      this._checked = newCheckedState;
      if (
        newCheckedState &&
        this.buttonGroup &&
        this.buttonGroup.value !== this.value
      ) {
        this.buttonGroup.selected = this;
      } else if (
        !newCheckedState &&
        this.buttonGroup &&
        this.buttonGroup.value === this.value
      ) {
        // When unchecking the selected radio button, update the selected radio
        // property on the group.
        this.buttonGroup.selected = null;
      }

      if (newCheckedState) {
        // Notify all radio buttons with the same name to un-check.
        this._buttonDispatcher.notify(this.id, this.name);
      }
      this._changeDetector.markForCheck();
    }
  }

  @Input()
  get value(): any {
    return this._value;
  }
  set value(value: any) {
    if (this._value !== value) {
      this._value = value;
      if (this.buttonGroup !== null) {
        if (!this.checked) {
          // Update checked when the value changed to match the radio group's value
          this.checked = this.buttonGroup.value === value;
        }
        if (this.checked) {
          this.buttonGroup.selected = this;
        }
      }
    }
  }

  @Input()
  get disabled(): boolean {
    return (
      this._disabled || (this.buttonGroup !== null && this.buttonGroup.disabled)
    );
  }
  set disabled(value: boolean) {
    const newDisabledState = coerceBooleanProperty(value);
    if (this._disabled !== newDisabledState) {
      this._disabled = newDisabledState;
      this._changeDetector.markForCheck();
    }
  }

  @Input()
  get required(): boolean {
    return this._required || (this.buttonGroup && this.buttonGroup.required);
  }
  set required(value: boolean) {
    this._required = coerceBooleanProperty(value);
  }

  @Input() tabIndex: number | null;

  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() readonly change: EventEmitter<
    BP2SButtonToggleChange
  > = new EventEmitter<BP2SButtonToggleChange>();

  buttonGroup: BP2SButtonToggleGroup;
  get inputId(): string {
    return `${this.id || this._uniqueId}-input`;
  }
  private _checked = false;
  private _disabled: boolean;
  private _required: boolean;
  private _value: any = null;
  @ViewChild('input') _inputElement: ElementRef<
    HTMLInputElement
  >;

  private _removeUniqueSelectionListener: () => void = () => {};

  constructor(
    @Optional() buttonGroup: BP2SButtonToggleGroup,
    private _elementRef: ElementRef,
    private _changeDetector: ChangeDetectorRef,
    private _focusMonitor: FocusMonitor,
    private _buttonDispatcher: UniqueSelectionDispatcher,
    @Attribute('tabIndex') defaultTabIndex: string,
    @Optional() @Inject(ANIMATION_MODULE_TYPE) public _animationMode?: string
  ) {
    // super(elementRef);
    super();

    const parsedTabIndex = Number(defaultTabIndex);
    this.tabIndex = (parsedTabIndex || parsedTabIndex === 0) ? parsedTabIndex : null;

    this.buttonGroup = buttonGroup;

    this._removeUniqueSelectionListener = _buttonDispatcher.listen(
      (id: string, name: string) => {
        if (id !== this.id && name === this.name) {
          this.checked = false;
        }
      }
    );
  }

  focus(): void {
    this._focusMonitor.focusVia(this._inputElement, 'keyboard');
  }

  _markForCheck() {
    // When group value changes, the button will not be notified. Use `markForCheck` to explicit
    // update radio button's status
    this._changeDetector.markForCheck();
  }

  ngOnInit() {
    if (this.buttonGroup) {
      // If the radio is inside a radio group, determine if it should be checked
      this.checked = this.buttonGroup.value === this._value;
      // Copy name from parent radio group
      this.name = this.buttonGroup.name;
    }
  }

  ngAfterViewInit() {
    this._focusMonitor
      .monitor(this._elementRef, true)
      .subscribe({
        next: focusOrigin => {
          if (!focusOrigin && this.buttonGroup) {
            this.buttonGroup._touch();
          }
        }
      }); /* No error handling required here. */
  }

  ngOnDestroy() {
    this._focusMonitor.stopMonitoring(this._elementRef);
    this._removeUniqueSelectionListener();
  }

  private _emitChangeEvent(): void {
    this.change.emit(new BP2SButtonToggleChange(this, this._value));
  }

  _onInputClick(event: Event) {
    event.stopPropagation();
  }

  _onInputChange(event: Event) {
    // We always have to stop propagation on the change event.
    // Otherwise the change event, from the input element, will bubble up and
    // emit its event object to the `change` output.
    event.stopPropagation();

    const groupValueChanged =
      this.buttonGroup && this.value !== this.buttonGroup.value;
    this.checked = true;
    this._emitChangeEvent();

    if (this.buttonGroup) {
      this.buttonGroup._controlValueAccessorChangeFn(this.value);
      if (groupValueChanged) {
        this.buttonGroup._emitChangeEvent();
      }
    }
  }
}
