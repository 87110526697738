import { Params } from '@angular/router';
import { LeftNavFlowSections } from './left-nav-flow.model';
import { LeftNavItemParams } from './letf-nav-item.model';

export enum GlobalNavType {
  default = 'default',
  flow = 'flow',
}

export interface GlobalNavItem {
  name: string;
  route?: string;
  queryParams?: Params,
  icon: string;
  type: GlobalNavType;
  sections?: LeftNavFlowSections[];
  params?: LeftNavItemParams;
  permission?: string;
}
