export enum RESPONSIBILITY {
  CREATE_PT = 'CREATE_PT',
  CREATE_AM = 'CREATE_AM',
  EDIT_PT = 'EDIT_PT',
  EDIT_AM = 'EDIT_AM',
  DESELECT_SOME_FIELDS_AM = 'DESELECT_SOME_FIELDS_AM',
  VIEW_PT = 'VIEW_PT',
  VIEW_AM = 'VIEW_AM',
  FOUREYECHECK_APPROVE_PT = 'FOUREYECHECK_APPROVE_PT',
  FOUREYECHECK_APPROVE_AM = 'FOUREYECHECK_APPROVE_AM',
  ASSIGN_ROLES = 'ASSIGN_ROLES',
  EXPORT_ACCOUNT = 'EXPORT_ACCOUNT',
  PERFORMANCE = 'PERFORMANCE',
  OPERATIONAL = 'OPERATIONAL',
  CLONE = 'CLONE',
  BULK_UPLOAD = 'BULK_UPLOAD',
  BULK_UPLOAD_OPS_TEMPLATE = 'BULK_UPLOAD_OPS_TEMPLATE',
  VIEW_SM = 'VIEW_SM',
  CREATE_SM = 'CREATE_SM',
  EDIT_SM = 'EDIT_SM',
  EXPORT_SECURITIES = 'EXPORT_SECURITIES',
  FOUREYECHECK_APPROVE_SM = 'FOUREYECHECK_APPROVE_SM',
  ALLFIELDS_4EYECHECK = 'ALLFIELDS_4EYECHECK',
  DELETE_PT = 'DELETE_PT',
  CREATE_MES = 'CREATE_MES',
  VIEW_MES = 'VIEW_MES',
  EDIT_MES = 'EDIT_MES',
  CREATE_BR = 'CREATE_BR',
  ACTIVATE_MES = 'ACTIVATE_MES',
  INACTIVATE_MES = 'INACTIVATE_MES',
  CREATE_IND = 'CREATE_IND',
  VIEW_UW_OW = 'VIEW_UW_OW',
	CREATE_UW_OW = 'CREATE_UW_OW',
	EDIT_UW_OW = 'EDIT_UW_OW',
	APPROVE_OW_UW = 'APPROVE_OW_UW',
  EDIT_EA = 'EDIT_EA',
  INACTIVATE_EA = 'INACTIVATE_EA',
  VIEW_XREF = 'VIEW_XREF',
  CREATE_XREF = 'CREATE_XREF',
  VIEW_TIMESERIES = 'VIEW_TIMESERIES',
  CREATE_TIMESERIES = 'CREATE_TIMESERIES',
  EDIT_TIMESERIES = 'EDIT_TIMESERIES',
  EXPORT_BOR_EXCEPTIONS = "EXPORT_BOR_EXCEPTIONS"
}

export const ROLES = {
  BNP_PARIBAS_MANAGER: [
    RESPONSIBILITY.VIEW_AM,
    RESPONSIBILITY.FOUREYECHECK_APPROVE_AM,
    RESPONSIBILITY.VIEW_PT,
    RESPONSIBILITY.FOUREYECHECK_APPROVE_PT,
    RESPONSIBILITY.DELETE_PT,
    RESPONSIBILITY.PERFORMANCE,
    RESPONSIBILITY.OPERATIONAL,
    RESPONSIBILITY.EXPORT_ACCOUNT,
    RESPONSIBILITY.VIEW_SM,
    RESPONSIBILITY.FOUREYECHECK_APPROVE_SM,
    RESPONSIBILITY.EXPORT_SECURITIES,
    RESPONSIBILITY.VIEW_MES,
    RESPONSIBILITY.VIEW_XREF,
    RESPONSIBILITY.BULK_UPLOAD_OPS_TEMPLATE,
    RESPONSIBILITY.EXPORT_BOR_EXCEPTIONS
  ],
  BNP_PARIBAS_OPERATIONAL_MEMBER: [
    RESPONSIBILITY.CREATE_AM,
    RESPONSIBILITY.EDIT_AM,
    RESPONSIBILITY.VIEW_AM,
    RESPONSIBILITY.CREATE_PT,
    RESPONSIBILITY.EDIT_PT,
    RESPONSIBILITY.VIEW_PT,
    RESPONSIBILITY.DELETE_PT,
    RESPONSIBILITY.PERFORMANCE,
    RESPONSIBILITY.OPERATIONAL,
    RESPONSIBILITY.EXPORT_ACCOUNT,
    RESPONSIBILITY.CLONE,
    RESPONSIBILITY.BULK_UPLOAD,
    RESPONSIBILITY.BULK_UPLOAD_OPS_TEMPLATE,
    RESPONSIBILITY.CREATE_SM,
    RESPONSIBILITY.EDIT_SM,
    RESPONSIBILITY.VIEW_SM,
    RESPONSIBILITY.EXPORT_SECURITIES,
    RESPONSIBILITY.EXPORT_BOR_EXCEPTIONS,
    RESPONSIBILITY.CREATE_MES,
    RESPONSIBILITY.VIEW_MES,
    RESPONSIBILITY.EDIT_MES,
    RESPONSIBILITY.CREATE_BR,
    RESPONSIBILITY.ACTIVATE_MES,
    RESPONSIBILITY.INACTIVATE_MES,
    RESPONSIBILITY.CREATE_IND,
    RESPONSIBILITY.CREATE_UW_OW,
    RESPONSIBILITY.EDIT_EA,
    RESPONSIBILITY.INACTIVATE_EA,
    RESPONSIBILITY.VIEW_XREF,
    RESPONSIBILITY.CREATE_XREF,
    RESPONSIBILITY.VIEW_TIMESERIES,
    RESPONSIBILITY.CREATE_TIMESERIES,
    RESPONSIBILITY.EDIT_TIMESERIES
  ],
  CLIENT_OPS_MANAGER: [
    RESPONSIBILITY.VIEW_AM,
    RESPONSIBILITY.FOUREYECHECK_APPROVE_AM,
    RESPONSIBILITY.OPERATIONAL,
    RESPONSIBILITY.EXPORT_ACCOUNT,
    RESPONSIBILITY.VIEW_PT
  ],
  CLIENT_OPS_MEMBER: [
    RESPONSIBILITY.CREATE_AM,
    RESPONSIBILITY.EDIT_AM,
    RESPONSIBILITY.DESELECT_SOME_FIELDS_AM,
    RESPONSIBILITY.VIEW_AM,
    RESPONSIBILITY.OPERATIONAL,
    RESPONSIBILITY.EXPORT_ACCOUNT,
    RESPONSIBILITY.CLONE,
    RESPONSIBILITY.CREATE_BR,
    RESPONSIBILITY.EDIT_EA,
    RESPONSIBILITY.INACTIVATE_EA,
    RESPONSIBILITY.VIEW_TIMESERIES,
    RESPONSIBILITY.CREATE_TIMESERIES,
    RESPONSIBILITY.EDIT_TIMESERIES
  ],
  IDM_TEAM_MANAGER: [
    RESPONSIBILITY.VIEW_AM,
    RESPONSIBILITY.FOUREYECHECK_APPROVE_AM,
    RESPONSIBILITY.VIEW_PT,
    RESPONSIBILITY.FOUREYECHECK_APPROVE_PT,
    RESPONSIBILITY.DELETE_PT,
    RESPONSIBILITY.PERFORMANCE,
    RESPONSIBILITY.OPERATIONAL,
    RESPONSIBILITY.EXPORT_ACCOUNT,
    RESPONSIBILITY.VIEW_SM,
    RESPONSIBILITY.FOUREYECHECK_APPROVE_SM,
    RESPONSIBILITY.EXPORT_SECURITIES,
    RESPONSIBILITY.VIEW_MES,
    RESPONSIBILITY.EXPORT_BOR_EXCEPTIONS
  ],
  IDM_TEAM_MEMBER: [
    RESPONSIBILITY.CREATE_AM,
    RESPONSIBILITY.EDIT_AM,
    RESPONSIBILITY.VIEW_AM,
    RESPONSIBILITY.CREATE_PT,
    RESPONSIBILITY.EDIT_PT,
    RESPONSIBILITY.VIEW_PT,
    RESPONSIBILITY.DELETE_PT,
    RESPONSIBILITY.PERFORMANCE,
    RESPONSIBILITY.OPERATIONAL,
    RESPONSIBILITY.EXPORT_ACCOUNT,
    RESPONSIBILITY.CLONE,
    RESPONSIBILITY.BULK_UPLOAD,
    RESPONSIBILITY.BULK_UPLOAD_OPS_TEMPLATE,
    RESPONSIBILITY.CREATE_SM,
    RESPONSIBILITY.EDIT_SM,
    RESPONSIBILITY.VIEW_SM,
    RESPONSIBILITY.EXPORT_SECURITIES,
    RESPONSIBILITY.CREATE_MES,
    RESPONSIBILITY.VIEW_MES,
    RESPONSIBILITY.EDIT_MES,
    RESPONSIBILITY.CREATE_BR,
    RESPONSIBILITY.ACTIVATE_MES,
    RESPONSIBILITY.INACTIVATE_MES,
    RESPONSIBILITY.CREATE_IND,
    RESPONSIBILITY.CREATE_UW_OW,
    RESPONSIBILITY.EDIT_EA,
    RESPONSIBILITY.INACTIVATE_EA,
    RESPONSIBILITY.VIEW_TIMESERIES,
    RESPONSIBILITY.CREATE_TIMESERIES,
    RESPONSIBILITY.EDIT_TIMESERIES,
    RESPONSIBILITY.EXPORT_BOR_EXCEPTIONS
  ],
  PERFORMANCE_MANAGER: [
    RESPONSIBILITY.VIEW_AM,
    RESPONSIBILITY.FOUREYECHECK_APPROVE_AM,
    RESPONSIBILITY.PERFORMANCE,
    RESPONSIBILITY.EXPORT_ACCOUNT,
    RESPONSIBILITY.VIEW_PT
  ],
  PERFORMANCE_MEMBER: [
    RESPONSIBILITY.EDIT_AM,
    RESPONSIBILITY.DESELECT_SOME_FIELDS_AM,
    RESPONSIBILITY.VIEW_AM,
    RESPONSIBILITY.PERFORMANCE,
    RESPONSIBILITY.EXPORT_ACCOUNT,
    RESPONSIBILITY.VIEW_TIMESERIES,
    RESPONSIBILITY.CREATE_TIMESERIES,
    RESPONSIBILITY.EDIT_TIMESERIES
  ],
  VIEW_ONLY: [
    RESPONSIBILITY.VIEW_AM,
    RESPONSIBILITY.EXPORT_ACCOUNT,
    RESPONSIBILITY.VIEW_SM,
    RESPONSIBILITY.VIEW_PT,
    RESPONSIBILITY.EXPORT_SECURITIES,
    RESPONSIBILITY.VIEW_MES
  ]
};
