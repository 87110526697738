import { Injectable } from '@angular/core';
import { Observable, of, lastValueFrom } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { shareReplay, tap, catchError } from 'rxjs/operators';
import { environment } from '@bp2s/environments/environment';
import { SHARED_HEADERS } from '@bp2s/core/consts/ls-tokens.const';

interface Configuration {
  envName: string;
  sentry: boolean;
  auth: {
    mocked: boolean;
    authority: string;
    client_id: string;
    login: string;
    silent_redirect: string;
    logout: string;
    sso: string;
  };
}

export function initConfigFactory(configService: ConfigurationService) {
  return () => lastValueFrom(configService.loadConfigurations())
}

@Injectable({
  providedIn: 'root',
})
export class ConfigurationService {
  private readonly CONFIG_URL = 'assets/environment/environment.json';

  public configuration: Configuration;
  private configuration$: Observable<Configuration>;
  constructor(
    private httpClient: HttpClient,
    ) {}
  public loadConfigurations(): Observable<Configuration> {
    if (!this.configuration$) {
      this.configuration$ = this.httpClient
        .get<Configuration>(this.CONFIG_URL, {
          headers: { [SHARED_HEADERS.DISABLED_ERRORS]: '1' },
        })
        .pipe(
          tap({
            next: (x) => {
              this.configuration = x;
            }
          }),
          shareReplay(1),
          catchError((err) => {
            this.configuration = environment as any;
            return of(null);
          })
        );
    }
    return this.configuration$;
  }
}
