import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CheckboxTreeComponent } from './checkbox-tree.component';
import { MatCheckboxModule } from '@angular/material/checkbox';

@NgModule({
  declarations: [CheckboxTreeComponent],
  imports: [CommonModule, MatCheckboxModule],
  exports: [CheckboxTreeComponent],
})
export class CheckboxTreeModule {}
