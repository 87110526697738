import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'bp2s-error-popup',
  templateUrl: './error-popup.component.html',
  styleUrls: ['./error-popup.component.scss']
})
export class ErrorPopupComponent {
  errorCategories: Array<string>;
  listOfErrors: Map<string, Array<any>> = new Map<string, any[]>();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.listOfErrors = data;
    if (this.listOfErrors) {
      this.errorCategories = Array.from(this.listOfErrors.keys());
    }
  }
}
