import { throwError, timer } from 'rxjs';

export const genericRetryStrategy = ({
  maxRetryAttempts = 3,
  scalingDuration = 1000,
  excludedStatusCodes = [],
  disabled = false
}: {
  maxRetryAttempts?: number;
  scalingDuration?: number;
  excludedStatusCodes?: number[];
  disabled?: boolean;
} = {}) => (error, i) => {

  const retryAttempt = i;
  // if maximum number of retries have been met
  // or response is a status code we don't wish to retry, throw error
  if (
    disabled ||
    retryAttempt > maxRetryAttempts ||
    excludedStatusCodes.find(e => e === error.status)
  ) {
    return throwError(() => error);
  }
  // eslint-disable-next-line no-console
  console.log(
    `Attempt ${retryAttempt}: retrying in ${retryAttempt *
      scalingDuration}ms`
  );
  
  return timer(retryAttempt * scalingDuration);
};
