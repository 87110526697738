/* istanbul ignore file */

import { Observable, of, from } from 'rxjs';

import { template, templateSettings } from 'lodash';

const _ = {
  template: template,
  templateSettings: templateSettings
};

_.templateSettings.interpolate = /{{([\s\S]+?)}}/g;

declare var require: any;


export function stringFormat(temp: string, binding: any): string {
  const compiled = _.template(temp);
  return compiled(binding);
}

export function isPromise(value: any): boolean {
  return value && typeof value.then === 'function';
}

export function wrapIntoObservable<T>(
  value: T | Promise<T> | Observable<T>
): Observable<T> {
  if (value instanceof Observable) {
    return value;
  }

  if (isPromise(value)) {
    return from(Promise.resolve(value));
  }

  return of(value as T);
}
