import { CommonModule } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS, HttpClientXsrfModule } from '@angular/common/http';
import {
  APP_INITIALIZER,
  ErrorHandler,
  NgModule,
} from '@angular/core';
import { MomentDateModule, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import {
  MatDialogModule,
  MAT_DIALOG_DEFAULT_OPTIONS,
} from '@angular/material/dialog';
import { MAT_PAGINATOR_DEFAULT_OPTIONS } from '@angular/material/paginator';
import {
  MatSnackBarModule,
  MAT_SNACK_BAR_DEFAULT_OPTIONS,
} from '@angular/material/snack-bar';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DYNAMIC_TAB_CONFIG, GlobalNavService } from '@bp2s/components';
import { AkitaNgDevtools } from '@datorama/akita-ngdevtools';
import { LoggerModule, NgxLoggerLevel } from 'ngx-logger';
import { NgxWebstorageModule } from 'ngx-webstorage';
import { environment } from '@bp2s/environments/environment';
import { ErrorPageModule } from '@bp2s/layout/error-page/error-page.module';
import { DynamicFormsModule } from '@bp2s/shared/forms/forms.module';
import { AuthCallbackComponent } from '@bp2s/core/auth/auth.callback.component';
import { AuthLogoutComponent } from '@bp2s/core/auth/auth.logout.component';
import { AuthService } from '@bp2s/core/auth/auth.service';
import { FZW_DATE_FORMAT } from '@bp2s/core/consts/date-format.consts';
import { NAV_ITEMS } from '@bp2s/core/consts/global-nav.consts';
import { ErrorPopupModule } from '@bp2s/core/errors/error-popup/error-popup.module';
import { ErrorSnackModule } from '@bp2s/core/errors/error-snack/error-snack.module';
import { AuthGuardService } from '@bp2s/core/guards/auth.guard';
import { AuthInterceptor } from '@bp2s/core/interceptors/auth.interceptor';
import { ErrorInterceptor } from '@bp2s/core/interceptors/error.interceptor';
import { ProgressInterceptor } from '@bp2s/core/interceptors/progress.interceptor';
import { XsrfInterceptor } from '@bp2s/core/interceptors/xsrf.interceptor';
import { BrowserDetectService } from '@bp2s/core/services/browser-detect.service';
import { getBaseUrl } from '@bp2s/core/utils/base-url.util';
import {
  SentryErrorHandler,
} from '@bp2s/core/services/sentry-error-handler.service';
import { ConfigurationService } from '@bp2s/core/services/configuration.service';
import { ApiModule } from '@bp2s/apimodule';
import { BASE_PATH } from '@bp2s/apivariables';
import { CharsetInitService } from '@bp2s/core/services/charset-init.service';
import { forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';
import { DisclaimerModule } from '@bp2s/core/disclaimer/disclaimer.module';

@NgModule({
  declarations: [AuthCallbackComponent, AuthLogoutComponent],
  imports: [
    CommonModule,
    NgxWebstorageModule.forRoot(),
    ApiModule,
    HttpClientModule,
    HttpClientXsrfModule,
    DynamicFormsModule,
    LoggerModule.forRoot({
      level: !environment.production
        ? NgxLoggerLevel.DEBUG
        : NgxLoggerLevel.OFF,
    }),
    environment.production ? [] : AkitaNgDevtools.forRoot(),
    BrowserAnimationsModule,
    ErrorPageModule,
    MatDialogModule,
    MomentDateModule,
    MatBottomSheetModule,
    MatSnackBarModule,
    ErrorPopupModule,
    ErrorSnackModule,
    DisclaimerModule,
  ],
  providers: [
    { provide: BASE_PATH, useFactory: getBaseUrl },

    { provide: MAT_DATE_LOCALE, useValue: 'en-AU' },
    { provide: MAT_DATE_FORMATS, useValue: FZW_DATE_FORMAT },
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: {strict: true} },


    { provide: DYNAMIC_TAB_CONFIG, useValue: { storageKey: 'FZW_TAB' } },
    AuthService,
    AuthGuardService,
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ProgressInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: XsrfInterceptor, multi: true },
    { provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: { width: '450px' } },
    {
      provide: MAT_PAGINATOR_DEFAULT_OPTIONS,
      useValue: { showFirstLastButtons: true },
    },

    { provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: { duration: 2500 } },
    {
      provide: APP_INITIALIZER,
      useFactory: (configService: ConfigurationService, charsetInitService: CharsetInitService) => () =>
        forkJoin([configService.loadConfigurations(), charsetInitService.fetchCharset()]).pipe(map(res => res[0])).toPromise(),
      deps: [ConfigurationService, CharsetInitService],
      multi: true,
    },
    {
      provide: ErrorHandler,
      useClass: SentryErrorHandler,
    },

    BrowserDetectService,
  ],
  exports: [],
})
export class CoreModule {
  constructor(private globalNavService: GlobalNavService) {
    let NAV = NAV_ITEMS;
    if (!environment.features.includes('MES')) {
      NAV = NAV.filter((item) => item.name !== 'Measurements');
    }

    NAV = NAV.filter(
      (item) => item.name !== 'Dashboard'
    );

    this.globalNavService.setNav(NAV);
  }
}
