import { UntypedFormControl } from '@angular/forms';
import { map, first } from 'rxjs/operators';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { of } from 'rxjs';
import { ListOfAccountsControllerService } from '@bp2s/api/listOfAccountsController.service';

export function uniqueDirectParentAccountValidation(
  control: UntypedFormControl,
  field: FormlyFieldConfig
) {
  if (control.value === null || control.value.trim() === '') return of(null);
  return field.options.formState.directParentAccount(control.value);
}

export function uniqueDirectParentAccountValidationConfig(
  accountControllerService: ListOfAccountsControllerService
) {
  return {
    validationMessages: [
      {
        name: 'uniqueDirectParentAccount',
        message: 'This Direct Parent Account is not valid'
      }
    ],
    validators: [
      {
        name: 'uniqueDirectParentAccount',
        validation: (control: UntypedFormControl) => {
          return accountControllerService
            .listOfAccountsSearchListOfAccountsUsingPOST({
              paged: false,
              terms: [{ fieldName: 'sourceAccountId', value: control.value }],
              pageRequest: { isPaged: false }
            })
            .pipe(
              first(),
              map(x =>
                x.content.findIndex(
                  _x => _x.sourceAccountId === control.value
                ) !== -1
                  ? null
                  : { uniqueDirectParentAccount: true }
              )
            );
        }
      }
    ]
  };
}
