import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class RouteCacheService {

  shouldCache = false;

  navigateWithRouterCache(router: Router, commands: any[], navExtras: any) {
    this.shouldCache = true;
    return router.navigate(commands, navExtras).then(
      res => {
        this.shouldCache = false;
        return res;
      },
      err => {
        this.shouldCache = false;
        return err;
      }
    );
  }
}
