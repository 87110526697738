import { Injectable } from '@angular/core';
import { DaisyChainControllerService } from '@bp2s/api/daisyChainController.service';
import { ReferenceDataControllerService } from '@bp2s/api/referenceDataController.service';
import { PolicyTreeVersionControllerService } from '@bp2s/api/policyTreeVersionController.service';
import { BnpLookupControllerService } from '@bp2s/api/bnpLookupController.service';
import { ValuationMasterControllerService } from '@bp2s/api/valuationMasterController.service';

@Injectable({
  providedIn: 'root'
})
export class BrLookupDependenciesService {

  constructor(
    public bnpLookupControllerService: BnpLookupControllerService,
    public daisyChainControllerService: DaisyChainControllerService,
    public valuationService: ValuationMasterControllerService,
    public referenceService:ReferenceDataControllerService,
    public policyTreeVersionControllerService: PolicyTreeVersionControllerService,
  ) { }
}
