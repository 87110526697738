import { Component, OnInit } from '@angular/core';
import { AuthService } from '@bp2s/core/auth/auth.service';

@Component({ selector: 'bp2s-auth-logout', template: '' })
export class AuthLogoutComponent implements OnInit {
  constructor(private authService: AuthService) {}
  ngOnInit() {
    this.authService.completeLogout();
  }
}

