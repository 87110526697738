import { Injectable, OnDestroy } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';

@Injectable({providedIn: 'root'})
export class ThrService implements OnDestroy {
  formGroup = new UntypedFormGroup({});

  constructor() {}

  registerField(name: string) {
    this.formGroup.addControl(name, new UntypedFormControl());
  }

  deregisterField(name: string) {
    this.formGroup.removeControl(name);
  }

  ngOnDestroy() {
    this.formGroup = null;
  }
}
