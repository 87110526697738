import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LeftNavFlowComponent } from './left-nav-flow.component';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
  declarations: [LeftNavFlowComponent],
  imports: [CommonModule, MatIconModule],
  exports: [LeftNavFlowComponent]
})
export class LeftNavFlowModule {}
