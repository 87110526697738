import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DiagUserConfigComponent } from '@bp2s/core/diagnostics/diag-user-config/diag-user-config.component';
import { MatSelectModule } from '@angular/material/select';
import { ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';



@NgModule({
  declarations: [DiagUserConfigComponent],
  imports: [
    CommonModule,
    MatSelectModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatIconModule
  ],
  exports: [DiagUserConfigComponent]
})
export class DiagUserConfigModule { }

