import { Component } from '@angular/core';
import { FieldWrapper } from '@ngx-formly/core';

@Component({
  selector: 'bp2s-formly-tooltip-wrapper',
  templateUrl: './formly-tooltip-wrapper.component.html',
  styleUrls: ['./formly-tooltip-wrapper.component.scss']
})
export class FormlyTooltipWrapperComponent extends  FieldWrapper {
}
