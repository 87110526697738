import { GlobalNavItem, GlobalNavType } from '@bp2s/components';
import { RESPONSIBILITY } from '@bp2s/core/consts/role_responsibility.consts';

export const NAV_ITEMS: GlobalNavItem[] = [
  {
    name: 'Dashboard',
    icon: 'dashboard',
    route: '/dashboard',
    type: GlobalNavType.default,
    permission: RESPONSIBILITY.VIEW_AM,
  },
  {
    name: 'Accounts',
    icon: 'work',
    route: '/accounts',
    type: GlobalNavType.default,
    permission: RESPONSIBILITY.VIEW_AM,
  },
  {
    name: 'Securities',
    icon: 'verified_user',
    route: '/securities',
    queryParams: { sourceId: 'GOLDEN_RECORD' },
    type: GlobalNavType.default,
    permission: RESPONSIBILITY.VIEW_SM,
  },
  {
    name: 'Fund Structures',
    icon: 'view_comfy',
    route: '/fund-structures',
    type: GlobalNavType.default,
    permission: RESPONSIBILITY.VIEW_PT,
  },
  {
    name: 'Measurements',
    icon: 'timeline',
    route: '/measurements',
    type: GlobalNavType.default,
    permission: RESPONSIBILITY.VIEW_MES,
  },
  {
    name: 'Issuers',
    icon: 'toll',
    route: '/issuers',
    queryParams: { sourceId: 'GOLDEN_RECORD' },
    type: GlobalNavType.default,
    permission: RESPONSIBILITY.VIEW_SM,
  },
  {
    name: 'XREF',
    icon: 'swap_horiz',
    route: '/xref',
    type: GlobalNavType.default,
    permission: RESPONSIBILITY.VIEW_XREF,
  },
  {
    name: 'Portfolio Modifiers',
    icon: 'donut_small',
    route: '/portfolio-modifiers',
    type: GlobalNavType.default,
    permission: RESPONSIBILITY.VIEW_AM,
  },
  {
    name: 'Indices',
    icon: '%',
    route: '/indices',
    type: GlobalNavType.default,
    permission: RESPONSIBILITY.VIEW_AM,
  },
  {
    name: 'Approvals',
    icon: 'check_box',
    route: '/approvals',
    type: GlobalNavType.default,
    permission: RESPONSIBILITY.VIEW_AM,
  },
  {
    name: 'Bulk Upload',
    icon: 'double_arrow',
    route: '/bulk-upload',
    type: GlobalNavType.default,
    permission: RESPONSIBILITY.VIEW_AM,
  },
  {
    name: 'Exports',
    icon: 'import_export',
    route: '/export',
    type: GlobalNavType.default,
    permission: RESPONSIBILITY.VIEW_AM,
  },
  {
    name: 'Jobs',
    icon: 'subject',
    route: '/jobs',
    type: GlobalNavType.default,
    permission: RESPONSIBILITY.VIEW_AM,
  },
];
