import { Injectable } from '@angular/core';
import { AllowedCharsetTableControllerService } from '@bp2s/api/allowedCharsetTableController.service';
import { isNil, noop } from 'lodash';
import { NGXLogger } from 'ngx-logger';
import { Observable, of } from 'rxjs';
import { catchError, filter, switchMap, take, tap } from 'rxjs/operators';
import { AuthService } from '@bp2s/core/auth/auth.service';
import { CommonDialogService } from '@bp2s/core/common-dialog/common-dialog.service';
import { CommonDialogData } from '@bp2s/core/common-dialog/common-dialog-data';
import { CharsetService } from '@bp2s/core/services/charset.service';

const CHARSET_ID = 'input-field-1.0.0';

const DIALOG_OPTIONS: CommonDialogData = {
  width: '500px',
  cancelText: 'NO',
  confirmText: 'YES',
  message: `The appplication could not fetch the list of allowed characters.
You may not be able to search/create/edit some records.
<p>Do you want to reload the application (Recommended) ?</p>`,
  title: 'INITIALIZATION ERROR'
};

export const TEST_CODES = [9,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114,115,116,117,118,119,120,121,122,123,124,125,126,8217,8221];
export const TEST_CHARACTERS = TEST_CODES.map(code => String.fromCharCode(code));

@Injectable({
  providedIn: 'root'
})
export class CharsetInitService {

  constructor(
    private allowedCharsetTableControllerService: AllowedCharsetTableControllerService,
    private authService: AuthService,
    private logger: NGXLogger,
    private commonDialogService: CommonDialogService,
  ) { }

  fetchCharset(): Observable<any> {
    this.authService.user$.pipe(
      filter(user => !isNil(user.token_type) && !isNil(user.access_token)),
      take(1),
      tap({
        next: (user) => this.logger.info(`fetching charset with authorization: ${user.token_type} ${user.access_token}`)
      }),
      switchMap(usr => this.allowedCharsetTableControllerService.allowedCharsetTableGetCharsetTableByIdUsingGET(CHARSET_ID)),
      catchError(err => {
        this.initializationError(err);
        return of({codes: []});
      })
    ).subscribe({
      next: (res) => {
        CharsetService.charset = res;
      },
      error: (err) => noop(),
      complete: () => {
        if (!CharsetService.charset) {
          this.initializationError('no data');
        }
      }
    });
    return of(true);
  }

  initializationError(err: string) {
    this.logger.error('problem fetching charset', err);
    this.commonDialogService.open(DIALOG_OPTIONS).afterClosed()
    .subscribe({
      next: (reload) => {
        if (reload) {
          window.location.reload();
        }
      }
    });
  }
}
