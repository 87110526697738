import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GLOBAL_ERROR } from '@bp2s/core/consts/global-error.consts';
import { ERROR_PAGE_MESSAGES } from '@bp2s/layout/error-page/error-page.consts';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'bp2s-error-page',
  templateUrl: './error-page.component.html',
  styleUrls: ['./error-page.component.scss'],
})
export class ErrorPageComponent implements OnInit, OnDestroy {
  error = `The system is down. Our teams are hard at work to make the service accessible.`;
  link = null;
  kill$: Subject<void> = new Subject();

  constructor(private atr: ActivatedRoute, private dialogRef: MatDialog) {}

  ngOnInit() {
    this.atr.queryParams
      .pipe(takeUntil(this.kill$))
      .subscribe({
        next: ({ error }: { error: GLOBAL_ERROR }) => {
          this.dialogRef.closeAll();
          if (ERROR_PAGE_MESSAGES[error]) {
            this.error = ERROR_PAGE_MESSAGES[error].message;
            this.link = ERROR_PAGE_MESSAGES[error].link;
          }
        }
      }); /* No error handling required for router Observables. */
  }

  ngOnDestroy() {
    this.kill$.next();
    this.kill$.complete();
  }
}
