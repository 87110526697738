import { Injectable } from '@angular/core';
import { AuthService } from '@bp2s/core/auth/auth.service';
import { ROLES, RESPONSIBILITY } from '@bp2s/core/consts/role_responsibility.consts';
import { Observable } from 'rxjs';
import { intersection } from 'lodash';

@Injectable({
  providedIn: 'root',
})
export class GlobalPermissionsService {
  userChange$: Observable<any>;

  constructor(private auth: AuthService) {
    this.userChange$ = this.auth.user$.asObservable();
  }

  hasRole(roles: string[]): boolean {
    if (this.auth.isLoggedIn() && this.auth.user?.profile?.authorities) {
      const userRoles: string[] = this.auth.user.profile.authorities;
      return intersection(userRoles, roles).length > 0;
    }
    return false;
  }

  getAvailableActions(): Set<RESPONSIBILITY> {
    const actions = new Set<RESPONSIBILITY>();
    if (this.auth.isLoggedIn() && this.auth.user?.profile?.authorities) {
      const roles: string[] = this.auth.user.profile.authorities;
      roles.forEach((role: string) => {
        const r = ROLES[role.toUpperCase()];
        if (r) {
          r.forEach((responsibility: RESPONSIBILITY) => {
            actions.add(responsibility);
          });
        }
      });
    }
    return actions;
  }

  checkPermission(permission: RESPONSIBILITY): boolean {
    return this.getAvailableActions().has(permission);
  }
}
