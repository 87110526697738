import { FZW_UI_DATE_FORMAT } from "@bp2s/core/consts/date-format.consts";

export const FORMS_VALIDATION_MSGS = {
  REQUIRED: (err, field) => {
    if(field?.type === 'datepicker'){
      return `${field.props?.label || 'This field'} is required in ${FZW_UI_DATE_FORMAT} format`;
    } else {
      return `${field.props?.label || 'This field'} is required`;
    }
  } ,
  MIN_LENGTH: (err, field) =>
    `${field.props.label || 'This field'} should have at least ${
      field.props.minLength
    } characters`,
  MAX_LENGTH: (err, field) =>
    `${field.props.label || 'This field'} should have at most ${
      field.props.maxLength
    } characters`,
  MIN: (err, field) =>
    `${field.props.label || 'This field'} should be more than ${
      field.props.min
    } `,
  MAX: (err, field) =>
    `${field.props.label || 'This field'} should be less than ${
      field.props.max
    } `,
  DATEPICKER_MIN: (err, field) => {
    const min = err.min?.format(FZW_UI_DATE_FORMAT) ?? 'Start Date';
    return `${field.props?.label || 'This field'} should be after ${min} `;
  },
  DATEPICKER_MAX: (err, field) => {
    const max = err.max?.format(FZW_UI_DATE_FORMAT) ?? 'End Date';
    return `${field.props?.label || 'This field'} should be before ${max} `;
  },
  INVALID_AUTOCOMPLETE: 'You must select an item from the list',
  UNIQUE_SOURCE_ACCOUNTID: 'This Source Account Id is already in use',
  INVALD_DIRECT_PARENT_ACC: 'This Direct Parent Account does not exist',
  NUMBER: 'Type a number (max precision: 6 digits)',
  TIMESERIES_DATE_VALIDATION: 'Existing TimeSeries Start Date is greater'
} ;
