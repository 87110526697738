import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TopNavOverlayComponent } from './top-nav-overlay.component';
import { OverlayModule } from '@angular/cdk/overlay';
import { PortalModule } from '@angular/cdk/portal';
import { TopNavOverlayTriggerDirective, TOP_NAV_OVERLAY_SCROLL_STRATEGY_FACTORY_PROVIDER } from './top-nav-overlay-trigger.directive';

@NgModule({
  declarations: [TopNavOverlayComponent, TopNavOverlayTriggerDirective],
  imports: [CommonModule, OverlayModule, PortalModule],
  providers: [TOP_NAV_OVERLAY_SCROLL_STRATEGY_FACTORY_PROVIDER],
  exports: [TopNavOverlayComponent, TopNavOverlayTriggerDirective]
})
export class TopNavOverlayModule {}
