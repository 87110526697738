import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { Configuration } from './configuration';
import { HttpClient } from '@angular/common/http';

import { AccountControllerService } from './api/accountController.service';
import { AccountTimeSeriesControllerService } from './api/accountTimeSeriesController.service';
import { AllowedCharsetTableControllerService } from './api/allowedCharsetTableController.service';
import { ApprovalControllerService } from './api/approvalController.service';
import { AsyncJobControllerService } from './api/asyncJobController.service';
import { BackdatedProcessControllerService } from './api/backdatedProcessController.service';
import { BnpAccountControllerService } from './api/bnpAccountController.service';
import { BnpLookupControllerService } from './api/bnpLookupController.service';
import { BorExceptionsControllerService } from './api/borExceptionsController.service';
import { BorMasterControllerService } from './api/borMasterController.service';
import { BorPackageControllerService } from './api/borPackageController.service';
import { BulkUploadControllerService } from './api/bulkUploadController.service';
import { BulkUploadTemplateControllerService } from './api/bulkUploadTemplateController.service';
import { ClientWorkflowControllerService } from './api/clientWorkflowController.service';
import { CltIndicesControllerService } from './api/cltIndicesController.service';
import { CustomIndexControllerService } from './api/customIndexController.service';
import { DaisyChainControllerService } from './api/daisyChainController.service';
import { DnaRuleBreakdownControllerService } from './api/dnaRuleBreakdownController.service';
import { DqKnownExceptionListControllerService } from './api/dqKnownExceptionListController.service';
import { DqMappingTableControllerService } from './api/dqMappingTableController.service';
import { DqToleranceControllerService } from './api/dqToleranceController.service';
import { ExportControllerService } from './api/exportController.service';
import { ExposureAdjustmentControllerService } from './api/exposureAdjustmentController.service';
import { InfoControllerService } from './api/infoController.service';
import { IssuerMasteringControllerService } from './api/issuerMasteringController.service';
import { ListOfAccountsControllerService } from './api/listOfAccountsController.service';
import { MeasurementSetControllerService } from './api/measurementSetController.service';
import { MeasurementTypeControllerService } from './api/measurementTypeController.service';
import { MetaModelControllerService } from './api/metaModelController.service';
import { OpsTemplateControllerService } from './api/opsTemplateController.service';
import { PolicyTreeVersionControllerService } from './api/policyTreeVersionController.service';
import { PortfolioModifierControllerService } from './api/portfolioModifierController.service';
import { ReferenceDataControllerService } from './api/referenceDataController.service';
import { SecurityControllerService } from './api/securityController.service';
import { SmBulkUploadControllerService } from './api/smBulkUploadController.service';
import { SmBulkUploadTemplateControllerService } from './api/smBulkUploadTemplateController.service';
import { SmExportControllerService } from './api/smExportController.service';
import { SmPriorityControllerService } from './api/smPriorityController.service';
import { SmSecurityControllerService } from './api/smSecurityController.service';
import { SmSecurityWorkflowControllerService } from './api/smSecurityWorkflowController.service';
import { UnderWriteOverWriteControllerService } from './api/underWriteOverWriteController.service';
import { ValuationMasterControllerService } from './api/valuationMasterController.service';
import { XRefControllerService } from './api/xRefController.service';

@NgModule({
  imports:      [],
  declarations: [],
  exports:      [],
  providers: []
})
export class ApiModule {
    public static forRoot(configurationFactory: () => Configuration): ModuleWithProviders<ApiModule> {
        return {
            ngModule: ApiModule,
            providers: [ { provide: Configuration, useFactory: configurationFactory } ]
        };
    }

    constructor( @Optional() @SkipSelf() parentModule: ApiModule,
                 @Optional() http: HttpClient) {
        if (parentModule) {
            throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
        }
        if (!http) {
            throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
            'See also https://github.com/angular/angular/issues/20575');
        }
    }
}
