import { Component, ViewChild, ViewContainerRef } from '@angular/core';
import { FieldWrapper } from '@ngx-formly/core';


@Component({
  selector: 'bp2s-formly-panel-wrapper',
  template: `
  <div class="card">
    <div class="card-body">
      <ng-container #fieldComponent></ng-container>
    </div>
    <mat-chip-listbox  *ngIf="model.accounts">
      <mat-chip-option #chipList *ngFor="let item of model?.accounts"
      (removed)="remove(item)"
             [removable]="removable">{{ item.nodeId }}
            <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
      </mat-chip-option>
    </mat-chip-listbox>
  </div>
 `,
  styleUrls: ['./formly-panel-wrapper.component.scss']
})
export class FormlyPanelWrapperComponent extends FieldWrapper {
  @ViewChild('fieldComponent', {read: ViewContainerRef}) fieldComponent: ViewContainerRef;
  selectable = true;
  removable = true;

  remove(account): void {
    const index = this.model.accounts.indexOf(account);
    if (index >= 0) {
      this.model.accounts.splice(index, 1)
    }
  }
}
