<header>
  <mat-icon>assignment</mat-icon>

  <div class="user-details">
    <h3>Jobs</h3>
    <h5 *ngIf="(jobsService.activeJobCount$ | async) === 0; else jobCount">No Active Jobs</h5>

    <ng-template #jobCount>
        <h5>{{(jobsService.activeJobCount$ | async)}} Active Jobs</h5>
    </ng-template>
  </div>
</header>

<mat-nav-list>
  <ng-container *ngFor="let job of jobsService.jobs | async ; let isLast = last">
    <mat-list-item  *ngIf="isLast && hasJobData() ; else readOnlyJobs" [routerLink]="job.link" >
      <h3 matLine>{{ job.type }}</h3>
      <p matLine>{{ job.displayText }}</p>
      <mat-icon *ngIf="job.status === STATUSES.COMPLETE">done</mat-icon>
      <mat-progress-spinner
        *ngIf="job.status === STATUSES.PENDING"
        diameter="25"
        mode="indeterminate"
      ></mat-progress-spinner>
    </mat-list-item>
    <ng-template #readOnlyJobs>
      <mat-list-item class="disabled" disableRipple="true">
        <h3 matLine>{{ job.type }}</h3>
        <p matLine>{{ job.displayText }}</p>
        <mat-icon *ngIf="job.status === STATUSES.COMPLETE">done</mat-icon>
        <mat-progress-spinner
          *ngIf="job.status === STATUSES.PENDING"
          diameter="25"
          mode="indeterminate"
        ></mat-progress-spinner>
      </mat-list-item>
    </ng-template>
  </ng-container>
  <ng-container *ngFor="let job of jobsService.asyncJobs | async ; let isLast = last">
    <mat-list-item (click)="viewAsyncJob(job)">
      <h3 matLine>{{ job.type }}</h3>
      <p matLine>{{ job.displayText }}</p>
      <mat-icon *ngIf="job.status === STATUSES.COMPLETED">done</mat-icon>
      <mat-progress-spinner
        *ngIf="job.status === STATUSES.PENDING || job.status === 'IN_PROGRESS'"
        diameter="25"
        mode="indeterminate"
      ></mat-progress-spinner>
    </mat-list-item>
  </ng-container>
</mat-nav-list>
